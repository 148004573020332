import { FunctionComponent, useEffect } from "react";
import { StatusUpdateMessage, WegenwachtInTransitMessageDetails } from "@status-updates/types";
import { useParams } from "react-router";

import { CenteredWrapper } from "../../../../components/wrapper";
import { WegenwachtInTransit } from "./components/updates/wegenwacht-in-transit";
import { Navigation } from "../../../../components/navigation";
import { useStatusUpdates } from "../../hooks/useStatusUpdates";
import { WegenwachtScheduleChanged } from "./components/updates/wegenwacht-schedule-changed";
import { TEN_SECONDS_MS } from "../../../../hooks/usePollMessages";
import { WegenwachtEnd } from "./components/updates/wegenwacht-end";
import { useGoogleTagManager } from "../../../../hooks/useGoogleTagManager";

export type WegenwachtUpdateArgs = {
  message: StatusUpdateMessage;
  registrationTime: string;
  isFormal: boolean;
  assistancePhoneNumber?: string;
  locationCustomerHighway?: {
    highway: string | undefined;
    hectometreMarker: number | undefined;
  };
};

const WaarIsDeWegenwacht: FunctionComponent = () => {
  const sendEventToGtm = useGoogleTagManager();
  const { identifier } = useParams();
  const { messages, caseReceivedMessage, setRefetchInterval } = useStatusUpdates();
  const message = messages.find((message) => message.metadata?.messageId === identifier);
  const assistancePhoneNumber = message?.metadata?.assistancePhoneNumber;
  const locationWegenwacht = (message?.messageDetails as WegenwachtInTransitMessageDetails)
    .locationWegenwacht;
  const locationCustomerHighway = {
    highway: caseReceivedMessage?.breakdownLocationHighway,
    hectometreMarker: caseReceivedMessage?.breakdownLocationHectometreMarker,
  };
  const customerType = caseReceivedMessage?.contractMarket?.endsWith("zama")
    ? "zakelijk"
    : "particulier";

  // When we show the wegenwacht we poll more frequently
  useEffect(() => {
    if (locationWegenwacht !== undefined) {
      setRefetchInterval(TEN_SECONDS_MS);
    }
  }, [locationWegenwacht?.latitude, locationWegenwacht?.longitude]);

  useEffect(() => {
    let gtmText;
    switch (message?.messageType) {
      case "WegenwachtEndMessage":
        gtmText = "Geen statusinformatie (meer) beschikbaar";
      case "WegenwachtCancelledByUserMessage":
        gtmText = "Geen statusinformatie (meer) beschikbaar";
      case "WegenwachtScheduleChangedMessage":
        gtmText = "Planning gewijzigd";
      case "WegenwachtInTransitMessage":
        gtmText = "De wegenwacht is onderweg";
    }
    sendEventToGtm({
      event: "visibility",
      type: "page",
      component: "volg de wegenwacht",
      action: "open",
      text: gtmText,
      category: caseReceivedMessage?.breakdownLocationCountryName,
      variant: customerType,
      status: "open",
    });
  }, [sendEventToGtm]);

  return (
    <>
      <Navigation />
      <CenteredWrapper>
        {(message?.messageType === "WegenwachtEndMessage" ||
          message?.messageType === "WegenwachtCancelledByUserMessage") && (
          <WegenwachtEnd
            message={message}
            registrationTime={caseReceivedMessage!.registrationTime}
            isFormal={message?.metadata?.contractMarket !== "coma"}
            assistancePhoneNumber={assistancePhoneNumber}
          />
        )}
        {message?.messageType === "WegenwachtScheduleChangedMessage" && (
          <WegenwachtScheduleChanged
            message={message}
            registrationTime={caseReceivedMessage!.registrationTime}
            isFormal={message?.metadata?.contractMarket !== "coma"}
            assistancePhoneNumber={assistancePhoneNumber}
          />
        )}
        {message?.messageType === "WegenwachtInTransitMessage" && (
          <WegenwachtInTransit
            message={message}
            registrationTime={caseReceivedMessage!.registrationTime}
            isFormal={message?.metadata?.contractMarket !== "coma"}
            assistancePhoneNumber={assistancePhoneNumber}
            locationCustomerHighway={locationCustomerHighway}
          />
        )}
      </CenteredWrapper>
    </>
  );
};

export default WaarIsDeWegenwacht;
