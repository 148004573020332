import { RefObject } from "react";
import { useGoogleTagManager } from "../../hooks/useGoogleTagManager";

export type UseTrackingProps = {
  /** The ref of the accordion container */
  ref: RefObject<HTMLDivElement>;
  /** The reference of the accordion */
  reference: string;
};

export const useTracking = ({ ref, reference }: UseTrackingProps) => {
  const sendEventToGtm = useGoogleTagManager();
  return (index: number, isOpen: boolean): void => {
    const elements = ref.current?.querySelectorAll(`[data-tracking-label]`);
    const element = elements?.[index];

    if (!element) {
      return;
    }

    const title = element.getAttribute(`data-tracking-label`) ?? "";

    sendEventToGtm({
      type: "page",
      component: reference,
      field: title,
      action: "click",
      actionValue: isOpen ? "Open" : "Close",
    });
  };
};
