import { FunctionComponent, ReactNode, useEffect } from "react";

import { InformationDefaultComa } from "../../../../components/country-information/InformationDefaultComa";
import { availableInformationPages, Market } from "./configuration";
import { InformationDefaultZama } from "../../../../components/country-information/InformationDefaultZama";
import { StyledInformation } from "./styled";
import { useStatusUpdates } from "../../hooks/useStatusUpdates";
import { useGoogleTagManager } from "../../../../hooks/useGoogleTagManager";

type Props = {
  children: ReactNode;
};

export const Wrapper: FunctionComponent<Props> = ({ children }) => (
  <StyledInformation>{children}</StyledInformation>
);

const Information: FunctionComponent = () => {
  const { messages, caseReceivedMessage } = useStatusUpdates();

  const sendEventToGtm = useGoogleTagManager();

  /*
   * On render, when an anchor link has been clicked, we have to manually scroll
   * to the specified anchor. This is because during the render process, it will never be able to
   * scroll to the appropriate anchor because the HTML dimensions are not yet finalized.
   */
  useEffect(() => {
    if (window.location.hash) {
      document.querySelector(location.hash)?.scrollIntoView();
    } else {
      window.scrollTo({ top: 0 });
    }
  }, []);

  useEffect(() => {
    sendEventToGtm({
      event: "user_interaction",
      type: "page",
      component: "informatie",
      action: "click",
    });
  }, [sendEventToGtm]);

  const breakdownLocationCountry =
    messages[0].metadata?.breakdownLocationCountry ?? caseReceivedMessage?.breakdownLocationCountry;

  const InformationPage = findEligibleInformationPage(
    breakdownLocationCountry,
    caseReceivedMessage?.contractMarket
  );

  return (
    <Wrapper>
      <InformationPage />
    </Wrapper>
  );
};

export default Information;

function findEligibleInformationPage(country?: string, market?: string): FunctionComponent {
  const page = availableInformationPages.find((informationPage) => {
    if (informationPage.country === country && informationPage.market.includes(market as Market)) {
      return informationPage;
    }
    return undefined;
  });

  if (page === undefined && market?.endsWith("zama")) {
    return InformationDefaultZama;
  }

  return page !== undefined ? page.component : InformationDefaultComa;
}
