import { useContext } from "react";
import { StatusUpdatesContext, StatusUpdatesState } from "../StatusUpdatesProvider";

export function useStatusUpdates(): StatusUpdatesState {
  const state = useContext(StatusUpdatesContext);

  if (state === undefined) {
    return {
      caseReceivedMessage: undefined,
      messages: [],
      isComa: true,
      token: "",
      isReadOnly: false,
      setRefetchInterval: () => {},
    };
  }

  return state;
}
