import styled from "styled-components";

export const StyledDurationAndDistanceLabel = styled.div`
  max-width: 600px;
  padding: 0 14px;
  position: relative;
  height: 70px;
  left: 50%;
  top: 26px;
  box-sizing: border-box;
  transform: translateX(-50%);

  .duration-label {
    font-size: 42px;
  }

  .distance-label {
    font-size: 42px;
  }

  div {
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  span:first-child {
    border-right: 1px solid ${({ theme }) => theme.colors.light.divider};
  }

  span {
    margin-bottom: 0px !important;
    text-align: center;
    line-height: 49px;
    flex: 0.5;
    height: 70%;
  }
`;
