import Typography from "@anwb/typography";
import { DateTime } from "luxon";
import {
  LocalizeProviderLocalizedMessageDetails,
  TypedStatusUpdateMessage,
} from "@status-updates/types";
import { FrontendState, StateMetadata } from "../frontend-state";
import { styledNameAndAddressWithMapsLink } from "../../utils/address-styler";

export class LocalizeProviderLocalizedState extends FrontendState<LocalizeProviderLocalizedMessageDetails> {
  constructor(
    message: TypedStatusUpdateMessage<LocalizeProviderLocalizedMessageDetails>,
    metadata: StateMetadata
  ) {
    super(message, metadata, {
      illustration: "alarmcentrale",
      title: "Wij hebben een garage gevonden",
    });
  }

  renderTimelineItems() {
    const { serviceProvider } = this.message.messageDetails;
    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: "Wij hebben een garage gevonden",
        content: (
          <Typography data-e2e-test-type="status-updates-localize-provider-localized" tagName="div">
            <p>Deze garage kan naar {this.getInformalOrFormal("jouw", "uw")} voertuig kijken.</p>
            <p>
              <b>Adres</b>
              <br />
              {styledNameAndAddressWithMapsLink(serviceProvider.name, serviceProvider.address)}
            </p>
            {this.metadata.countryCode === "FR" && (
              <p>
                Let op, in Frankrijk sluiten garages tussen 12:00 en 14:00
                uur.
              </p>
            )}
            {this.getInformalOrFormal("Je kan", "U kunt")} ons{" "}
            <Typography
              variant="link-text"
              href={`tel:${this.metadata.assistancePhonenumber || ""}`}
            >
              bellen
            </Typography>{" "}
            voor extra hulp bij:
            <ul style={{ listStyleType: "disc" }}>
              <li>Communicatie met de hulpdienst of de garage;</li>
              <li>Begeleiding van de reparatie.</li>
            </ul>
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
