import styled from "styled-components";
import { ButtonContainer as PonchoButtonContainer } from "@anwb/button";

export const ButtonContainer = styled.div`
  margin: 1.4rem 0;
  display: flex;
  justify-content: center;

  ${PonchoButtonContainer} {
    width: 100%;    
    padding-left 0;
    padding-right 0;
  }
`;
