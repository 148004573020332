import { DefaultDetails, TypedStatusUpdateMessage } from "@status-updates/types";
import Typography from "@anwb/typography";
import { DateTime } from "luxon";
import { FrontendState, StateMetadata } from "../frontend-state";

export class WegenwachtCancelledByUserState extends FrontendState<DefaultDetails> {
  constructor(message: TypedStatusUpdateMessage<DefaultDetails>, metadata: StateMetadata) {
    super(message, metadata, {
      illustration: "alarmcentrale",
      title: "Melding geannuleerd",
    });
  }

  renderTimelineItems() {
    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: this.options.title,
        content: (
          <Typography
            data-e2e-test-type="status-updates-wegenwacht-cancelled-message"
            tagName="div"
          >
            <p>
              {this.getInformalOrFormal("Je hebt je", "U heeft uw")} melding geannuleerd. Wij hopen
              dat {this.getInformalOrFormal("je", "uw")} reis verder voorspoeding verloopt. Mochten
              wij nog iets voor {this.getInformalOrFormal("je", "u")} kunnen betekenen, klik dan op
              de belknop rechtsboven.
            </p>
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
