import { Address } from "@status-updates/types";
import { styledNameAndAddressWithMapsLink } from "./address-styler";

export function serviceProviderText(
  provider: Address | undefined,
  providerName: string | undefined
): JSX.Element {
  return (
    <span>
      <p>
        <strong>Deze hulpdienst gaat je helpen</strong>
        <br />
        {styledNameAndAddressWithMapsLink(providerName, provider)}
      </p>
    </span>
  );
}
