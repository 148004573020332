import { FunctionComponent } from "react";
import { Navigation } from "../../../../components/navigation";
import { StyledInformation } from "./styled";
import { GoogleMaps } from "../../../../components/google-maps/Map";
import carMarker from "../../../../../public/markers/car.svg";
import { useStatusUpdates } from "../../hooks/useStatusUpdates";
import { CaseReceivedMessageDetails } from "@status-updates/types";
import Typography, { BodyText } from "@anwb/typography";

const BreakdownLocation: FunctionComponent = () => {
  const { caseReceivedMessage } = useStatusUpdates();

  const {
    lat,
    lng,
    locationComment,
    streetAndHouseNumber,
    postalCode,
    city,
    assistancePhoneNumber,
    highway,
    hectometreMarker,
  } = generateBreakdownLocationState(caseReceivedMessage);

  return (
    <StyledInformation>
      <Navigation />
      <GoogleMaps
        center={{ lat, lng }}
        markers={[
          {
            marker: {
              draggable: false,
              position: { lat, lng },
            },
            img: {
              src: carMarker,
              alt: "pechlocatie pin",
            },
          },
        ]}
      />
      <div className="pechlocatie">
        <Typography variant="content-title">Pechlocatie</Typography>
        <BodyText>{streetAndHouseNumber}</BodyText>
        <BodyText>
          {postalCode} {city}
        </BodyText>
        <BodyText>
          {highway}
          {hectometreMarker ? `, hectometerpaal ${hectometreMarker}` : ""}
        </BodyText>
        <BodyText>{locationComment}</BodyText>
      </div>
    </StyledInformation>
  );
};

export default BreakdownLocation;

export function generateBreakdownLocationState(
  caseReceivedMessageDetails?: CaseReceivedMessageDetails
) {
  const {
    assistancePhoneNumber = "",
    breakdownLocationComment = undefined,
    breakdownCoordinates = { latitude: 0, longitude: 0 },
    breakdownLocationCity = "",
    breakdownLocationStreet = "",
    breakdownLocationPostalCode = "",
    breakdownLocationHouseNumber = "",
    breakdownLocationHouseNumberExtension = "",
    breakdownLocationHighway = "",
    breakdownLocationHectometreMarker = "",
  } = caseReceivedMessageDetails ?? ({} as CaseReceivedMessageDetails);

  const streetAndHouseNumber = `${breakdownLocationStreet} ${breakdownLocationHouseNumber}${breakdownLocationHouseNumberExtension}`;
  const hectometreMarkerWithComma = breakdownLocationHectometreMarker.toLocaleString("nl-NL");

  return {
    lat: breakdownCoordinates.latitude,
    lng: breakdownCoordinates.longitude,
    locationComment: breakdownLocationComment,
    streetAndHouseNumber: streetAndHouseNumber,
    postalCode: breakdownLocationPostalCode,
    city: breakdownLocationCity,
    assistancePhoneNumber: assistancePhoneNumber,
    highway: breakdownLocationHighway,
    hectometreMarker: hectometreMarkerWithComma,
  };
}
