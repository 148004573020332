import { AwsRum, AwsRumConfig } from "aws-rum-web";
import { useEffect, useState, createContext, useContext } from "react";

type Props = {
  children?: React.ReactNode;
};

const MonitoringContext = createContext<AwsRum | undefined>(undefined);

export const useMonitoring: () => AwsRum | undefined = () => {
  return useContext(MonitoringContext);
};

export const MonitoringProvider: React.FC<Props> = ({ children }) => {
  const [client, setClient] = useState<AwsRum | undefined>();

  useEffect(() => {
    try {
      const config: AwsRumConfig = {
        sessionSampleRate: 1,
        guestRoleArn: window.rumConfiguration.guestRoleArn,
        identityPoolId: window.rumConfiguration.identityPoolId,
        endpoint: "https://dataplane.rum.eu-central-1.amazonaws.com",
        telemetries: [
          "performance",
          [
            "errors",
            {
              ignore: (errorEvent: ErrorEvent | PromiseRejectionEvent) => {
                return "message" in errorEvent && /^CWR:/.test(errorEvent.message);
              },
            },
          ],
          "http",
        ],
        allowCookies: true,
        enableXRay: false,
      };

      const APPLICATION_ID = window.rumConfiguration.clientId;
      const APPLICATION_VERSION = "1.0.0";
      const APPLICATION_REGION = "eu-central-1";

      setClient(new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config));
    } catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
    }
  }, []);

  return (
    <MonitoringContext.Provider value={client}>{client && children}</MonitoringContext.Provider>
  );
};
