import { FunctionComponent, useEffect } from "react";
import { useGoogleTagManager } from "../../../../../hooks/useGoogleTagManager";
import { useStatusUpdates } from "../../../hooks/useStatusUpdates";
import { Illustration } from "../../../../../components/illustration";
import { ButtonPrimary } from "@anwb/button";
import { useLinkBuilder } from "../../../../../components/link";
import { getInformalOrFormal } from "../../../utils";

const SuccessfullCancel: FunctionComponent = () => {
  const sendEventToGtm = useGoogleTagManager();
  const { caseReceivedMessage } = useStatusUpdates();
  const isComa = caseReceivedMessage?.contractMarket === "coma";

  useEffect(() => {
    sendEventToGtm({
      event: "visibility",
      type: "page",
      component: "annuleren succesvol",
    });
  }, [sendEventToGtm]);

  const closeLink = useLinkBuilder("/");

  return (
    <div className="successfull-cancel-page">
      <h1>Melding geannuleerd!</h1>

      <Illustration variant="blije-klant" className="happy-customer-illustration" />

      <p>We wensen {getInformalOrFormal("je", "u", isComa)} een goede reis!</p>
      <ButtonPrimary className="close-button" href={closeLink}>
        Sluit
      </ButtonPrimary>
    </div>
  );
};

export default SuccessfullCancel;
