import { createContext, FunctionComponent, useEffect, useMemo, useState } from "react";
import type { CaseReceivedMessageDetails, StatusUpdateMessage } from "@status-updates/types";

import {
  findCaseReceivedMessage,
  getCurrentStatus,
  sortMessages,
  updateFavicon,
  updateTitle,
} from "./utils";
import { ContentState } from "../../components/content-state";
import { GtmData, useGoogleTagManager } from "../../hooks/useGoogleTagManager";
import { usePollMessages } from "../../hooks/usePollMessages";

export type StatusUpdatesState = {
  token: string;
  caseReceivedMessage: CaseReceivedMessageDetails | undefined;
  messages: StatusUpdateMessage[];
  isComa: boolean;
  isReadOnly: boolean;
  setRefetchInterval: React.Dispatch<React.SetStateAction<number | undefined>>;
};

export const StatusUpdatesContext = createContext<StatusUpdatesState>({
  token: "",
  caseReceivedMessage: undefined,
  messages: [],
  isComa: true,
  isReadOnly: false,
  setRefetchInterval: () => {},
});

/**
 * This provider is responsible for polling the data for a specific case
 * with an interval of 5 minutes. It enables components in the `Home` page tree to
 * make use of this data through the @see useStatusUpdates hook.
 *
 * @returns
 */
export const StatusUpdatesProvider: FunctionComponent<{
  token: string;
  isReadOnly: boolean;
  children: (state: StatusUpdatesState) => React.ReactNode;
}> = ({ token, isReadOnly, children }) => {
  const sendEventToGtm = useGoogleTagManager();
  const [isRefreshed, setRefreshed] = useState<boolean>(false);
  const { status, data, error, retry, setRefetchInterval } = usePollMessages({
    token,
    isReadOnly,
  });

  const messages = sortMessages(data?.messages || []);
  const caseReceivedMessage = findCaseReceivedMessage(messages);
  const isComa = !caseReceivedMessage?.contractMarket?.endsWith("zama") ?? true;
  const counter = data?.counter;

  useEffect(() => {
    if (caseReceivedMessage !== undefined) {
      updateTitle(caseReceivedMessage);
      updateFavicon(caseReceivedMessage);
      sendMultipleEventsToGTM(
        sendEventToGtm,
        getCurrentStatus(messages[0].messageType),
        isRefreshed,
        caseReceivedMessage
      );
      setRefreshed(true);
    }
  }, [status, messages, counter, caseReceivedMessage]);

  return (
    <ContentState status={status} retry={retry} error={error}>
      <StatusUpdatesContext.Provider
        value={useMemo(
          () => ({ token, caseReceivedMessage, isComa, messages, isReadOnly, setRefetchInterval }),
          [token, caseReceivedMessage, messages, isComa, isReadOnly, setRefetchInterval]
        )}
      >
        {children({
          token,
          caseReceivedMessage,
          isComa,
          messages,
          isReadOnly,
          setRefetchInterval,
        })}
      </StatusUpdatesContext.Provider>
    </ContentState>
  );
};

function sendMultipleEventsToGTM(
  sendEventToGtm: (event: GtmData) => void,
  currentStatus: string | undefined,
  isRefreshed: boolean,
  caseReceivedMessage: CaseReceivedMessageDetails
) {
  const isRefreshedEventLabel = isRefreshed === false ? "open" : "refresh";
  const customerType = caseReceivedMessage.contractMarket?.endsWith("zama")
    ? "zakelijk"
    : "particulier";
  if (
    currentStatus !== undefined &&
    caseReceivedMessage.breakdownLocationCountryName &&
    caseReceivedMessage.contractMarket
  ) {
    sendEventToGtm({
      event: "visibility",
      type: "page",
      component: "statusmelding",
      status: isRefreshedEventLabel,
      text: currentStatus,
      category: caseReceivedMessage.breakdownLocationCountryName,
      variant: customerType,
    });
  }
}
