import { BodyText } from "@anwb/typography";
import styled from "styled-components";

export const StyledInformation = styled.section`
  box-sizing: border-box;

  .pechlocatie {
    padding: 0 14px;
    margin: 0 auto;
    max-width: ${({ theme }) => `${theme.viewportBreakpoint.sm}px`};

    ${BodyText} {
      margin: 0;
    }
  }
`;
