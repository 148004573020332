import { DefaultDetails, TypedStatusUpdateMessage } from "@status-updates/types";
import Typography from "@anwb/typography";
import { DateTime } from "luxon";
import { FrontendState, StateMetadata } from "../frontend-state";

export class WegenwachtEndState extends FrontendState<DefaultDetails> {
  constructor(message: TypedStatusUpdateMessage<DefaultDetails>, metadata: StateMetadata) {
    super(message, metadata, {
      illustration: "wegenwacht",
      title: "Geen statusinformatie (meer) beschikbaar",
    });
  }

  renderTimelineItems() {
    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: this.options.title,
        content: (
          <Typography data-e2e-test-type="status-updates-wegenwacht-end-message" tagName="div">
            <p>
              Mochten wij nog iets voor {this.getInformalOrFormal("je", "u")} kunnen betekenen, klik
              dan op de belknop rechtsboven.
            </p>
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
