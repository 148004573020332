import { ANWBBrandRefresh, Theme } from "@anwb/theme";

export type StatusUpdatesTheme = Theme & {
  header: {
    height: string;
    borderWidth: string;
  };
  spacings: {
    horizontalPadding: string;
  };
  statusupdates: {
    bgStatus: string;
  };
};

export const StatusUpdatesDefaultTheme: StatusUpdatesTheme = {
  ...Object.assign(ANWBBrandRefresh, {
    header: {
      height: "42px",
      borderWidth: "10px",
    },
    spacings: {
      horizontalPadding: "14px",
    },
    statusupdates: {
      bgStatus: "#e5f4fb",
    },
  }),
};
