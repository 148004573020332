import React from "react";
import Typography from "@anwb/typography";
import Progress from "@anwb/progress";

type HeaderContentProps = {
  isComa: boolean;
  showBodyText: boolean;
  percentage: number;
};

export const HeaderContent: React.FC<HeaderContentProps> = (props) => {
  return (
    <>
      <Typography variant="page-title">
        Deel {props.isComa ? "je" : "uw"} reisplanning met ons!
      </Typography>
      {props.showBodyText && (
        <Typography variant="intro-text">
          Om {props.isComa ? "jou" : "u"} zo goed mogelijk te helpen, zijn we benieuwd naar{" "}
          {props.isComa ? "je" : "uw"} originele reisplannen. Bedankt voor het invullen!
        </Typography>
      )}
      <Progress percentage={props.percentage} showPercentage={false} />
    </>
  );
};
