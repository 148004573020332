import React from "react";
import Typography from "@anwb/typography";
import { determineDayPartForDiagnosisDetermined } from "@status-updates/shared/src/business-logic/diagnosis-determined-daypart";
import { DateTime } from "luxon";
import { DiagnosisDeterminedMessage, TypedStatusUpdateMessage } from "@status-updates/types";
import { FrontendState, StateMetadata } from "../frontend-state";

export class DiagnosisDerminedState extends FrontendState<DiagnosisDeterminedMessage> {
  constructor(
    message: TypedStatusUpdateMessage<DiagnosisDeterminedMessage>,
    metadata: StateMetadata
  ) {
    super(message, metadata, {
      illustration: "auto-op-brug",
      title: "De diagnose is bijna klaar",
    });
  }

  renderTimelineItems() {
    const created = DateTime.fromISO(this.message.created);
    const dayPart = determineDayPartForDiagnosisDetermined(created);
    return [
      {
        timestamp: created,
        title: this.options.title,
        content: (
          <Typography tagName="div" data-e2e-test-type="status-updates-diagnosis-determined">
            Wij zijn alle gegevens van de diagnose van {this.getInformalOrFormal("jouw", "uw")}{" "}
            voertuig aan het verzamelen.
            <br />
            <br />
            Met deze informatie gaan wij {this.getInformalOrFormal("je", "u")} bellen om de volgende
            stappen met {this.getInformalOrFormal("je", "u")} te bespreken. Wij nemen hierover in de
            loop van de {dayPart} contact met {this.getInformalOrFormal("je", "u")} op.
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
