import styled from "styled-components";
import { pxToRem } from "@anwb/style-utilities";
import { PageTitle } from "@anwb/typography";
import { ButtonContainer } from "@anwb/button";

export const ForeignRoadsideAssistanceContainer = styled.section`
  padding: ${({ theme }) => pxToRem(theme.spacing["200"])};

  ${PageTitle} {
    margin-top: 14px;
    margin-bottom: 14px;
  }

  ${ButtonContainer} {
    width: auto;
    display: flex;
  }

  .registration-updated-time {
    padding: 0.7rem 0;
  }
`;

export const StyledHulpdienstContainer = styled.div`
  display: flex;
  flex-direction: column;
  a {
    margin-top: 0.375em;
  }
`;
