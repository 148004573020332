import { BackendApi } from "./generated/Backend";

export function createClient() {
  return new BackendApi({
    BASE: window.statusUpdatesSettings.apiBaseUrl,
    HEADERS: {
      "x-anwb-client-id": window.statusUpdatesSettings.apiKey,
    },
  });
}
