import styled from "styled-components";

// cancel-failed-notification has all its styling properties overwritten, because the Notification component and the notification from the Form component look different
export const StyledInformation = styled.section`
  .cancel-page {
    margin: 0 auto;
    padding: 5px 12px;
    max-width: ${({ theme }) => `${theme.viewportBreakpoint.sm}px`};
  }

  .successfull-cancel-page {
    margin: 0 auto;
    padding: 5px 12px;
    max-width: ${({ theme }) => `${theme.viewportBreakpoint.sm}px`};
    display: flex;
    flex-direction: column;
  }

  h1 {
    padding-top: 2rem;
    font-style: normal;
  }

  h5 {
    margin-top: 0.2rem !important;
  }

  .cancel-page div {
    max-width: 92%;
  }

  .cancel-failed-notification {
    border: 0.06666666666666667rem solid #003d86;
    border-radius: 0.5333333333333333rem;
    display: grid;
    grid-column-gap: 0.8rem;
    grid-row-gap: 0.5333333333333333rem;
    grid-template-columns: min-content auto min-content;
    grid-template-rows: auto;
    grid-template-areas:
      "icon title close-button"
      ". content content";
    padding: 0.8rem;
    width: 100%;
    height: auto;
    margin-top: 0 !important;
    margin-bottom: 0.8rem !important;
    background-color: #faebeb;
    border-color: #bb0606;
    max-width: 92%;
  }

  .cancel-failed-notification h5 {
    margin-top: 0 !important;
  }

  .cancel-failed-notification span {
    font-size: 1.0666666666666667rem;
  }

  .cancel-button {
    width: 100%;
    margin-top: 2rem !important;
  }

  .call-button {
    width: 100%;
  }

  .call-button-link {
    text-decoration: none;
    color: #ffffff;
  }

  .close-button {
    width: 100%;
    margin-top: 2rem !important;
  }

  .happy-customer-illustration {
    align-self: center;
  }
`;
