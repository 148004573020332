import Typography from "@anwb/typography";
import { DateTime } from "luxon";
import {
  ReplacementVehicleHandInMessageDetails,
  TypedStatusUpdateMessage,
} from "@status-updates/types";
import { renderHandInInfo, renderHandInInformation } from "./renderReplacementVehicleInfo";
import { FrontendState, type StateMetadata } from "../frontend-state";

export class ReplacementVehicleHandInState extends FrontendState<ReplacementVehicleHandInMessageDetails> {
  constructor(
    message: TypedStatusUpdateMessage<ReplacementVehicleHandInMessageDetails>,
    metadata: StateMetadata
  ) {
    super(message, metadata, {
      illustration: "huurauto-sleutels",
      title: "Herinnering inleveren huurauto",
    });
  }

  renderTimelineItems() {
    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: this.options.title,
        content: (
          <Typography data-e2e-test-type="status-updates-replacement-vehicle-handin" tagName="div">
            {renderHandInInformation(
              {
                address: this.message.messageDetails.handInLocation.address,
                name: this.message.messageDetails.handInLocation.name,
              },
              this.message.messageDetails.handInDeadline,
              false
            )}
            {renderHandInInfo(this.isComa, this.message.metadata?.breakdownLocationCountryName)}
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
