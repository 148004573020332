import styled from "styled-components";
import { FunctionComponent } from "react";

import intake from "./svg/intake.svg";
import alarmcentrale from "./svg/alarmcentrale.svg";
import diagnosegarage from "./svg/diagnose-garage.svg";
import sleepwagengarage from "./svg/sleepwagen-garage.svg";
import sleepwagenonderweg from "./svg/sleepwagen-onderweg.svg";
import hulpnodig from "./svg/auto-gerepareerd.svg";
import huurautoSleutels from "./svg/huurauto-sleutels.svg";
import autoOpBrug from "./svg/auto-op-brug.svg";
import internationaalTransport from "./svg/internationaal-transport.svg";
import wegenwacht from "./svg/wegenwacht.svg";
import blijeKlant from "./svg/customer-happy.svg";

export type Variants =
  | "alarmcentrale"
  | "diagnose-garage"
  | "intake"
  | "sleepwagen-garage"
  | "sleepwagen-onderweg"
  | "auto-gerepareerd"
  | "huurauto-sleutels"
  | "auto-op-brug"
  | "internationaal-transport"
  | "wegenwacht"
  | "blije-klant";

type Props = {
  variant: Variants;
  className?: string;
};

const illustrationMapping: Record<Variants, { illustration: unknown }> = {
  alarmcentrale: {
    illustration: alarmcentrale,
  },
  intake: {
    illustration: intake,
  },
  "diagnose-garage": {
    illustration: diagnosegarage,
  },
  "sleepwagen-garage": {
    illustration: sleepwagengarage,
  },
  "sleepwagen-onderweg": {
    illustration: sleepwagenonderweg,
  },
  "auto-gerepareerd": {
    illustration: hulpnodig,
  },
  "huurauto-sleutels": {
    illustration: huurautoSleutels,
  },
  "auto-op-brug": {
    illustration: autoOpBrug,
  },
  "internationaal-transport": {
    illustration: internationaalTransport,
  },
  wegenwacht: {
    illustration: wegenwacht,
  },
  "blije-klant": {
    illustration: blijeKlant,
  },
};

export const StyledIllustration = styled.img`
  width: 70%;
  max-width: 300px;
  margin: 0 auto;

  @media (max-height: 520px) {
    display: none;
  }

  @media (max-width: 300px) {
    display: none;
  }
`;

export const Illustration: FunctionComponent<Props> = ({ variant }) => (
  <StyledIllustration src={illustrationMapping[variant].illustration as string} />
);
