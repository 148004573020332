import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import Checkbox from "@anwb/checkbox";
import Icon from "@anwb/icon";
import Typography from "@anwb/typography";
import { StyledPushNotification } from "./styled";
import { GtmData, useGoogleTagManager } from "../../hooks/useGoogleTagManager";
import { usePushNotification } from "../../hooks/usePushNotification";
import { useSearchParams } from "react-router-dom";

export const PushNotification: FunctionComponent = () => {
  const sendEventToGtm = useGoogleTagManager();
  const notification = usePushNotification();
  const [searchParams] = useSearchParams();
  const isReadOnly = searchParams.get("isReadOnly") === "true";
  const [isDismissed, setDismissed] = useState(false);

  // Some phones or browsers do not support the Push notification API,
  // therefore we do not show the opt-in banner
  if (!notification.isSupported || notification.permission !== "default" || isDismissed) {
    return null;
  }

  if (isReadOnly) {
    return null;
  }

  return (
    <GtmStyledPushNotification>
      <div className="header">
        <Checkbox
          label="Ik wil notificaties op mijn telefoon ontvangen"
          name="Checkbox"
          reference="checkbox-01"
          variant="basic"
          onClick={() => {
            if (notification.isSupported) {
              notification.requestPermission();
            }
            gtmPermissionClick("valid", sendEventToGtm);
          }}
        />
        <Icon
          style={{ cursor: "pointer", marginLeft: "auto" }}
          variant={"cross"}
          onClick={() => {
            setDismissed(true);
            gtmPermissionClick("sluit", sendEventToGtm);
          }}
        />
      </div>
      <Typography className="notification-description" variant="sticker-text-medium">
        Ontvang push notificaties zodra er nieuws is over jouw pechmelding.
      </Typography>
    </GtmStyledPushNotification>
  );
};

function gtmPermissionClick(permission: string, sendEventToGtm: (event: GtmData) => void) {
  sendEventToGtm({
    type: "push notification",
    component: "push notification",
    action: "click",
    actionValue: permission,
  });
}

export type Props = {
  children: ReactNode;
};

const GtmStyledPushNotification: FunctionComponent<Props> = ({ children }) => {
  const sendEventToGtm = useGoogleTagManager();
  useEffect(() => {
    sendEventToGtm({
      event: "visibility",
      type: "page",
      component: "push notificatie",
      text: "Ik wil notificaties op mijn telefoon ontvangen",
    });
  }, []);
  return <StyledPushNotification>{children}</StyledPushNotification>;
};
