export function determineRentalOpeningHourInformation(country: string | undefined): string {
  if (!country) {
    return "Let op, het verhuurdbedrijf kan gesloten zijn tijdens de lunch.";
  }

  if (country === "Frankrijk") {
    return "Let op, in Frankrijk sluiten de garages tussen 12:00 en 14:00 uur.";
  }

  return `Let op, in ${country} kunnen de verhuurbedrijven gesloten zijn tijdens de lunch.`;
}

export function determineInformationPageTitle(countryName: string | undefined): string {
  return `Pech tijdens je reis in ${countryName}`;
}

export function determineInformationLinkText(subject: string): string {
  return `Meer over ${subject}`;
}
