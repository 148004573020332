// TODO: Figure out how to get eslint to understand that we are in a browser environment...
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import type {
  CaseReceivedMessageDetails,
  StatusUpdateMessage,
  StatusUpdatesMessageType,
} from "@status-updates/types";
import { DateTime } from "luxon";

export function findCaseReceivedMessage(
  messages: StatusUpdateMessage[]
): CaseReceivedMessageDetails | undefined {
  const result = messages.find(
    (message) =>
      message.messageType === "CaseReceivedMessage" ||
      message.messageType === "InitCaseReceivedMessage" ||
      message.messageType === "CaseReceivedDispatchMessage"
  );

  return (result?.messageDetails as CaseReceivedMessageDetails) || result;
}

export function sortMessages(messages: StatusUpdateMessage[]): StatusUpdateMessage[] {
  return messages.sort(({ created: aTimestamp }, { created: bTimestamp }) => {
    const aDate = DateTime.fromISO(aTimestamp).toMillis();
    const bDate = DateTime.fromISO(bTimestamp).toMillis();

    return bDate - aDate;
  });
}

const availableCurrentStatuses: Record<StatusUpdatesMessageType, string> = {
  InitCaseReceivedMessage: "Hulpverlening gestart mijn case",
  CaseReceivedMessage: "Hulpverlening gestart algemeen",
  CaseReceivedSecondMessage: "Hulpverlening gestart algemeen",
  CaseReceivedThirdMessage: "Hulpverlening gestart algemeen",
  CaseReceivedDispatchMessage: "Melding goed ontvangen",
  ServiceMessage: "Er is een hulpdienst onderweg",
  NeedHelpMessage: "Heb je nog hulp nodig?",
  AgreeToDiagnosisMessage: "Toestemming nodig",
  AppointmentMessage: "De hulpdienst is onderweg (afspraak)",
  DetermineDiagnosisMessage: "Diagnose van jouw voertuig opvragen",
  DiagnosisDeterminedMessage: "Diagnose wordt afgerond",
  DiagnosisNoAnswer: "Jouw voertuig wacht op diagnose",
  DiagnosisNotCompleted: "De diagnose is nog niet compleet",
  DiagnosisReadyNoAnswerCustomer: "Diagnose is bekend",
  EtaUpdatedMessage: "De hulpdienst is vertraagd",
  FindReplacementVehicle: "Wij gaan op zoek naar vervangend vervoer",
  InternationalTransportPlanned: "Jouw voertuig wordt ingepland voor transport",
  InternationalTransportIsPlanned: "Jouw voertuig wordt ingepland voor transport",
  LocalizeProviderLocalized: "Wij hebben een garage gevonden",
  LocalizeProviderStarted: "Wij zijn op zoek naar een geschikte garage",
  PlannedRepair: "Reparatie is ingepland",
  RepairFinished: "Reparatie is gereed",
  RepairNotYetFinished: "De reparatie is nog niet klaar",
  ReplacementVehicleContractUpdated: "Het huurcontract is aangepast",
  ReplacementVehicleDelivery: "De huurauto wordt bij je gebracht",
  ReplacementVehicleHandIn: "Het is bijna tijd om de huurauto in te leveren",
  ReplacementVehiclePickUp: "Je kunt de huurauto ophalen",
  ReplacementVehiclePostponed: "Op zoek naar vervangend vervoer",
  TrinGarageCosts: "Er zijn kosten gemaakt in de garage",
  VehicleAlmostAtDestination: "Voertuig bijna op eindbestemming",
  VehicleUnloaded: "Jouw voertuig is aangekomen",
  TowingCompanyLocationMessage: "TowingCompanyLocationMessage",
  InternationalTransportStatus: "Status van het transport",
  SparxRoadsideAssistanceService: "De hulpdienst is onderweg",
  RepairOnSiteStartedMessage: "Reparatie gestart",
  WegenwachtAssignedMessage: "Wegenwacht toegewezen",
  WegenwachtInTransitMessage: "Wegenwacht onderweg",
  WegenwachtScheduleChangedMessage: "Wegenwacht planning gewijzigd",
  WegenwachtBusyInAreaMessage: "Wegenwacht het is druk in jouw regio",
  WegenwachtEndMessage: "Wegenwacht geen informatie meer beschikbaar",
  AppointmentConfirmedMessage: "Afspraakbevestiging",
  WegenwachtCancelledByUserMessage: "Wegenwacht geannuleerd",
  AppointmentPostponedMessage: "De wegenwacht komt later",
}; // when implementing TowingCompanyLocationMessage add the right status name

export function getCurrentStatus(lastMessageType: StatusUpdatesMessageType): string | undefined {
  const [, description] = Object.entries(availableCurrentStatuses).find(
    ([key]) => key === lastMessageType
  ) || [undefined, undefined];

  return description;
}

function getTitlePrefix(caseReceivedMessageDetails: CaseReceivedMessageDetails) {
  if (caseReceivedMessageDetails.contractMarket === "coma") {
    return "ANWB";
  }

  return caseReceivedMessageDetails.smsSender;
}

/**
 * Updates the page title based on the case information
 *
 * If no title element is present on the page, nothing is updated
 * @param caseReceivedMessage
 */
export function updateTitle(caseReceivedMessage: CaseReceivedMessageDetails) {
  const titleElement = document.querySelector("title");

  if (titleElement) {
    const titlePrefix = getTitlePrefix(caseReceivedMessage);
    const titleSuffix = "Status updates";
    titleElement.text = titlePrefix !== undefined ? `${titlePrefix} | ${titleSuffix}` : titleSuffix;
  }
}

/**
 * Updates the favicon based on the case information
 *
 * If no <link rel="icon" ...> element is present on the page, nothing is updated
 *
 * @param caseReceivedMessage
 */
export function updateFavicon(caseReceivedMessage: CaseReceivedMessageDetails) {
  if (caseReceivedMessage.contractMarket === "coma") {
    const link: HTMLElement | null = document.querySelector("link[rel*='icon']");
    if (link) {
      link.setAttribute("href", "/anwb.ico");
    }
  }
}

/**
 * Decides wether to use the formal or informal phrasing based on source
 * @param informal informal phrasing
 * @param formal formal phrasing
 * @param isComa whether source is coma
 */

export function getInformalOrFormal(informal: string, formal: string, isComa: boolean) {
  if (isComa) {
    return informal;
  }
  return formal;
}

/**
 * Replaces all booleans in given type to string
 * Usable when you need to get boolean values from the frontend within your type since browser elements don't support boolean values
 */
export type ReplaceBooleanWithString<T> = {
  [K in keyof T as Exclude<K, ["", ""]>]: T[K] extends boolean | undefined
    ? string | undefined
    : T[K] extends object | undefined
    ? ReplaceBooleanWithString<T[K]>
    : T[K];
};
