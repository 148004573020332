import { AppointmentMessageDetails, TypedStatusUpdateMessage } from "@status-updates/types";
import { DateTime } from "luxon";
import { getAppointmentTimeDescription } from "@status-updates/shared/src/business-logic/appointment-time";
import Typography from "@anwb/typography";
import { FrontendState, StateMetadata } from "../frontend-state";
import { serviceProviderText } from "../../utils/serviceProviderText";

export class AppointmentServiceState extends FrontendState<AppointmentMessageDetails> {
  constructor(
    message: TypedStatusUpdateMessage<AppointmentMessageDetails>,
    metadata: StateMetadata
  ) {
    super(message, metadata, {
      illustration: "sleepwagen-onderweg",
      title: "De hulpdienst is onderweg",
    });
  }

  renderTimelineItems() {
    const { appointment, provider } = this.message.messageDetails;

    // TODO: Handle timezones correctly
    // The description will provide a specific description for dates today or tomorrow. However, if
    // the times are in other timezones than the current time, this computation will be unpredictable.
    const appointmentTimeDescription = getAppointmentTimeDescription(
      DateTime.now().setZone("Europe/Amsterdam"),
      DateTime.fromISO(appointment.dateFrom, { setZone: true }),
      DateTime.fromISO(appointment.dateTo, { setZone: true })
    );

    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: "De hulpdienst is onderweg",
        content: (
          <Typography data-e2e-test-type="status-updates-appointment-message" tagName="div">
            We hebben de hulpdienst gevraagd om {this.getInformalOrFormal("jou", "u")} te helpen{" "}
            {appointmentTimeDescription}. De hulpdienst kan iets te vroeg of te laat zijn. Blijf in
            de buurt van {this.getInformalOrFormal("je", "uw")} voertuig en houd{" "}
            {this.getInformalOrFormal("je", "uw")} telefoon bij de hand. <br />
            <br /> De hulpdienst of sleepwagen kan kleine (nood-)oplossingen ter plaatse uitvoeren.
            Als de hulpdienst {this.getInformalOrFormal("je", "u")} niet ter plaatse kan helpen, dan
            brengen zij {this.getInformalOrFormal("jouw", "uw")} voertuig naar een garage. Als het
            nodig is, dan kan {this.getInformalOrFormal("je", "u")} in overleg met de hulpdienst
            meerijden.
            <br />
            <br />
            {serviceProviderText(provider, provider.providerName)}
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
