import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

export type GtmData = {
  event?: string;
  type: string;
  component: string;
  field?: string;
  action?: string;
  text?: string;
  actionValue?: string;
  category?: string;
  variant?: string;
  status?: string;
  product?: string;
};

/**
 * Sends the given event to google tag manager (gtm).
 *
 * Google Tag Manager is used for analytics purposes
 * @param event
 */
export function useGoogleTagManager(): (event: GtmData) => void {
  const sendDataToGTM = useGTMDispatch();

  return (event: GtmData): void => {
    if (event && window.statusUpdatesSettings.enableGA4) {
      sendDataToGTM({
        event: event.event ?? "user_interaction",
        ...event,
      });
    }
  };
}
