import React from "react";
import { TimelineItem } from "@anwb/timeline";
import { formatTimestamp } from "../../../utils/timestamp";
import { TimelineItemData } from "../../../states/frontend-state";

export function renderTimelineItem(
  { timestamp, title, content, feedback }: TimelineItemData,
  index: number
): JSX.Element {
  return (
    <TimelineItem
      title={title}
      timestamp={formatTimestamp(timestamp)}
      variant={index === 0 ? undefined : "checked"}
      key={`${title.split(" ").join("-")}-${index}`}
    >
      {typeof content === "function" ? content() : content}
      {feedback}
    </TimelineItem>
  );
}
