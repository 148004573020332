import styled from "styled-components";
import { pxToRem } from "@anwb/style-utilities";
import { PageTitle } from "@anwb/typography";

export const InternationalTransportContainer = styled.section`
  padding: ${({ theme }) => pxToRem(theme.spacing["200"])};

  ${PageTitle} {
    margin-top: 14px;
    margin-bottom: 14px;
  }
`;

export const CenteredImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonsContainer = styled.section`
  display: flex;
  flex-direction: column;
  padding: 0;

  > button:first-child {
    margin-bottom: 16px;
  }
`;
