import { StateMachineProvider } from "little-state-machine";
import { Route, Routes } from "react-router-dom";
import { TravelInformationFinal } from "./pages/TravelInformationFinal";
import { TravelInformationFormFirst } from "./pages/TravelInformationFirst";
import { TravelInformationFormSecond } from "./pages/TravelInformationSecond";
import { TravelInformationFormSummary } from "./pages/TravelInformationSummary";
import { initStore } from "./form-store";
import { useStatusUpdates } from "../../../../../hooks/useStatusUpdates";
import { ReplaceBooleanWithString } from "../../../../../utils";
import { travelInformationData } from "../../../../../../../features/api/generated/Backend";

// We ignore travelDocuments in this conversion since checkboxes which are used for these fields do return their value as boolean
export type TravelInfoForm = ReplaceBooleanWithString<
  Omit<travelInformationData, "travelEquipment">
> & { travelEquipment?: travelInformationData["travelEquipment"] };

export type TravelFormProps = {
  isComa: boolean;
};

export const TravelInformationForm = () => {
  initStore();

  const isComa = useStatusUpdates().caseReceivedMessage?.contractMarket === "coma";

  return (
    <>
      <StateMachineProvider>
        <Routes>
          <Route path="reisplanning" element={<TravelInformationFormFirst isComa={isComa} />} />
          <Route path="wie-en-wat" element={<TravelInformationFormSecond isComa={isComa} />} />
          <Route path="samenvatting" element={<TravelInformationFormSummary isComa={isComa} />} />
          <Route path="bedankt" element={<TravelInformationFinal isComa={isComa} />} />
        </Routes>
      </StateMachineProvider>
    </>
  );
};
