import React from "react";
import { createRoot } from "react-dom/client";

import App from "./components/app";
import * as serviceWorker from "./registerServiceWorker";
import "./index.less";
import { MonitoringProvider } from "./monitoring/rum-client";
import { ErrorBoundary } from "./monitoring/ErrorBoundary";

const container = document.getElementById("app");
const root = createRoot(container!);

root.render(
  <>
    {window.location.hostname !== "localhost" ? (
      <MonitoringProvider>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </MonitoringProvider>
    ) : (
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    )}
  </>
);

serviceWorker.register();
