import Icon from "@anwb/icon";
import Typography, { LabelTitle } from "@anwb/typography";
import { DateTime } from "luxon";
import { PanelContainer } from "@anwb/panel";
import type { Address } from "@status-updates/types";
import styled from "styled-components";
import type { ReactNode } from "react";
import Notification, { NotificationContainer, NotificationProps } from "@anwb/notification";

import { IconWegenwacht, IconWithPanel } from "./styled";
import { AddressWrapper } from "../../styled";
import {
  Illustration,
  StyledIllustration,
  Variants,
} from "../../../../../../components/illustration";
import { Divider } from "../../../../../../components/divider";

type WegenwachtUpdateProps = {
  panel?: {
    text: ReactNode;
    icon?: "car-wegenwacht" | "mechanic";
  };
  illustration?: Variants;
  title?: string;
  lastUpdated?: string;
  registrationTime?: string;
  /**  Having a breakdownLocation as well as a breakdownLocationHighway seems redundant. However,
   * breakdownLocation is currently populated by data coming from the caseItem, while information
   * about the highway location is coming from the caseMessagereceived. To fix this situation, some
   * refactoring would be required
   */
  breakdownLocation?: Address;
  breakdownLocationHighway?: {
    highway: string | undefined;
    hectometreMarker: number | undefined;
  };
  notification?: NotificationProps;
  additionalText?: string;
  footer?: ReactNode;
  children?: ReactNode;
};

const WegenwachtUpdateContainer = styled.div`
  width: 100%;
  max-width: ${({ theme }) => `${theme.viewportBreakpoint.sm}px`};
  padding: 0 14px;
  box-sizing: border-box;

  ${StyledIllustration} {
    align-self: center;
  }

  ${NotificationContainer} {
    box-sizing: border-box;
    margin-bottom: 0 !important;

    .PONCHO-notification-subtitle {
      margin-top: 0;
    }
  }

  .registration-updated-time {
    padding: 0.7rem 0;
  }

  ${LabelTitle} {
    padding: 0;
  }
`;

export function WegenwachtUpdate({
  panel,
  illustration,
  title = "Volg de wegenwacht",
  registrationTime,
  lastUpdated,
  notification,
  additionalText,
  breakdownLocation,
  breakdownLocationHighway,
  footer,
  children,
}: WegenwachtUpdateProps) {
  let highway;
  let hectometreMarker;

  if (breakdownLocationHighway) {
    highway = breakdownLocationHighway?.highway;
    hectometreMarker = breakdownLocationHighway?.hectometreMarker;
  }

  return (
    <WegenwachtUpdateContainer>
      <Typography variant="page-title">{title}</Typography>
      {illustration && <Illustration variant={illustration} />}
      {panel && (
        <section>
          <IconWithPanel>
            <IconWegenwacht>
              <Icon type="illustrative" size="xxl" variant={panel.icon ?? "car-wegenwacht"} />
            </IconWegenwacht>
            <PanelContainer $hasMedia={false}>
              <LabelTitle>{panel.text}</LabelTitle>
            </PanelContainer>
          </IconWithPanel>
          {registrationTime && lastUpdated && (
            <Typography variant="support-text" className="registration-updated-time">
              Melding {DateTime.fromISO(registrationTime).toLocaleString(DateTime.TIME_24_SIMPLE)} -
              Laatste update {DateTime.fromISO(lastUpdated).toLocaleString(DateTime.TIME_24_SIMPLE)}
            </Typography>
          )}
          {additionalText && <Typography>{additionalText}</Typography>}
          {notification && <Notification {...notification} />}
        </section>
      )}
      {children}
      {breakdownLocation && (
        <>
          <Divider />
          <AddressWrapper>
            <Typography variant="content-title" tagName="i">
              Pechlocatie
            </Typography>
            <Typography>
              <p>
                {breakdownLocation.street} {breakdownLocation.houseNumber}
              </p>
              <p>
                {breakdownLocation.postalCode} {breakdownLocation.city}
              </p>
              <p>
                {highway}
                {hectometreMarker ? `, hectometerpaal ${hectometreMarker}` : ""}
              </p>
              {breakdownLocation.comment ? (
                `"${breakdownLocation.comment}"`
              ) : (
                <i>Geen bijzonderheden</i>
              )}
            </Typography>
          </AddressWrapper>
        </>
      )}
      {footer && <>{footer}</>}
    </WegenwachtUpdateContainer>
  );
}
