import { FunctionComponent } from "react";
import styled from "styled-components";
import { DateTime } from "luxon";
import { formatTimestamp } from "../../utils/timestamp";

const Section = styled.section`
  padding: 14px;
  background-color: ${({ theme }) => theme.statusupdates.bgStatus};

  dl {
    display: grid;
    grid-template-columns: 85px auto;
    margin: 0;
  }

  dt {
    grid-column: 1;
    color: ${({ theme }) => theme.colors.base.textBody};
  }

  dd {
    grid-column: 2;
    margin-left: 0;
    font-weight: Bold;
    color: ${({ theme }) => theme.colors.base.textBody};
  }
`;

type Props = {
  registrationTime: string;
  caseNumber: string;
};

export const StatusUpdatesInformation: FunctionComponent<Props> = ({
  registrationTime,
  caseNumber,
}) => (
  <Section>
    <dl>
      <dt>melding:</dt>
      <dd>{formatTimestamp(DateTime.fromISO(registrationTime))}</dd>
      <dt>case nr:</dt>
      <dd>{caseNumber}</dd>
    </dl>
  </Section>
);
