import Typography from "@anwb/typography";
import { ButtonTertiary } from "@anwb/button";
import { DateTime } from "luxon";
import { LogicxTransportStatusDetails, TypedStatusUpdateMessage } from "@status-updates/types";
import { FrontendState, StateMetadata } from "../frontend-state";
import Icon from "@anwb/icon";
import { calculateDaysDifference } from "../../utils/timestamp";
import { ButtonLink } from "../../components/link";
import styled from "styled-components";

const StyledModified = styled.div`
  color: ${({ theme }) => theme.colors.informative.textSupport};
  padding-top: 0px;
  padding-bottom: 10px;
`;

export class InternationalTransportStatusState extends FrontendState<LogicxTransportStatusDetails> {
  private readonly vehicleType: string;
  constructor(
    message: TypedStatusUpdateMessage<LogicxTransportStatusDetails>,
    metadata: StateMetadata
  ) {
    super(message, metadata, {
      illustration: "internationaal-transport",
      title: `Status transport van ${FrontendState.getInformalOrFormal(
        "jouw",
        "uw",
        metadata.contractMarket
      )} ${message.messageDetails.vehicleType ?? "voertuig"}`,
    });
    this.vehicleType = message.messageDetails.vehicleType ?? "voertuig";
  }

  renderTimelineItems() {
    const created = DateTime.fromISO(this.message.created);
    const status = this.message.messageDetails.status;
    const lastUpdate = this.message.messageDetails.updatedDateTime;
    const formattedLastUpdate = lastUpdate
      ? DateTime.fromISO(lastUpdate).setLocale("nl-NL").toFormat("d MMMM' 'HH':'mm")
      : undefined;
    const days = calculateDaysDifference(this.message.messageDetails.eta);
    return [
      {
        timestamp: created,
        title: this.options.title,
        content: (
          <Typography data-e2e-test-type="status-updates-international-status" tagName="div">
            <Icon variant="live" type="ui" size="lg" />
            &nbsp;{toStatus(status)}
            <br />
            <br />
            {status !== "AFGELEVERD" && status !== "BIJNA_AFGELEVERD" && days && (
              <>
                Wij verwachten dat {this.getInformalOrFormal("jouw", "uw")} {this.vehicleType}{" "}
                aankomt over ongeveer: <br />
                <Icon variant="calendar" type="ui" size="lg" /> <b>{days} dagen</b>
                {lastUpdate && (
                  <Typography variant="support-text">
                    <StyledModified> Laatste update: {formattedLastUpdate}</StyledModified>
                  </Typography>
                )}
              </>
            )}
            Aankomst hangt af van verkeer, weekenden, rij- en rusttijden.{" "}
            {this.getInformalOrFormal("Je", "U")} ontvangt een bericht zodra{" "}
            {this.getInformalOrFormal("jouw", "uw")} {this.vehicleType} op de eindbestemming is
            aangekomen.
            <br />
            <br />
            Zorg dat {this.getInformalOrFormal("je", "u")} zelf{" "}
            {this.getInformalOrFormal("jouw", "uw")} garage op de hoogte brengt, voordat{" "}
            {this.getInformalOrFormal("jouw", "uw")} {this.vehicleType} op de eindbestemming
            aankomt.
            <br />
            <br />
            We geven {this.getInformalOrFormal("je", "u")} graag informatie en tips over het
            transport van {this.getInformalOrFormal("jouw", "uw")} {this.vehicleType}.
            <br />
            <br />
            <ButtonLink href="informatie#voertuig-nederland" container={ButtonTertiary}>
              Informatie, tips en contact
            </ButtonLink>
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}

function toStatus(status: string) {
  switch (status) {
    case "GEREGISTREERD": {
      return "Ingepland";
    }
    case "OPGEHAALD": {
      return "Opgehaald";
    }
    case "BIJNA_AFGELEVERD": {
      return "Opgehaald";
    }
    case "AFGELEVERD": {
      return "Afgeleverd";
    }
    default: {
      return "";
    }
  }
}
