import { DefaultDetails, TypedStatusUpdateMessage } from "@status-updates/types";
import Typography from "@anwb/typography";
import { DateTime } from "luxon";
import { FrontendState, StateMetadata } from "../frontend-state";
import { MeldingAnnuleren } from "./melding-annuleren";

export class WegenwachtAssignedState extends FrontendState<DefaultDetails> {
  constructor(message: TypedStatusUpdateMessage<DefaultDetails>, metadata: StateMetadata) {
    super(message, metadata, {
      illustration: "alarmcentrale",
      title: `We sturen een wegenwacht naar ${FrontendState.getInformalOrFormal(
        "jou",
        "u",
        metadata.contractMarket
      )} toe`,
    });
  }

  renderTimelineItems() {
    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: this.options.title,
        content: (
          <Typography data-e2e-test-type="status-updates-wegenwacht-assigned-message" tagName="div">
            <p>
              Zorg dat {this.getInformalOrFormal("je", "u")} bereikbaar blijft.{" "}
              {this.getInformalOrFormal("Je", "U")} ontvangt een nieuwe update zodra{" "}
              {this.getInformalOrFormal("je", "u")} de wegenwacht{" "}
              {this.getInformalOrFormal("kan", "kunt")} volgen.
            </p>
            {this.metadata.showDigitalCancelButton && (
              <MeldingAnnuleren
                contractMarket={this.metadata.contractMarket}
                assistancePhonenumber={this.metadata.assistancePhonenumber}
              />
            )}
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
