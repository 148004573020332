import { ErrorBoundary as ReactErrorBoundary, FallbackProps } from "react-error-boundary";
import { Fonts } from "@anwb/theme";
import { ThemeProvider } from "styled-components";

import { StatusUpdatesDefaultTheme } from "../components/theme";
import { useMonitoring } from "./rum-client";
import { GenericErrorState } from "../components/content-state/states/error";

type Props = {
  children?: React.ReactNode;
};

export const Fallback: React.FC<FallbackProps> = ({ resetErrorBoundary }) => (
  <ThemeProvider theme={StatusUpdatesDefaultTheme}>
    <Fonts />
    <GenericErrorState retry={resetErrorBoundary} />
  </ThemeProvider>
);

export const ErrorBoundary: React.FC<Props> = ({ children }) => {
  const monitoringClient = useMonitoring();

  return (
    <ReactErrorBoundary
      FallbackComponent={Fallback}
      onError={(error: Error) => {
        if (monitoringClient !== undefined) {
          monitoringClient.recordError(error);
        }
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
};
