import Icon from "@anwb/icon";
import Typography from "@anwb/typography";
import { FunctionComponent, useState } from "react";
import classNames from "classnames";

import { StyledNavigation, NavigationLinkback } from "./styled";
import { BreadCrumbLink } from "../link";

type Props = {
  links?: { title: string; link: string }[];
  withStatusUpdatesNavigation?: boolean;
};

export const Navigation: FunctionComponent<Props> = ({
  links = [],
  withStatusUpdatesNavigation = true,
}) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <StyledNavigation>
      {withStatusUpdatesNavigation === true && (
        <NavigationLinkback>
          <BreadCrumbLink href="/">{"< Status updates"}</BreadCrumbLink>
        </NavigationLinkback>
      )}
      {links.length > 0 && (
        <section className="navigation navigation--quick">
          <Typography
            variant="button-link"
            key="a"
            className="dropdown__link navigation--quick__item"
            onClick={(): void => setOpen(!isOpen)}
          >
            <span
              className={classNames("collapsible__title", {
                "collapsible__title--active": isOpen,
              })}
            >
              Direct naar
            </span>
            <Icon
              type="ui"
              variant={isOpen ? "chevron-up" : "chevron-down"}
              size="sm"
              className="dropdown__icon"
            />
          </Typography>
          {links.length > 0 && isOpen && (
            <>
              {links.map(({ title, link }) => (
                <Typography
                  className="navigation--quick__item"
                  variant="button-link"
                  tagName="a"
                  key={link}
                  href={link}
                  onClick={() => setOpen(false)}
                >
                  {title}
                </Typography>
              ))}
            </>
          )}
        </section>
      )}
    </StyledNavigation>
  );
};
