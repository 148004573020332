import { useGoogleTagManager } from "../../hooks/useGoogleTagManager";
import { ActionTileTravelInformation } from "./tiles/ActionTileTravelInformation";
import type { form, feed } from "../../features/api/generated/Backend";
import { ActionTileInternationalTransport } from "./tiles/ActionTileInternationalTransport";
import { ActionTileForeignRoadsideAssistance } from "./tiles/ActionTileForeignRoadsideAssistance";

export const ActionTile: React.FC<form | feed> = (tile) => {
  const sendEventToGtm = useGoogleTagManager();

  function sendGAEvent(component: string) {
    sendEventToGtm({
      event: "user_interaction",
      type: "page",
      component,
      action: "click",
    });
  }

  switch (tile.name) {
    case "TRAVEL_INFORMATION":
      return <ActionTileTravelInformation sendGAEvent={sendGAEvent} />;
    case "INTERNATIONAL_TRANSPORT":
      return <ActionTileInternationalTransport feedData={tile} sendGAEvent={sendGAEvent} />;
    case "FOREIGN_ROADSIDE_ASSISTANCE":
      return <ActionTileForeignRoadsideAssistance feedData={tile} sendGAEvent={sendGAEvent} />;
    default:
      return null;
  }
};
