import Typography from "@anwb/typography";
import { DateTime } from "luxon";
import { PlannedRepairMessageDetails, TypedStatusUpdateMessage } from "@status-updates/types";
import { ButtonTertiary } from "@anwb/button";
import { FrontendState, StateMetadata } from "../frontend-state";
import { ButtonLink, InlineLink } from "../../components/link";
import { determineInformationLinkText } from "../../utils/countrySpecificText";
import { styledNameAndAddressWithMapsLink } from "../../utils/address-styler";

export class PlannedRepairState extends FrontendState<PlannedRepairMessageDetails> {
  constructor(
    message: TypedStatusUpdateMessage<PlannedRepairMessageDetails>,
    metadata: StateMetadata
  ) {
    super(message, metadata, {
      illustration: message.messageDetails.expectedCompletionTime
        ? "auto-op-brug"
        : "alarmcentrale",
      title: "De reparatie is ingepland",
    });
  }

  renderTimelineItems() {
    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: this.options.title,
        content: (
          <Typography data-e2e-test-type="status-updates-planned-repair-message" tagName="div">
            {this.message.messageDetails.expectedCompletionTime
              ? "De garage gaat aan de slag met de reparatie"
              : "De garage gaat de reparatie voorbereiden"}
            . Wij blijven de reparatie volgen en houden {this.getInformalOrFormal("je", "u")} op de
            hoogte.
            <br />
            {this.isComa && (
              <>
                <p>
                  De{" "}
                  <InlineLink href="informatie#diagnose-reparatie">
                    kosten van de reparatie
                  </InlineLink>{" "}
                  zijn voor eigen rekening.
                </p>
              </>
            )}
            <p>
              <strong>Reparatie wordt uitgevoerd door:</strong>
              <br />
              {styledNameAndAddressWithMapsLink(
                this.message.messageDetails.garage?.name,
                this.message.messageDetails.garage?.address
              )}
            </p>
            <ButtonLink container={ButtonTertiary} href="informatie#diagnose-reparatie">
              {determineInformationLinkText("reparatie")}
            </ButtonLink>
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
