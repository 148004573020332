import { DateTime } from "luxon";
import { Address } from "@status-updates/types";
import { styledNameAndAddressWithMapsLink } from "../../utils/address-styler";
import { determineRentalOpeningHourInformation } from "../../../src/utils/countrySpecificText";
import { getInformalOrFormal } from "../../routes/home/utils";
import { InlineLink } from "../../components/link";

export function renderHandInInformation(
  handInLocation: { address: Address | undefined; name: string | undefined },
  handInDeadlineISO: string,
  shouldRenderNewTag: boolean
): JSX.Element {
  const handInDeadline = DateTime.fromISO(handInDeadlineISO, {
    locale: "nl-NL",
  });
  const formattedHandInDate = handInDeadline.toFormat("d MMMM");
  const formattedHandInTime = handInDeadline.toLocaleString(DateTime.TIME_24_SIMPLE);

  return (
    <>
      <p>
        {shouldRenderNewTag && (
          <>
            <strong>Nieuwe gegevens:</strong>
            <br />
          </>
        )}
        <b>Inleveren</b>
        <br />
        {formattedHandInDate} uiterlijk om {formattedHandInTime} uur
        <br />
        {handInLocation.address
          ? styledNameAndAddressWithMapsLink(handInLocation.name, handInLocation.address)
          : "Locatie volgt in een latere update."}
      </p>
    </>
  );
}

export function renderHandInInfo(isComa: boolean, country?: string) {
  return (
    <>
      <p>
        Vergeet niet om de huurauto schoon in te leveren en de brandstoftank te vullen. Zorg ervoor
        dat {getInformalOrFormal("je", "u", isComa)} de huurauto voor het afgesproken tijdstip en op
        de afgesproken plaats inlevert. Hiermee{" "}
        {getInformalOrFormal("voorkom je", "voorkomt u", isComa)} extra kosten.
      </p>

      {country && <p>{determineRentalOpeningHourInformation(country)}</p>}
    </>
  );
}

export function renderPickUpInfo(
  pickUpLocation: { address: Address | undefined; name: string | undefined },
  pickUpTimeStampISO: string,
  isUpdated: boolean
): JSX.Element {
  const pickUpTimestamp = DateTime.fromISO(pickUpTimeStampISO, {
    locale: "nl-NL",
  });
  const formattedPickUpDate = pickUpTimestamp.toFormat("d MMMM");
  const formattedPickUpTime = pickUpTimestamp.toLocaleString(DateTime.TIME_24_SIMPLE);

  return (
    <>
      <p>
        {isUpdated && (
          <>
            <strong>Nieuwe gegevens:</strong>
            <br />
          </>
        )}
        <strong>Ophalen</strong>
        <br />
        {formattedPickUpDate} om {formattedPickUpTime} uur
        <br />
        {styledNameAndAddressWithMapsLink(pickUpLocation.name, pickUpLocation.address)}
      </p>
    </>
  );
}

export function renderBeOnTime(isComa: boolean) {
  return (
    <p>
      Zorg dat {isComa ? "je" : "u"} op tijd bent. {isComa ? "Heb je" : "Heeft u"} vertraging? Neem
      dan direct contact op met het uitgiftepunt. Als {isComa ? "je" : "u"} de auto niet kunt
      ophalen, laat het ons weten via de belknop rechtsboven. Als {isComa ? "je" : "u"} te laat bent
      of de auto niet ophaalt, kunnen er kosten in rekening worden gebracht en is de auto mogelijk
      niet meer beschikbaar.
    </p>
  );
}

export function renderPickupList(): JSX.Element {
  return (
    <p>
      Neem het volgende mee bij het ophalen van de huurauto:
      <ul style={{ listStyleType: "disc" }}>
        <li>Pinpas en creditcard</li>
        <li>Rijbewijs</li>
        <li>ID of paspoort</li>
      </ul>
    </p>
  );
}

export function renderInformationLink(isComa: boolean): JSX.Element {
  return (
    <p>
      Meer informatie over het ophalen {getInformalOrFormal("vind je", "vindt u", isComa)} via deze{" "}
      <InlineLink href="informatie#vervangend-vervoer">link</InlineLink>.
    </p>
  );
}
