import Typography from "@anwb/typography";
import { DateTime } from "luxon";
import { ButtonTertiary } from "@anwb/button";
import { AgreeToDiagnosisMessageDetails, TypedStatusUpdateMessage } from "@status-updates/types";
import { FrontendState, StateMetadata } from "../frontend-state";
import { ButtonLink, Link } from "../../components/link";
import { determineInformationLinkText } from "../../utils/countrySpecificText";

export class DiagnosisConfirmationState extends FrontendState<AgreeToDiagnosisMessageDetails> {
  constructor(
    message: TypedStatusUpdateMessage<AgreeToDiagnosisMessageDetails>,
    metadata: StateMetadata
  ) {
    super(message, metadata, {
      illustration: "diagnose-garage",
      title: `Wat als de hulpdienst ${FrontendState.getInformalOrFormal(
        "je",
        "uw",
        metadata.contractMarket
      )} voertuig niet ter plaatse kan repareren?`,
    });
  }

  renderTimelineItems() {
    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: this.options.title,
        content: (
          <Typography data-e2e-test-type="status-updates-agree-to-diagnosis-message" tagName="div">
            Soms kan de hulpdienst {this.getInformalOrFormal("je", "uw")} pech niet ter plaatse verhelpen. Deze brengt {this.getInformalOrFormal("je", "uw")} voertuig dan naar een garage.
            <br />
            <br />
            Laat {this.getInformalOrFormal("je", "uw")} sleutel achter én geef de hulpdienst of
            garage toestemming om onderzoek te doen naar de pechoorzaak.
            {this.metadata.contractMarket === "coma" && (
              <> De kosten van de diagnose zijn voor eigen rekening.</>
            )}
            {isAfter7pmOrSundays(this.message)
              ? generateAccommodationText(this.getInformalOrFormal.bind(this))
              : ""}
            {generateCallInformationText(this.metadata, this.getInformalOrFormal.bind(this))}
            <ButtonLink container={ButtonTertiary} href="informatie#diagnose-reparatie">
              {determineInformationLinkText("diagnose & reparatie")}
            </ButtonLink>
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}

function isAfter7pmOrSundays(message: TypedStatusUpdateMessage<AgreeToDiagnosisMessageDetails>) {
  const dateTime = DateTime.fromISO(message.created);
  const isAfterSevenPM = dateTime.hour >= 19;
  const isSunday = dateTime.weekday === 7;
  return isAfterSevenPM || isSunday;
}

function generateAccommodationText(
  getInformalOrFormal: (informal: string, formal: string) => string
) {
  return (
    <>
      <Typography>
        We raden {getInformalOrFormal("je", "u")} aan om te kijken naar een plek om te{" "}
        <Link inline href="informatie#hulp-ter-plekke">
          overnachten
        </Link>
        wanneer de hulpdienst {getInformalOrFormal("jouw", "uw")} voertuig niet gelijk kan repareren.{" "}
        {getInformalOrFormal("Je", "U")} kunt onverwachte kosten, zoals een overnachting, vaak na
        overleg bij {getInformalOrFormal("jouw", "uw")} reisverzekering terugvragen.
      </Typography>
    </>
  );
}

function generateCallInformationText(
  metadata: StateMetadata,
  getInformalOrFormal: (informal: string, formal: string) => string
) {
  return (
    <Typography>
      {getInformalOrFormal("Je", "U")} kan ons{" "}
      <Typography variant="link-text" href={`tel:${metadata.assistancePhonenumber || ""}`}>
        bellen
      </Typography>{" "}
      voor extra hulp bij:
      <ul style={{ listStyleType: "disc" }}>
        <li>Communicatie met de hulpdienst of de garage;</li>
        <li>Begeleiding van de reparatie.</li>
      </ul>
    </Typography>
  );
}
