import styled from "styled-components";

export const StyledNavigation = styled.nav`
  position: fixed;
  width: 100%;
  z-index: 5;
  background:${({ theme }) => theme.colors.accent.bgMainNavigation}; 

  .navigation {
    width: 100%;
    display: flex;
    flex-direction: column;

    &--quick {
        display: flex
        border-bottom: 2px solid ${({ theme }) => theme.colors.light.bgToggle};

      .dropdown__link {
        > .collapsible__title {
          flex: 1;

          &--active {
            color: ${({ theme }) => theme.colors.highlight.textInteractive};
          }
        }

        > .dropdown__icon {
          align-self: flex-end;
          color: ${({ theme }) => theme.colors.highlight.textInteractive};
        }
      }

      &__item {
        padding: 7px 10px;
        box-sizing: border-box;
        display: flex;
        flex: 1;
        border-bottom: 1px solid ${({ theme }) => theme.colors.light.borderDropdown};
        text-decoration: none;
      }
    }
  }
`;

export const NavigationLinkback = styled.section`
  background: ${({ theme }) => theme.colors.blanc.bgButton};
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 10px;
`;
