import Block from "@anwb/block";
import { useLinkBuilder } from "../../link";
import { useNavigate } from "react-router";
import type { foreignRoadsideAssistanceFeed } from "../../../features/api/generated/Backend";
import { FunctionComponent } from "react";
import { STATUS_PATH } from "../../app";
import { HighlightedText } from "../styled";

export const ActionTileForeignRoadsideAssistance: FunctionComponent<{
  feedData: foreignRoadsideAssistanceFeed;
  sendGAEvent: (component: string) => void;
}> = ({ feedData: { feedId }, sendGAEvent }) => {
  const link = useLinkBuilder(`status/${STATUS_PATH.FOREIGN_ROADSIDE_ASSISTANCE}/${feedId}`);
  const navigate = useNavigate();

  return (
    <Block
      labelText={`Volg de hulpdienst`}
      onClick={(e) => {
        e.preventDefault();
        sendGAEvent("status hulpdienst");
        navigate(link);
      }}
      href=""
      iconVariant="ask-question"
      hasArrow
      data-e2e-test-tile="tile-foreign-roadside-assistance"
    >
      <HighlightedText>Volg</HighlightedText> hier de laatste informatie van{" "}
      <HighlightedText>de hulpdienst</HighlightedText>.
    </Block>
  );
};
