import { AdvancedMarker, AdvancedMarkerProps, Map as GoogleMap } from "@vis.gl/react-google-maps";
import React, { FunctionComponent, ImgHTMLAttributes, ReactNode, useState } from "react";
import styled from "styled-components";
import { FullscreenControl } from "./controls/Fullscreen";
import { showDirections } from "./Directions";

type Props = {
  directions?: {
    origin: google.maps.LatLngLiteral;
    destination: google.maps.LatLngLiteral;
  };
  zoom?: number;
  markers?: {
    marker: AdvancedMarkerProps;
    img: ImgHTMLAttributes<HTMLImageElement>;
  }[];
  center?: google.maps.LatLngLiteral;
  isFullscreen?: boolean;
  toggleFullscreen?: () => void;
  children?: (isFullscreen?: boolean, distance?: string) => ReactNode;
};

const MapContainer = styled.div<{ $isFullscreen: boolean }>`
  position: relative;
  width: 100%;
  height: ${(props) => (props.$isFullscreen ? "calc(100vh - 92px)" : "50vh")};
`;

export const GoogleMaps: FunctionComponent<Props> = ({
  directions,
  center,
  markers = [],
  isFullscreen,
  toggleFullscreen = () => {},
  children,
}) => {
  const [googleDistance, setGoogleDistance] = useState<google.maps.DistanceMatrixResponse | null>(
    null
  );

  if (directions) {
    showDirections(directions, setGoogleDistance);
  }

  return (
    <MapContainer $isFullscreen={isFullscreen ?? false}>
      <GoogleMap
        style={{ height: "100%", width: "100%" }}
        defaultZoom={16}
        defaultCenter={center}
        gestureHandling="greedy"
        disableDefaultUI
        mapId="4fe4a6543c89b601"
      >
        {markers.map((marker, index) => (
          <AdvancedMarker key={index} {...marker.marker}>
            <img {...marker.img} />
          </AdvancedMarker>
        ))}
        {isFullscreen !== undefined && (
          <FullscreenControl isFullscreen={isFullscreen} toggleFullscreen={toggleFullscreen} />
        )}
        {children && (
          <>{children(isFullscreen, googleDistance?.rows[0].elements[0].distance.text)}</>
        )}
      </GoogleMap>
    </MapContainer>
  );
};
