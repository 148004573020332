import { DefaultThemeProps } from "@anwb/theme";
import styled, { css } from "styled-components";
import { pxToRem } from "@anwb/style-utilities";

const footerContainerStyle = ({ theme }: DefaultThemeProps) => css`
  display: flex;
  flex-direction: column;
  padding: 0 ${pxToRem(theme.spacing["200"])};

  > button:first-child {
    margin-bottom: 16px;
  }
`;

export const StyledFooter = styled.footer<DefaultThemeProps>`
  ${footerContainerStyle}
`;
