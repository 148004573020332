import { DefaultDetails, TypedStatusUpdateMessage } from "@status-updates/types";
import Typography from "@anwb/typography";
import { DateTime } from "luxon";
import { FrontendState, StateMetadata } from "../frontend-state";

export class AppointmentPostponedState extends FrontendState<DefaultDetails> {
  constructor(message: TypedStatusUpdateMessage<DefaultDetails>, metadata: StateMetadata) {
    super(message, metadata, {
      illustration: "alarmcentrale",
      title: "De wegenwacht komt later",
    });
  }

  renderTimelineItems() {
    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: this.options.title,
        content: (
          <Typography
            data-e2e-test-type="status-updates-appointment-postponed-message"
            tagName="div"
          >
            <p>
              Sorry, we moeten helaas melden dat de wegenwacht iets later komt. Zodra de wegenwacht
              onderweg is, {this.getInformalOrFormal("krijg je", "krijgt u")} hier een nieuwe
              update.
            </p>
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
