import Block from "@anwb/block";
import { useLinkBuilder } from "../../link";
import { useNavigate } from "react-router";
import { useStatusUpdates } from "../../../routes/home/hooks/useStatusUpdates";
import { HighlightedText } from "../styled";

export const ActionTileTravelInformation: React.FC<{
  sendGAEvent: (component: string) => void;
}> = ({ sendGAEvent }) => {
  const link = useLinkBuilder("scenario/1/reisplanning");
  const navigate = useNavigate();
  const isComa = useStatusUpdates().caseReceivedMessage?.contractMarket === "coma";

  return (
    <Block
      labelText={isComa ? "Vraag aan jou" : "Vraag aan u"}
      onClick={(e) => {
        e.preventDefault();
        sendGAEvent("deel je reisplanning");
        navigate(link);
      }}
      href=""
      iconVariant="ask-question"
      hasArrow
      data-e2e-test-tile="tile-travel-information"
    >
      <HighlightedText>Deel {isComa ? "je" : "uw"} reisplanning</HighlightedText> met ons! Klik hier
      om in te vullen.
    </Block>
  );
};
