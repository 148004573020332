import {
  AgreeToDiagnosisMessageDetails,
  AppointmentMessageDetails,
  CaseReceivedMessageDetails,
  DefaultDetails,
  DetermineDiagnosisMessageDetails,
  DiagnosisDeterminedMessage,
  EmptyMessageDetails,
  EtaUpdatedMessageDetails,
  FlexibleAppointmentMessageDetails,
  GarageMessageDetails,
  LocalizeProviderLocalizedMessageDetails,
  LogicxTransportStatusDetails,
  PlannedRepairMessageDetails,
  RepairNotYetFinishedMessageDetails,
  ReplacementVehicleDeliveryMessageDetails,
  ReplacementVehicleHandInMessageDetails,
  ReplacementVehicleContractUpdatedMessageDetails,
  ReplacementVehiclePickUpMessageDetails,
  ServiceMessageDetails,
  StatusUpdateMessage,
  TaskPostponedMessageDetails,
  TypedStatusUpdateMessage,
  WegenwachtInTransitMessageDetails,
} from "@status-updates/types";
import { PlannedRepairState } from "./states/coordinate-repair/planned-repair-state";
import { RepairFinishedState } from "./states/coordinate-repair/repair-finished";
import { RepairNotYetFinishedState } from "./states/coordinate-repair/repair-not-yet-finished";
import { DiagnosisConfirmationState } from "./states/diagnosis/agree-to-diagnosis-state";
import { DetermineDiagnosisState } from "./states/diagnosis/determine-diagnosis-state";
import { DiagnosisDerminedState } from "./states/diagnosis/diagnosis-determined";
import { DiagnosisPostponedNoAnswerState } from "./states/diagnosis/diagnosis-postponed-no-answer";
import { DiagnosisPostponedNotCompletedState } from "./states/diagnosis/diagnosis-postponed-not-completed";
import { DiagnosisReadyNoAnswerCustomerState } from "./states/diagnosis/diagnosis-ready-no-answer-customer";
import { CaseReceivedDispatchState } from "./states/dispatch/case-received-dispatch-state";
import { WegenwachtAssignedState } from "./states/dispatch/wegenwacht-assigned-state";
import { WegenwachtInTransitState } from "./states/dispatch/wegenwacht-in-transit-state";
import { WegenwachtScheduleChanged } from "./states/dispatch/wegenwacht-schedule-changed";
import { FrontendState } from "./states/frontend-state";
import { CaseReceivedState } from "./states/generic/case-received-state";
import { CaseReceivedThirdState } from "./states/generic/case-received-third-state";
import { LocalizeProviderLocalizedState } from "./states/localize-provider/localize-provider-localized";
import { LocalizeProviderStartedState } from "./states/localize-provider/localize-provider-started";
import { AppointmentServiceState } from "./states/repair-on-site/appointment-service-state";
import { DeploymentServiceState } from "./states/repair-on-site/deployment-service-state";
import { EtaUpdatedState } from "./states/repair-on-site/eta-updated-state";
import { NeedHelpState } from "./states/repair-on-site/need-help-state";
import { ServiceStartedState } from "./states/repair-on-site/service-started-state";
import { FindReplacementVehicleState } from "./states/replacement-vehicle/find-replacement-vehicle-state";
import { ReplacementVehicleContractUpdatedState } from "./states/replacement-vehicle/replacement-vehicle-contractupdated-state";
import { ReplacementVehicleDeliveryState } from "./states/replacement-vehicle/replacement-vehicle-delivery-state";
import { ReplacementVehicleHandInState } from "./states/replacement-vehicle/replacement-vehicle-handin-state";
import { ReplacementVehiclePickUpState } from "./states/replacement-vehicle/replacement-vehicle-pickup-state";
import { ReplacementVehiclePostponedState } from "./states/replacement-vehicle/replacement-vehicle-postponed-state";
import { TrinGarageCostsState } from "./states/transport-international/garage-costs-state";
import { InternationalTransportPlannedState } from "./states/transport-international/international-transport-planned-state";
import { InternationalTransportStatusState } from "./states/transport-international/international-transport-status-state";
import { InternationalTransportIsPlannedState } from "./states/transport-international/v2/international-transport-is-planned-state";
import { VehicleUnloadedState } from "./states/transport-international/vehicle-unloaded-state";
import { WegenwachtBusyInArea } from "./states/dispatch/wegenwacht-busy-in-area";
import { WegenwachtCancelledByUserState } from "./states/dispatch/wegenwacht-cancelled-by-user-state";
import { WegenwachtEndState } from "./states/dispatch/wegenwacht-end-state";
import { VehicleAlmostAtDestinationState } from "./states/transport-international/vehicle-almost-at-destination-state";
import { AppointmentConfirmedState } from "./states/dispatch/appointment-confirmed-state";
import { AppointmentPostponedState } from "./states/dispatch/appointment-postponed-state";
import { InternationalTransportIsEnRouteState } from "./states/transport-international/v2/international-transport-is-en-route-state";

export function mapMessageToState({
  message,
  token,
  showFeedback,
  assistancePhonenumber,
  countryName,
  countryCode,
  label,
  contractMarket,
  isReadOnly,
  showDigitalCancelButton,
}: {
  message: StatusUpdateMessage;
  token: string;
  showFeedback: boolean;
  assistancePhonenumber?: string;
  countryName?: string;
  countryCode?: string;
  label?: string;
  contractMarket?: string;
  isReadOnly?: boolean;
  showDigitalCancelButton?: boolean;
}) {
  const metadata = {
    token,
    countryName,
    countryCode,
    contractMarket,
    label,
    isReadOnly,
    assistancePhonenumber,
    showFeedback,
    showDigitalCancelButton,
  };

  switch (message.messageType) {
    case "CaseReceivedMessage":
      return new CaseReceivedState(
        message as TypedStatusUpdateMessage<CaseReceivedMessageDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "CaseReceivedSecondMessage":
      return null;
    case "CaseReceivedThirdMessage":
      return new CaseReceivedThirdState(
        message as TypedStatusUpdateMessage<CaseReceivedMessageDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "CaseReceivedDispatchMessage":
    case "InitCaseReceivedMessage":
      return new CaseReceivedDispatchState(
        message as TypedStatusUpdateMessage<CaseReceivedMessageDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "AppointmentConfirmedMessage":
      return new AppointmentConfirmedState(
        message as TypedStatusUpdateMessage<FlexibleAppointmentMessageDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "AppointmentPostponedMessage":
      return new AppointmentPostponedState(
        message as TypedStatusUpdateMessage<DefaultDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "WegenwachtEndMessage":
      return new WegenwachtEndState(message as TypedStatusUpdateMessage<DefaultDetails>, metadata);
    case "WegenwachtCancelledByUserMessage":
      return new WegenwachtCancelledByUserState(
        message as TypedStatusUpdateMessage<DefaultDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "WegenwachtScheduleChangedMessage":
      return new WegenwachtScheduleChanged(
        message as TypedStatusUpdateMessage<DefaultDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "WegenwachtBusyInAreaMessage":
      return new WegenwachtBusyInArea(
        message as TypedStatusUpdateMessage<DefaultDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "WegenwachtInTransitMessage":
      return new WegenwachtInTransitState(
        message as TypedStatusUpdateMessage<WegenwachtInTransitMessageDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "WegenwachtAssignedMessage":
      return new WegenwachtAssignedState(
        message as TypedStatusUpdateMessage<DefaultDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "DetermineDiagnosisMessage":
      return new DetermineDiagnosisState(
        message as TypedStatusUpdateMessage<DetermineDiagnosisMessageDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "RepairOnSiteStartedMessage":
      return new ServiceStartedState(message as TypedStatusUpdateMessage<DefaultDetails>, metadata);
    case "ServiceMessage":
      return new DeploymentServiceState(
        message as TypedStatusUpdateMessage<ServiceMessageDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "AppointmentMessage":
      return new AppointmentServiceState(
        message as TypedStatusUpdateMessage<AppointmentMessageDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "AgreeToDiagnosisMessage":
      return new DiagnosisConfirmationState(
        message as TypedStatusUpdateMessage<AgreeToDiagnosisMessageDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "DiagnosisNoAnswer":
      return new DiagnosisPostponedNoAnswerState(
        message as TypedStatusUpdateMessage<TaskPostponedMessageDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "DiagnosisNotCompleted":
      return new DiagnosisPostponedNotCompletedState(
        message as TypedStatusUpdateMessage<TaskPostponedMessageDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "DiagnosisReadyNoAnswerCustomer":
      return new DiagnosisReadyNoAnswerCustomerState(
        message as TypedStatusUpdateMessage<TaskPostponedMessageDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "FindReplacementVehicle":
      return new FindReplacementVehicleState(
        message as TypedStatusUpdateMessage<ServiceMessageDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "NeedHelpMessage":
      return new NeedHelpState(message as TypedStatusUpdateMessage<DefaultDetails>, metadata);
    case "DiagnosisDeterminedMessage":
      return new DiagnosisDerminedState(
        message as TypedStatusUpdateMessage<DiagnosisDeterminedMessage>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "ReplacementVehicleDelivery":
      return new ReplacementVehicleDeliveryState(
        message as TypedStatusUpdateMessage<ReplacementVehicleDeliveryMessageDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "ReplacementVehiclePickUp":
      return new ReplacementVehiclePickUpState(
        message as TypedStatusUpdateMessage<ReplacementVehiclePickUpMessageDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "ReplacementVehicleContractUpdated":
      return new ReplacementVehicleContractUpdatedState(
        // TODO ReplacementVehicleHandInMessageDetails kan verwijderd worden wanneer deze change lang genoeg live staat.
        message as TypedStatusUpdateMessage<
          ReplacementVehicleContractUpdatedMessageDetails | ReplacementVehicleHandInMessageDetails
        >,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "ReplacementVehicleHandIn":
      return new ReplacementVehicleHandInState(
        message as TypedStatusUpdateMessage<ReplacementVehicleHandInMessageDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "ReplacementVehiclePostponed":
      return new ReplacementVehiclePostponedState(
        message as TypedStatusUpdateMessage<TaskPostponedMessageDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "PlannedRepair":
      return new PlannedRepairState(
        message as TypedStatusUpdateMessage<PlannedRepairMessageDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "RepairNotYetFinished":
      return new RepairNotYetFinishedState(
        message as TypedStatusUpdateMessage<RepairNotYetFinishedMessageDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "LocalizeProviderStarted":
      return new LocalizeProviderStartedState(
        message as TypedStatusUpdateMessage<EmptyMessageDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "LocalizeProviderLocalized":
      return new LocalizeProviderLocalizedState(
        message as TypedStatusUpdateMessage<LocalizeProviderLocalizedMessageDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "RepairFinished":
      return new RepairFinishedState(
        message as TypedStatusUpdateMessage<GarageMessageDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "TrinGarageCosts":
      return new TrinGarageCostsState(
        message as TypedStatusUpdateMessage<DefaultDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "VehicleAlmostAtDestination":
      return new VehicleAlmostAtDestinationState(
        message as TypedStatusUpdateMessage<LogicxTransportStatusDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "VehicleUnloaded":
      return new VehicleUnloadedState(
        message as TypedStatusUpdateMessage<LogicxTransportStatusDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "InternationalTransportPlanned":
      return new InternationalTransportPlannedState(
        message as TypedStatusUpdateMessage<LogicxTransportStatusDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "InternationalTransportIsPlanned":
      return new InternationalTransportIsPlannedState(
        message as TypedStatusUpdateMessage<LogicxTransportStatusDetails>,
        metadata
      );
    case "InternationalTransportIsEnRoute":
      return new InternationalTransportIsEnRouteState(
        message as TypedStatusUpdateMessage<LogicxTransportStatusDetails>,
        metadata
      );
    case "InternationalTransportStatus":
      return new InternationalTransportStatusState(
        message as TypedStatusUpdateMessage<LogicxTransportStatusDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;
    case "EtaUpdatedMessage":
      return new EtaUpdatedState(
        message as TypedStatusUpdateMessage<EtaUpdatedMessageDetails>,
        metadata
      ) as FrontendState<StatusUpdateMessage["messageDetails"]>;

    // Instead of having a default clause, explicitly mention the message types currently not supported by the frontend.
    // The compiler will now force you to handle each state in the frontend (or choose not to).
    case "TowingCompanyLocationMessage":
      return handleUnsupportedMessageType(message.messageType);
  }
}

function handleUnsupportedMessageType(messageType: string) {
  console.error(`Message type: ${messageType} is not implemented`);
  return null;
}
