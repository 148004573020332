import { EtaUpdatedMessageDetails, TypedStatusUpdateMessage } from "@status-updates/types";
import { DateTime } from "luxon";
import Typography from "@anwb/typography";
import { FrontendState, StateMetadata } from "../frontend-state";
import { serviceProviderText } from "../../utils/serviceProviderText";

export class EtaUpdatedState extends FrontendState<EtaUpdatedMessageDetails> {
  constructor(
    message: TypedStatusUpdateMessage<EtaUpdatedMessageDetails>,
    metadata: StateMetadata
  ) {
    super(message, metadata, {
      illustration: "sleepwagen-onderweg",
      title: "De hulpdienst heeft vertraging",
    });
  }

  renderTimelineItems() {
    // TODO: niet renderen eta wanneer status == AFGELEVERD
    const { eta, provider } = this.message.messageDetails;
    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: this.options.title,
        content: (
          <Typography data-e2e-test-type="status-updates-eta-updated-message" tagName="div">
            Helaas heeft de hulpdienst laten weten dat ze vertraging hebben. Ze verwachten over
            ongeveer {eta.roundedEstimateInMinutes} minuten bij{" "}
            {this.getInformalOrFormal("je", "u")} te zijn. Blijf in de buurt van{" "}
            {this.getInformalOrFormal("je", "uw")} voertuig en houd{" "}
            {this.getInformalOrFormal("je", "uw")} telefoon bij de hand.
            <br />
            <br />
            {serviceProviderText(provider, provider.providerName)}
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
