import styled from "styled-components";

export const StyledHome = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  main {
    padding-top: ${({ theme }) => `calc(${theme.header.height} + ${theme.header.borderWidth})`};
  }
`;
