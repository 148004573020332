import { FunctionComponent, useEffect } from "react";
import { Navigation } from "../../../../components/navigation";
import { useGoogleTagManager } from "../../../../hooks/useGoogleTagManager";
import { StyledInformation } from "./styled";
import Typography, { ContentTitle, IntroText, PageTitle } from "@anwb/typography";
import { useStatusUpdates } from "../../hooks/useStatusUpdates";
import { getInformalOrFormal } from "../../utils";

const Safety: FunctionComponent = () => {
  const sendEventToGtm = useGoogleTagManager();
  const { caseReceivedMessage } = useStatusUpdates();
  const isComa = caseReceivedMessage?.contractMarket === "coma";

  useEffect(() => {
    sendEventToGtm({
      event: "user_interaction",
      type: "page",
      component: "veiligheid",
      action: "click",
    });
  }, [sendEventToGtm]);

  return (
    <StyledInformation>
      <Navigation />
      <div className="safety-information">
        <PageTitle>Veiligheid</PageTitle>
        <IntroText>
          Bij pech op de snelweg is het van belang dat{" "}
          {getInformalOrFormal("jij en je", "u en uw", isComa)} voertuig zo veilig mogelijk staan.
          Bekijk onze veiligheidstips hieronder.
        </IntroText>

        <ContentTitle>Het voertuig</ContentTitle>
        <Typography variant="body-text" tagName="ul">
          <li>
            Zet {getInformalOrFormal("je", "uw", isComa)} auto altijd zo ver mogelijk naar rechts,
            liefst in de berm.
          </li>
          <li>Draai de wielen naar rechts.</li>
          <li>Zet de alarmlichten aan.</li>
        </Typography>

        <ContentTitle>
          {getInformalOrFormal("Jij en je", "U en uw", isComa)} passagiers
        </ContentTitle>
        <Typography variant="body-text" tagName="ul">
          <li>Trek allemaal een veiligheidsvest aan.</li>
          <li>Laat alle passagiers aan de rechterzijde uitstappen.</li>
          <li>
            Blijf achter de vangrail of in de berm wachten op de hulpdienst, met zicht op het
            verkeer.
          </li>
          <li>Steek nooit de snelweg over, dat is levensgevaarlijk!</li>
        </Typography>

        <ContentTitle>Gevarendriehoek</ContentTitle>
        <Typography variant="body-text" tagName="ul">
          <li>
            {getInformalOrFormal("Sta je", "Staat u", isComa)} op de rijbaan van een regionale weg?{" "}
            Plaats dan een gevarendriehoek goed zichtbaar op ongeveer 30 meter achter{" "}
            {getInformalOrFormal("je", "uw", isComa)} voertuig.
          </li>
          <li>
            Doe dit alleen als het voor {getInformalOrFormal("jou", "u", isComa)} geen gevaar vormt.
          </li>
        </Typography>
      </div>
    </StyledInformation>
  );
};

export default Safety;
