import { FunctionComponent, useEffect } from "react";
import type {
  StatusUpdateMessage,
  WegenwachtInTransitMessageDetails,
  StatusUpdatesMessageType,
} from "@status-updates/types";
import { useNavigate } from "react-router";
import { useStatusUpdates } from "../../hooks/useStatusUpdates";
import { mapMessageToState } from "../../../../state-mapping";
import { StyledStatusUpdates } from "./styled";
import Footer from "../../../../components/footer";
import { FrontendState } from "../../../../states/frontend-state";
import StatusUpdatesComponent from "../../../../components/status-updates";
import { ONE_MINUTE_MS } from "../../../../hooks/usePollMessages";
import { useLinkBuilder } from "../../../../components/link";
import CookieBanner from "../../../../components/cookie-banner";

const ALLOWED_RPTP_DISPATCH_MESSAGETYPES: StatusUpdatesMessageType[] = [
  "InitCaseReceivedMessage",
  "CaseReceivedDispatchMessage",
  "AppointmentConfirmedMessage",
  "AppointmentPostponedMessage",
  "WegenwachtAssignedMessage",
  "WegenwachtBusyInAreaMessage",
  "WegenwachtScheduleChangedMessage",
  "WegenwachtInTransitMessage",
];

/**
 * Maps the given messages to a state in the frontend.
 *
 * Messages that cannot be mapped to a known state are discarded
 */
export function mapMessagesToKnownStates(
  messages: StatusUpdateMessage[],
  token: string,
  assistancePhonenumber?: string,
  countryName?: string,
  countryCode?: string,
  contractMarket?: string,
  label?: string,
  isReadOnly?: boolean
): FrontendState<StatusUpdateMessage["messageDetails"]>[] {
  return messages
    .map((message, index) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      mapMessageToState({
        message,
        token,
        showFeedback: index === 0,
        assistancePhonenumber,
        countryName,
        countryCode,
        label,
        contractMarket,
        isReadOnly,
        showDigitalCancelButton: shouldShowCancelButton(message, messages),
      })
    )
    .filter((state): state is FrontendState<StatusUpdateMessage["messageDetails"]> => !!state);
}

export function shouldShowCancelButton(
  currentMessage: StatusUpdateMessage,
  messages: StatusUpdateMessage[]
): boolean {
  if (messages.indexOf(currentMessage) !== 0) {
    return false;
  }

  if (!ALLOWED_RPTP_DISPATCH_MESSAGETYPES.includes(currentMessage.messageType)) {
    return false;
  }

  if (currentMessage.messageType === "WegenwachtInTransitMessage") {
    return (
      (currentMessage.messageDetails as WegenwachtInTransitMessageDetails).status !== "gearriveerd"
    );
  }

  return true;
}

const StatusUpdates: FunctionComponent = () => {
  const navigate = useNavigate();
  const { token, isReadOnly, messages, caseReceivedMessage, setRefetchInterval } =
    useStatusUpdates();

  const states = mapMessagesToKnownStates(
    messages,
    token,
    caseReceivedMessage?.assistancePhoneNumber,
    caseReceivedMessage?.breakdownLocationCountryName,
    caseReceivedMessage?.breakdownLocationCountry,
    caseReceivedMessage?.contractMarket,
    caseReceivedMessage?.contractLabelId,
    isReadOnly
  );

  const informationLink = useLinkBuilder("informatie");
  const safetyLink = useLinkBuilder("veiligheid");
  const breakdownLocationLink = useLinkBuilder("pechlocatie");

  // Set the polling interval back to normal when returning to the home page
  useEffect(() => setRefetchInterval(ONE_MINUTE_MS), []);

  return (
    <>
      <StyledStatusUpdates>
        <StatusUpdatesComponent states={states} caseReceivedMessage={caseReceivedMessage} />
      </StyledStatusUpdates>
      <Footer
        onInformationClick={() => navigate(informationLink)}
        onSafetyClick={() => navigate(safetyLink)}
        onBreakdownLocationClick={() => navigate(breakdownLocationLink)}
      />
      <CookieBanner />
    </>
  );
};

export default StatusUpdates;
