import { type FunctionComponent } from "react";
import { ScenarioForm } from "./components/ScenarioForm";
import { StyledStatusUpdates } from "../status-updates/styled";
import { useParams } from "react-router";

const ID_TO_FORM_NAME_MAP: Map<string, string> = new Map([["1", "TRAVEL_INFORMATION"]]);

export const Scenario: FunctionComponent = () => {
  const { id } = useParams();
  const name = ID_TO_FORM_NAME_MAP.get(id ?? "");

  if (!name) {
    return null;
  }

  return (
    <StyledStatusUpdates>
      <ScenarioForm {...{ type: "FORM", name }} />
    </StyledStatusUpdates>
  );
};
