import { Fragment, FunctionComponent, ReactNode, useEffect, useRef, useState } from "react";
import { useAccordionState } from "@anwb/accordion";
import { Accordion } from "@anwb/accordion";
import { useTracking } from "./UseTracking";
import { useGoogleTagManager } from "../../hooks/useGoogleTagManager";

type Props = {
  content: { title: string; content: JSX.Element }[];
};

export const GtmAccordion: FunctionComponent<Props> = ({ content }) => {
  /* For every accordion on a page, a unique ref is needed. 
    For every item in a accordion an initialState must be set (false means closed).
    isOpenState and toggle need to have the same unique number in one accordion item. */
  const [gtmEvent, setGtmEvent] = useState<{ index: number; isOpen: boolean } | undefined>(
    undefined
  );
  const ref = useRef(null);
  const track = useTracking({ ref, reference: "accordion" });
  const { isOpenStates, toggle } = useAccordionState({
    initialState: Array(content.length).fill(false) as boolean[],
    onChange: (index, isOpen) => setGtmEvent({ index, isOpen }),
  });

  useEffect(() => {
    if (gtmEvent) {
      track(gtmEvent.index, gtmEvent.isOpen);
    }
  }, [gtmEvent]);

  return (
    <Accordion ref={ref}>
      {content.map(
        (
          value: {
            title: string;
            content: ReactNode;
          },
          index
        ) => (
          <Fragment key={index}>
            <Accordion.Item isOpen={isOpenStates[index]} toggle={() => toggle(index)}>
              <Accordion.Header>
                <Accordion.Title>{value.title}</Accordion.Title>
              </Accordion.Header>
              <Accordion.Content>
                <Accordion.Body>{value.content}</Accordion.Body>
              </Accordion.Content>
            </Accordion.Item>
          </Fragment>
        )
      )}
    </Accordion>
  );
};
