import { FunctionComponent } from "react";
import AssistanceButton from "../../components/assistence-button/change-or-cancel-button";
import { ButtonLink } from "../../components/link";
import { ButtonTertiary } from "@anwb/button";
import { useGoogleTagManager } from "../../hooks/useGoogleTagManager";

type ButtonProps = {
  assistancePhonenumber: string | undefined;
  contractMarket: string | undefined;
};

export const MeldingAnnuleren: FunctionComponent<ButtonProps> = ({
  contractMarket,
  assistancePhonenumber,
}) => {
  const sendEventToGtm = useGoogleTagManager();
  const gtmCancelClick: () => void = () => {
    sendEventToGtm({
      type: "page",
      component: "melding annuleren",
      action: "click",
    });
  };

  return (
    <>
      {!contractMarket || contractMarket === "gzama" ? (
        <AssistanceButton assistancePhoneNumber={assistancePhonenumber} />
      ) : (
        <ButtonLink container={ButtonTertiary} onClick={gtmCancelClick} href="annuleren">
          Melding annuleren
        </ButtonLink>
      )}
    </>
  );
};
