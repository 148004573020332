import { CaseReceivedMessageDetails, TypedStatusUpdateMessage } from "@status-updates/types";
import Typography from "@anwb/typography";
import { DateTime } from "luxon";
import { FrontendState, StateMetadata } from "../frontend-state";

import { MeldingAnnuleren } from "./melding-annuleren";

export class CaseReceivedDispatchState extends FrontendState<CaseReceivedMessageDetails> {
  constructor(
    message: TypedStatusUpdateMessage<CaseReceivedMessageDetails>,
    metadata: StateMetadata
  ) {
    super(message, metadata, {
      illustration: "alarmcentrale",
      title: "Melding goed ontvangen",
    });
  }

  renderTimelineItems() {
    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: this.options.title,
        content: (
          <Typography
            data-e2e-test-type="status-updates-case-received-dispatch-message"
            tagName="div"
          >
            <p>
              We hebben {this.getInformalOrFormal("jouw", "uw")} pechmelding succesvol ontvangen. In
              dit scherm {this.getInformalOrFormal("word je", "wordt u")} op de hoogte gehouden van
              de status van {this.getInformalOrFormal("jouw", "uw")} melding. Zodra er meer nieuws
              is, {this.getInformalOrFormal("krijg je", "krijgt u")} een nieuwe update. Dit kan even
              duren. <br /> <br />
              {this.getInformalOrFormal("Jouw", "Uw")} veiligheid is belangrijk. Klik op de
              veiligheidsknop hieronder en volg de stappen. <br /> <br />
              {!this.message.messageDetails.isAppointment
                ? `Het is ons streven ${this.getInformalOrFormal(
                    "je",
                    "u"
                  )} binnen 1,5 uur te helpen.`
                : ""}
            </p>
            {this.metadata.showDigitalCancelButton && (
              <MeldingAnnuleren
                contractMarket={this.metadata.contractMarket}
                assistancePhonenumber={this.metadata.assistancePhonenumber}
              />
            )}
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
