import Typography from "@anwb/typography";
import { DateTime } from "luxon";
import {
  ReplacementVehicleDeliveryMessageDetails,
  TypedStatusUpdateMessage,
} from "@status-updates/types";
import { FrontendState, StateMetadata } from "../frontend-state";

export class ReplacementVehicleDeliveryState extends FrontendState<ReplacementVehicleDeliveryMessageDetails> {
  constructor(
    message: TypedStatusUpdateMessage<ReplacementVehicleDeliveryMessageDetails>,
    metadata: StateMetadata
  ) {
    super(message, metadata, {
      illustration: "huurauto-sleutels",
      title: `De huurauto wordt bij ${FrontendState.getInformalOrFormal(
        "je",
        "u",
        metadata.contractMarket
      )} gebracht`,
    });
  }

  renderTimelineItems() {
    const handInDeadline = DateTime.fromISO(this.message.messageDetails.handInDeadline, {
      locale: "nl-NL",
    });
    const formattedDate = handInDeadline.toFormat("d MMMM");
    const formattedTime = handInDeadline.toLocaleString(DateTime.TIME_24_SIMPLE);

    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: `De huurauto wordt bij ${this.getInformalOrFormal("je", "u")} gebracht`,
        content: (
          <Typography
            data-e2e-test-type="status-updates-replacement-vehicle-delivery"
            tagName="div"
          >
            <p>
              De chauffeur neemt contact met {this.getInformalOrFormal("je", "u")} op. Zorg dat{" "}
              {this.getInformalOrFormal("je", "u")} telefonisch bereikbaar blijft.
            </p>
            <p>
              Houd het volgende bij de hand voor de overdracht van de huurauto:
              <ul style={{ listStyleType: "disc" }}>
                <li>Pinpas en/of creditcard</li>
                <li>Rijbewijs</li>
                <li>ID of paspoort</li>
              </ul>
            </p>
            <p>
              <strong>Inleveren</strong>
              <br />
              {formattedDate} voor {formattedTime} uur
              <br />
              Locatie volgt in een latere update.
            </p>
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
