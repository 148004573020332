import Typography from "@anwb/typography";
import { DateTime } from "luxon";
import {
  Address,
  DetermineDiagnosisMessageDetails,
  TypedStatusUpdateMessage,
} from "@status-updates/types";
import { ButtonTertiary } from "@anwb/button";

import { FrontendState, StateMetadata } from "../frontend-state";
import { determineInformationLinkText } from "../../../src/utils/countrySpecificText";
import { styledNameAndAddressWithMapsLink } from "../../utils/address-styler";
import { ButtonLink } from "../../components/link";

export class DetermineDiagnosisState extends FrontendState<DetermineDiagnosisMessageDetails> {
  constructor(
    message: TypedStatusUpdateMessage<DetermineDiagnosisMessageDetails>,
    metadata: StateMetadata
  ) {
    super(message, metadata, {
      illustration: "auto-op-brug",
      title: "Wij nemen contact op met de garage",
    });
  }

  renderTimelineItems() {
    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: this.options.title,
        content: (
          <Typography data-e2e-test-type="status-updates-determine-diagnosis-message" tagName="div">
            <p>
              Wanneer {this.getInformalOrFormal("jouw", "uw")} voertuig bij de garage
              staat en de werkplaats open is, vragen wij het volgende:
              <ul style={{ listStyleType: "disc" }}>
                <li>Het probleem;</li>
                <li>De geschatte kosten;</li>
                <li>Hoelang het oplossen van het probleem gaat duren.</li>
              </ul>
            </p>
            <p>
              Als wij meer informatie hebben, nemen wij contact met{" "}
              {this.getInformalOrFormal("je", "u")} op. Als we zien dat het probleem niet
              snel op te lossen is, dan regelen we vervolghulp in overleg met{" "}
              {this.getInformalOrFormal("jou", "u")}.
            </p>
            <p>
              Laat {this.getInformalOrFormal("je", "uw")} sleutel achter én geef de garage
              toestemming om onderzoek te doen naar de pechoorzaak. De kosten van de diagnose zijn
              voor eigen rekening.
            </p>
            {determineGarageInfo(
              this.message.messageDetails.garage?.name,
              this.message.messageDetails.garage?.address
            )}
            <ButtonLink container={ButtonTertiary} href="informatie#diagnose-reparatie">
              {determineInformationLinkText("diagnose")}
            </ButtonLink>
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}

function determineGarageInfo(name?: string, address?: Address) {
  if (name === undefined || address === undefined) {
    return undefined;
  } // This if is for backward compatibility, can be removed after one month
  return (
    <p>
      We vragen de diagnose aan bij:
      <br />
      {styledNameAndAddressWithMapsLink(name, address)}
    </p>
  );
}
