import { useEffect, useState } from "react";
import type { StatusUpdateMessage } from "@status-updates/types";
import { useQuery } from "@tanstack/react-query";

export type Status = "idle" | "error" | "loading" | "success";

export const ONE_MINUTE_MS = 1000 * 60;
export const TEN_SECONDS_MS = 1000 * 10;
// counter for refresh GTM useeffect
let counter = 0;

type Props = {
  refetchInterval?: number;
  token: string;
  isReadOnly?: boolean;
};

export class HttpError extends Error {
  public statusCode: number;

  constructor(statusCode: number) {
    super(`HTTP error ${statusCode}`);
    this.statusCode = statusCode;
  }
}

function determineApiUrl(): string {
  return `${window.statusUpdatesSettings.apiBaseUrl}/messages`;
}

export type PollResult = {
  data?: { messages: StatusUpdateMessage[]; counter: number };
  error: unknown;
  status: Status;
  retry: () => void;
  setRefetchInterval: React.Dispatch<React.SetStateAction<number | undefined>>;
};

export function usePollMessages({
  token,
  isReadOnly,
  refetchInterval: initialRefetchInterval = ONE_MINUTE_MS,
}: Props): PollResult {
  const apiUrl = determineApiUrl();
  const [refetchInterval, setRefetchInterval] = useState<number | undefined>(
    initialRefetchInterval
  );

  const { status, data, error } = useQuery<{ messages: StatusUpdateMessage[]; counter: number }>(
    [`status-updates-${apiUrl}`],
    async (): Promise<{ messages: StatusUpdateMessage[]; counter: number }> => {
      const readOnly = isReadOnly === undefined ? false : isReadOnly === true;
      const url: string = `${apiUrl}?token=${token}&isReadOnly=${readOnly.toString()}`;

      const response = await fetch(url, {
        headers: {
          "x-anwb-client-id": window.statusUpdatesSettings.apiKey,
        },
      });

      if (!response.ok) {
        throw new HttpError(response.status);
      }

      const responseBody = (await response.json()) as { messages: StatusUpdateMessage[] };

      if (data === undefined) {
        return {
          messages: responseBody.messages,
          counter: 1,
        };
      }

      counter += 1;
      return {
        messages: responseBody.messages,
        counter,
      };
    },
    {
      refetchInterval: refetchInterval || false,
      retry: false,
      keepPreviousData: true,
      cacheTime: 0,
    }
  );

  // Stop polling if previous call resulted in an error
  useEffect(() => {
    if (error && error instanceof HttpError && error.statusCode >= 400 && error.statusCode <= 499) {
      console.log("Stop refreshing data due to HTTP 4xx error");
      setRefetchInterval(undefined);
    }
  }, [error]);

  return {
    status,
    data,
    error,
    retry: (): void => setRefetchInterval(initialRefetchInterval),
    setRefetchInterval,
  };
}
