import React from "react";
import Typography from "@anwb/typography";

type TravelDataProps = {
  title: string;
  value: string;
};

export const TravelData: React.FC<TravelDataProps> = ({ title, value }) => {
  return (
    <div style={{ alignContent: "center", padding: "4px 0" }}>
      <Typography variant="component-subtitle" style={{ margin: "0px" }}>
        {title}
      </Typography>
      <Typography variant="field-hint-text" style={{ margin: "0px", paddingTop: "8px" }}>
        {value}
      </Typography>
    </div>
  );
};
