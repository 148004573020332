import { DefaultThemeProps } from "@anwb/theme";
import styled, { css } from "styled-components";
import { PanelContainer, PanelWrapper } from "@anwb/panel";
import { pxToRem } from "@anwb/style-utilities";

const formStyle = ({ theme }: DefaultThemeProps) => css`
  ${PanelContainer}, ${PanelWrapper} {
    padding: ${pxToRem(theme.spacing["200"])};
  }
`;
export const StyledForm = styled.div`
  ${formStyle}
`;
