import React from "react";
import Typography from "@anwb/typography";
import Icon from "@anwb/icon";

type TravelItemProps = {
  title: string;
  value: string | boolean | undefined;
};

export const TravelItem: React.FC<TravelItemProps> = ({ title, value }) => {
  return (
    <Typography variant="field-hint-text" style={{ margin: "0" }}>
      {value === "true" || value === true ? (
        <Icon variant="checkbox" style={{ color: "#207100", margin: "4px" }} />
      ) : (
        <Icon variant="cross" style={{ color: "#990000", margin: "4px" }} />
      )}{" "}
      {title}
    </Typography>
  );
};
