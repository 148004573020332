/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { feed } from '../models/feed';
import type { form } from '../models/form';
import type { travelInformationData } from '../models/travelInformationData';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * GET all available form types that correspond to a given token
     * @param token The token used for getting the correct forms
     * @returns form Successful get of one or multiple forms
     * @throws ApiError
     */
    public getForms(
        token: string,
    ): CancelablePromise<Array<form>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/forms',
            query: {
                'token': token,
            },
            errors: {
                422: `Token was missing in request`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * POST to handle submitted form data
     * @param token The token that the posted information is associated with
     * @param requestBody
     * @returns any Successful POST of a form
     * @throws ApiError
     */
    public postFormsTravelInformation(
        token: string,
        requestBody: travelInformationData,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/forms/travel-information',
            query: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `No forms found`,
                422: `Token was missing in request`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * GET one available feed that correspond to a given token and id
     * @param id The id used for getting specific feed data
     * @param token The token used for getting the correct feed
     * @returns feed Successful get one feed
     * @throws ApiError
     */
    public getFeed(
        id: string,
        token: string,
    ): CancelablePromise<feed> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/feed',
            query: {
                'id': id,
                'token': token,
            },
            errors: {
                404: `No feed found corresponding to given token and id`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * GET all available feeds that correspond to a given token
     * @param token The token used for getting the correct feeds
     * @returns feed Successful get of zero or more feeds in list
     * @throws ApiError
     */
    public getFeeds(
        token: string,
    ): CancelablePromise<Array<feed>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/feeds',
            query: {
                'token': token,
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

}
