import Icon from "@anwb/icon";
import Typography from "@anwb/typography";
import { FunctionComponent } from "react";
import styled from "styled-components";

export const StyledFullscreenButton = styled.div<{ fullscreen: boolean }>`
  position: absolute;
  width: ${(props) => (props.fullscreen ? "125px" : "150px")};
  height: 40px;
  left: 50%;
  top: calc(100% - 40px);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  padding: 0 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.highlight.iconInteractive};
`;

type Props = {
  isFullscreen: boolean;
  toggleFullscreen: () => void;
};

export const FullscreenControl: FunctionComponent<Props> = ({ isFullscreen, toggleFullscreen }) => {
  return (
    <StyledFullscreenButton onClick={toggleFullscreen} fullscreen={isFullscreen}>
      {isFullscreen ? (
        <Icon variant="normal-mode" type="ui" size="lg" />
      ) : (
        <Icon variant="fullscreen-mode" type="ui" size="lg" />
      )}
      <Typography variant="label-title" className="fullscreen-button" tagName="span">
        {isFullscreen ? "Kaart sluiten" : "Toon vergroting"}
      </Typography>
    </StyledFullscreenButton>
  );
};
