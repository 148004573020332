import { ButtonPrimary } from "@anwb/button";
import Typography from "@anwb/typography";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { Illustration } from "../../../../../../../../components/illustration";
import { StyledHeader } from "../styles/Header";
import { FormBreadcrumb } from "../../../../../../../../components/bread-crumb/ReturnToStatusUpdatesBreadCrumb";
import { TravelFormProps } from "../TravelInformationForm";
import { useGoogleTagManager } from "../../../../../../../../hooks/useGoogleTagManager";
import { createGATravelInformationEvent } from "../util";

const StyledBody = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacings.horizontalPadding}`};
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const StyledFooter = styled.footer`
  padding: ${({ theme }) => `0 ${theme.spacings.horizontalPadding}`};
`;

const StyledTravelInformationFinal = styled.div`
  height: ${({ theme }) => `calc(100vh - ${theme.header.height} - 24px)`};
  display: flex;
  flex-direction: column;
`;

export const TravelInformationFinal = (props: TravelFormProps) => {
  const navigate = useNavigate();
  const sendEventToGtm = useGoogleTagManager();

  return (
    <StyledTravelInformationFinal>
      <FormBreadcrumb />
      <StyledHeader>
        <Typography variant="page-title">Bedankt!</Typography>
      </StyledHeader>
      <StyledBody>
        <Illustration variant="blije-klant" />
        <Typography>
          Wij doen ons best om passende hulp te regelen voor {props.isComa ? "jou" : "u"}. Bedankt
          voor het invullen!
        </Typography>
      </StyledBody>
      <StyledFooter>
        <ButtonPrimary
          onClick={() => {
            sendEventToGtm(createGATravelInformationEvent("sluit", "Bedankt!"));
            navigate("../../");
          }}
          data-e2e-test-type="close"
        >
          Sluit
        </ButtonPrimary>
      </StyledFooter>
    </StyledTravelInformationFinal>
  );
};
