import Typography from "@anwb/typography";
import { ButtonTertiary } from "@anwb/button";
import { DateTime } from "luxon";
import { DefaultDetails, TypedStatusUpdateMessage } from "@status-updates/types";
import { FrontendState, StateMetadata } from "../frontend-state";
import { ButtonLink } from "../../components/link";

export class TrinGarageCostsState extends FrontendState<DefaultDetails> {
  constructor(message: TypedStatusUpdateMessage<DefaultDetails>, metadata: StateMetadata) {
    super(message, metadata, {
      illustration: "auto-op-brug",
      title: "Er zijn kosten gemaakt in de garage",
    });
  }

  renderTimelineItems() {
    const created = DateTime.fromISO(this.message.created);
    return [
      {
        timestamp: created,
        title: this.options.title,
        content: (
          <>
            <Typography data-e2e-test-type="status-updates-trin-garage-costs" tagName="div">
              Er moeten nog diagnose- en/of reparatiekosten betaald worden.{" "}
              {this.getInformalOrFormal("Jouw", "Uw")}{" "}
              {this.isComa ? "Wegenwachtpakket" : "mobiliteitsservice"} dekt deze kosten niet. Geen
              zorgen, wij hebben de kosten voorgeschoten. {this.getInformalOrFormal("Je", "U")}{" "}
              ontvangt binnenkort een rekening om de ANWB terug te betalen.
            </Typography>
            <br />
            <ButtonLink href="informatie#diagnose-reparatie" container={ButtonTertiary}>
              Informatie en tips
            </ButtonLink>
          </>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
