import Typography from "@anwb/typography";
import { DateTime } from "luxon";
import { GarageMessageDetails, TypedStatusUpdateMessage } from "@status-updates/types";
import { styledNameAndAddressWithMapsLink } from "../../utils/address-styler";
import { FrontendState, StateMetadata } from "../frontend-state";

export class RepairFinishedState extends FrontendState<GarageMessageDetails> {
  constructor(message: TypedStatusUpdateMessage<GarageMessageDetails>, metadata: StateMetadata) {
    super(message, metadata, {
      illustration: "auto-gerepareerd",
      title: "De reparatie is klaar",
    });
  }

  renderTimelineItems() {
    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: this.options.title,
        content: (
          <Typography data-e2e-test-type="status-updates-repair-finished" tagName="div">
            <p>
              Goed nieuws! {this.getInformalOrFormal("Je", "Uw")} voertuig is gerepareerd en {this.getInformalOrFormal("je", "u")} kunt deze ophalen.
              <br />
              {this.metadata.countryCode === "FR" &&
                "Let op, in Frankrijk sluiten de garages tussen 12:00 en 14:00 uur."}
            </p>

            {this.metadata.contractMarket === "coma" && (
              <p>
                De kosten van de reparatie {this.getInformalOrFormal("betaal je", "betaalt u")} bij
                het ophalen van
                {this.getInformalOrFormal("jouw", "uw")} voertuig. Dit kan meestal met een
                creditcard of pinpas.
              </p>
            )}

            <p>
              <strong>Voertuig ophalen</strong>
              <br />
              {styledNameAndAddressWithMapsLink(
                this.message.messageDetails.garage?.name,
                this.message.messageDetails.garage?.address
              )}
            </p>
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
