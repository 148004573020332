import Block from "@anwb/block";
import { useLinkBuilder } from "../../link";
import { useNavigate } from "react-router";
import type { internationalTransportFeed } from "../../../features/api/generated/Backend";
import { FunctionComponent } from "react";
import { useStatusUpdates } from "../../../routes/home/hooks/useStatusUpdates";
import { calculateDaysDifference, calculateWorkDaysDifference } from "../../../utils/timestamp";
import { STATUS_PATH } from "../../app";
import { HighlightedText } from "../styled";

export const ActionTileInternationalTransport: FunctionComponent<{
  feedData: internationalTransportFeed;
  sendGAEvent: (component: string) => void;
}> = ({ feedData: { feedId, vehicleType, eta, status }, sendGAEvent }) => {
  const { isComa } = useStatusUpdates();
  const link = useLinkBuilder(`status/${STATUS_PATH.INTERNATIONAL_TRANSPORT}/${feedId}`);
  const navigate = useNavigate();
  const vehicleIsAlmostAtDestination = status === "ALMOST_AT_DESTINATION";
  const statusText = status === "PLANNED" ? "ingepland" : "onderweg";
  const preposition = status === "PLANNED" ? "voor" : "op";
  const days = vehicleIsAlmostAtDestination
    ? calculateWorkDaysDifference(eta)
    : calculateDaysDifference(eta);

  return (
    <Block
      labelText={`Volg het transport van ${isComa ? "jouw" : "uw"} ${vehicleType}`}
      onClick={(e) => {
        e.preventDefault();
        sendGAEvent("status transport");
        navigate(link);
      }}
      href=""
      iconVariant="ask-question"
      hasArrow
      data-e2e-test-tile="tile-international-transport"
    >
      {vehicleIsAlmostAtDestination ? (
        <>
          {isComa ? "Jouw" : "Uw"} {vehicleType} <HighlightedText>is er bijna</HighlightedText> en
          wordt binnen{" "}
          <HighlightedText>
            {days} {days === 1 ? "werkdag" : "werkdagen"}
          </HighlightedText>{" "}
          afgeleverd.
        </>
      ) : (
        <>
          {isComa ? "Jouw" : "Uw"} {vehicleType} <HighlightedText>is {statusText}</HighlightedText>{" "}
          {preposition} transport en wordt over ongeveer{" "}
          <HighlightedText>
            {days} {days === 1 ? "dag" : "dagen"}
          </HighlightedText>{" "}
          afgeleverd.
        </>
      )}
    </Block>
  );
};
