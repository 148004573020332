import Typography from "@anwb/typography";
import { TaskPostponedMessageDetails, TypedStatusUpdateMessage } from "@status-updates/types";
import { DateTime } from "luxon";
import { FrontendState, StateMetadata } from "../frontend-state";

export class DiagnosisPostponedNoAnswerState extends FrontendState<TaskPostponedMessageDetails> {
  constructor(
    message: TypedStatusUpdateMessage<TaskPostponedMessageDetails>,
    metadata: StateMetadata
  ) {
    super(message, metadata, {
      illustration: "auto-op-brug",
      title: "We hebben nog geen contact met de garage kunnen krijgen",
    });
  }

  renderTimelineItems() {
    const { timeFragment } = this.message.messageDetails;
    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: this.options.title,
        content: (
          <Typography data-e2e-test-type="status-updates-diagnosis-no-answer" tagName="div">
            Hierdoor hebben we nog geen informatie. Wij gaan opnieuw contact opnemen met de garage.{" "}
            {timeFragment.charAt(0).toUpperCase() + timeFragment.slice(1)} verwachten we{" "}
            {this.getInformalOrFormal("je", "u")} meer te kunnen vertellen.
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
