import { CaseReceivedMessageDetails, TypedStatusUpdateMessage } from "@status-updates/types";
import Typography from "@anwb/typography";
import { ButtonTertiary } from "@anwb/button";
import { DateTime } from "luxon";
import { determineInformationLinkText } from "../../utils/countrySpecificText";
import { FrontendState, StateMetadata } from "../frontend-state";
import { ButtonLink } from "../../components/link";

export class CaseReceivedThirdState extends FrontendState<CaseReceivedMessageDetails> {
  constructor(
    message: TypedStatusUpdateMessage<CaseReceivedMessageDetails>,
    metadata: StateMetadata
  ) {
    super(message, metadata, {
      illustration: "alarmcentrale",
      title: `Wij gaan voor ${FrontendState.getInformalOrFormal(
        "jou",
        "u",
        metadata.contractMarket
      )} aan de slag`,
    });
  }

  renderTimelineItems() {
    const assistanceAbroad =
      this.message.messageDetails.contractMarket === "coma"
        ? `Let op dat de hulpverlening misschien anders gaat dan ${this.getInformalOrFormal(
            "je",
            "u"
          )} van ons verwacht. Dit komt doordat de partners van de ANWB de hulpverlening uitvoeren.`
        : "Let op dat de hulpverlening misschien anders gaat dan in Nederland.";
    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: this.options.title,
        content: (
          <Typography data-e2e-test-type="status-updates-action-created" tagName="div">
            <p>
              In dit scherm word {this.getInformalOrFormal("je", "u")} op de hoogte gehouden van de
              status van {this.getInformalOrFormal("jouw", "uw")} melding. Wij gaan{" "}
              {this.getInformalOrFormal("je", "u")} helpen. Het kan even duren voordat{" "}
              {this.getInformalOrFormal("je", "u")} een nieuw bericht krijgt.
              <br />
              <br />
              {assistanceAbroad}
            </p>
            <ButtonLink container={ButtonTertiary} href="informatie">
              {determineInformationLinkText("hulpverlening")}
            </ButtonLink>
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
