import { Timeline, TimelineContainer } from "@anwb/timeline";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { StatusUpdateMessage } from "@status-updates/types";
import { FrontendState } from "../../../states/frontend-state";
import { renderTimelineItem } from "./item";
import { PushNotification } from "../../push-notification/pushNotification";

const Section = styled.section`
  flex: 1 1 auto;
  overflow-y: visible;
  padding: 0 14px;
  margin-top: 16px;
  margin-bottom: 36px;

  ${TimelineContainer} {
    > li:last-child {
      margin-bottom: 2.5rem;
    }
  }

  .PONCHO-timeline__title {
    font-size: 22px;
  }
`;

type StatusUpdatesTimelineProps = {
  states: FrontendState<StatusUpdateMessage["messageDetails"]>[];
};

function toTimelineItems(
  states: FrontendState<StatusUpdateMessage["messageDetails"]>[]
): JSX.Element[] {
  return states
    .flatMap((state) => state.renderTimelineItems())
    .map((timelineItem, idx) => renderTimelineItem(timelineItem, idx));
}

export const StatusUpdatesTimeline: FunctionComponent<StatusUpdatesTimelineProps> = ({
  states,
}: StatusUpdatesTimelineProps) => {
  return (
    <Section>
      {window.statusUpdatesSettings.enablePushNotification && <PushNotification />}
      <Timeline>{toTimelineItems(states)}</Timeline>
    </Section>
  );
};
