import Typography from "@anwb/typography";
import { DateTime } from "luxon";
import { EmptyMessageDetails, TypedStatusUpdateMessage } from "@status-updates/types";
import { FrontendState, StateMetadata } from "../frontend-state";

export class LocalizeProviderStartedState extends FrontendState<EmptyMessageDetails> {
  constructor(message: TypedStatusUpdateMessage<EmptyMessageDetails>, metadata: StateMetadata) {
    super(message, metadata, {
      illustration: "alarmcentrale",
      title: "Wij zijn op zoek naar een garage",
    });
  }

  renderTimelineItems() {
    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: this.options.title,
        content: (
          <Typography data-e2e-test-type="status-updates-localize-provider-started" tagName="div">
            We laten het {this.getInformalOrFormal("je", "u")} weten als we een garage in{" "}
            {this.getInformalOrFormal("jouw", "uw")} buurt hebben gevonden die kan helpen.
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
