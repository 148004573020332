import Typography from "@anwb/typography";
import { FunctionComponent } from "react";
import { StyledCookieBanner } from "./styled";

const CookieBanner: FunctionComponent = () => {
  return (
    <StyledCookieBanner>
      <section>
        <Typography
          tagName="a"
          variant="usp-text-sm"
          href="https://www.anwb.nl/juridisch-advies/aanrijding-en-dan/aansprakelijkheid"
          className="footer-link"
        >
          Aansprakelijkheid
        </Typography>
        <Typography
          tagName="a"
          variant="usp-text-sm"
          href="https://www.anwb.nl"
          className="footer-link"
        >
          © ANWB
        </Typography>
        <Typography
          tagName="a"
          variant="usp-text-sm"
          href="https://www.anwb.nl/jouw-privacy/anwb-hulpverlening#pechhulp"
          className="footer-link"
        >
          Privacy en cookies
        </Typography>
      </section>
    </StyledCookieBanner>
  );
};

export default CookieBanner;
