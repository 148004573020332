import { DateTime } from "luxon";

/**
 * Returns a description of the time period between the two given dates
 *
 * Please note that the timezone of both times is ignored. The description will be buggy and unpredictable
 * if the timezones of the 3 given times are not the same!
 *
 * @param dateFrom
 * @param dateTo
 */
export function getAppointmentTimeDescription(now: DateTime, dateFrom: DateTime, dateTo: DateTime) {
  const DATE_TIME_FORMAT = "d MMMM HH:mm";
  const TIME_FORMAT = "HH:mm";

  // Ensure all date/times formatted according to Dutch locale
  const startDate = dateFrom.setLocale("nl");
  const endDate = dateTo.setLocale("nl");
  const tomorrow = now.plus({ day: 1 });

  if (startDate.hasSame(endDate, "day")) {
    if (startDate.hasSame(now, "day")) {
      return `vandaag tussen ${startDate.toFormat(TIME_FORMAT)} en ${endDate.toFormat(
        TIME_FORMAT
      )}`;
    }

    if (startDate.hasSame(tomorrow, "day")) {
      return `morgen tussen ${startDate.toFormat(TIME_FORMAT)} en ${endDate.toFormat(TIME_FORMAT)}`;
    }

    return `tussen ${startDate.toFormat(DATE_TIME_FORMAT)} en ${endDate.toFormat(TIME_FORMAT)}`;
  }

  return `tussen ${startDate.toFormat(DATE_TIME_FORMAT)} en ${endDate.toFormat(DATE_TIME_FORMAT)}`;
}
