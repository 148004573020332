import { ProviderAddress } from "@status-updates/types";
import { addressFormats } from "./addressFormats";

/**
 * This method formats a very basic address according to the rules of a specific country.
 *
 * Please note that this method only supports street, houseNumber, postalcode and city. This is intentional, as it fits
 * our needs. If more countries are to be supported or more detailed formatting is needed, consider using an existing library
 * such as @fragaria/address-formatter
 *
 * @param address
 */
export function localizeAddress(address: ProviderAddress): string[] {
  const addressWithComposedHouseNumber = {
    ...address,
    houseNumber:
      !!address.houseNumber || !!address.houseNumberExtension
        ? `${address.houseNumber || ""}${address.houseNumberExtension || ""}`
        : undefined,
    houseNumberExtension: undefined,
  };

  let addressLines;

  if (addressFormats.hasOwnProperty(address.countryCode.toUpperCase())) {
    addressLines = addressFormats[address.countryCode.toUpperCase()](
      addressWithComposedHouseNumber
    );
  } else {
    addressLines = addressFormats.NL(addressWithComposedHouseNumber);
  }

  // Remove leading and trailing spaces
  return addressLines.map((line) => line.trim());
}
