import { type FunctionComponent } from "react";
import { TravelInformationForm } from "./forms/travel-information/TravelInformationForm";

type ScenarioFormType = {
  type: "FORM";
  name: string;
};

export const ScenarioForm: FunctionComponent<ScenarioFormType> = ({ name }) => {
  switch (name) {
    case "TRAVEL_INFORMATION":
      return <TravelInformationForm />;
    default:
      return null;
  }
};
