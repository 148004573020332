import { FunctionComponent } from "react";
import Typography from "@anwb/typography";
import { Navigation } from "../navigation";
import { InformationPageTitle } from "../information/informationPageTitle";
import { GtmAccordion } from "./GtmAccordion";

export const InformationFrZama: FunctionComponent = () => {
  return (
    <>
      <Navigation
        links={[
          { title: "Hulp ter plekke", link: "#hulp-ter-plekke" },
          { title: "Diagnose & Reparatie", link: "#diagnose-reparatie" },
          { title: "Vervangend Vervoer", link: "#vervangend-vervoer" },
          { title: "Transport voertuig naar Nederland", link: "#voertuig-nederland" },
          { title: "Invoeren en Slopen", link: "#invoeren-en-slopen" },
        ]}
      />

      <section className="country-information">
        <InformationPageTitle />

        <Typography variant="intro-text" tagName="div" data-testid="fr-zama">
          Blijf kalm en zorg voor veiligheid voor uzelf en uw reisgenoten. De Alarmcentrale doet er
          intussen alles aan om u zo snel mogelijk te helpen. Die hulp is net even anders dan in
          Nederland. Uiteraard houden we u op de hoogte via de overzichtspagina, sms en telefonisch
          contact. De status van uw pechgeval wordt bij nieuwe informatie automatisch bijgewerkt op
          de overzichtspagina. Hieronder leest u antwoorden op veel gestelde vragen over onze
          hulpverlening.
        </Typography>

        <Typography variant="content-title" id="hulp-ter-plekke">
          Hulp ter plekke
        </Typography>

        <GtmAccordion
          content={[
            {
              title: "Wat gaat er gebeuren met mijn voertuig?",
              content: (
                <Typography variant="body-text" tagName="p">
                  In Frankrijk sturen we een hulpdienst (dépanneur) naar u toe. Deze probeert uw voertuig ter plaatse te repareren. Lukt dat niet, dan brengt die uw voertuig met een sleepauto naar een geschikte garage in de buurt.
                </Typography>
              ),
            },
            {
              title: "Mijn voertuig kunnen ze niet ter plekke repareren, wat nu?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Kan uw voertuig ter plekke niet gerepareerd worden? Wij brengen uw voertuig en de inzittenden naar een geschikte garage in de buurt. Deze garage kan verder weg zijn dan u in Nederland gewend bent. De garage stelt een diagnose. Hiermee kan de Alarmcentrale de verdere hulp bepalen.
                  <br />
                  <br />
                  De garage stelt een diagnose. Hiermee kan de Alarmcentrale de verdere hulp
                  bepalen.
                  <ul>
                    <li>
                      Staat u langs de weg, dan zal de pechhulp (dépanneur) u ophalen. Als niet iedereen mee kan, zorgen wij voor extra vervoer.
                    </li>
                    <li>
                      Bent u bijvoorbeeld op de camping en hoeft u niet mee te rijden? Dan houdt de ANWB Alarmcentrale contact met de garage.
                    </li>
                    <li>
                      Geef voertuigsleutels, voertuigpapieren en uw telefoonnummer aan de garage. Ze kunnen dan snel met uw voertuig beginnen!
                    </li>
                  </ul>
                </Typography>
              ),
            },
            {
              title: "De garage is al dicht, en nu?",
              content: (
                <Typography variant="body-text" tagName="p">
                  <ul>
                    <li>
                      Het is belangrijk dat u in dat geval de voertuigsleutels, de voertuigpapieren
                      en uw telefoonnummer bij de hulpdienst afgeeft.
                    </li>
                    <li>
                      Vraag de sleepdienst advies over een overnachtingsplek en laat hen een taxi voor u bellen. Of zoek zelf via internet naar een beschikbare overnachtingsplek in de omgeving. Bewaar hiervan de betaalbewijzen goed.
                    </li>
                    <li>
                      Onverwachte uitgaven, zoals een overnachting, kunt u vaak ter beoordeling bij
                      de reisverzekering indienen.
                    </li>
                  </ul>
                </Typography>
              ),
            },
            {
              title: "Hoe verloopt de hulp ’s nachts? ",
              content: (
                <Typography variant="body-text" tagName="p">
                  's Nachts repareren garages geen voertuigen. Uw voertuig wordt door de hulpdienst (dépanneur) veiliggesteld bij een depot. Als u daarna nog hulp nodig hebt, neem dan de volgende dag contact op met de ANWB. We kunnen dan de vervolghulp bespreken.
                  <ul>
                    <li>
                      U kunt de sleepdienst advies vragen over een overnachtingsplek en vragen een
                      taxi voor u te bellen. Bewaar hiervan de betaalbewijzen goed.
                    </li>
                    <li>
                      Onverwachte uitgaven, zoals een overnachting, kunt u vaak ter beoordeling bij de reisverzekering indienen.
                    </li>
                  </ul>
                </Typography>
              ),
            },
            {
              title: "Wat moet ik doen als mijn voertuig naar een depot is gebracht? ",
              content: (
                <Typography variant="body-text" tagName="p">
                  <p>
                    Neem in dit geval contact op met de Alarmcentrale om de eventuele kosten van het
                    afslepen te regelen. Klik op de belknop rechtsboven.
                  </p>
                  <p>
                    We gaan kijken of deze sleepdienst u kan helpen. Als dit niet het geval is, zoeken we een garage die met het voertuig kan werken.
                  </p>
                </Typography>
              ),
            },
          ]}
        />

        <Typography variant="content-title" id="diagnose-reparatie">
          Diagnose & Reparatie
        </Typography>

        <GtmAccordion
          content={[
            {
              title: "Waarom is het stellen van een diagnose belangrijk?",
              content: (
                <Typography variant="body-text" tagName="p">
                  We willen graag weten wat er aan de hand is met het voertuig en hoelang het gaat
                  duren. Aan de hand hiervan kunnen we bekijken welke hulp u nodig hebt om de reis
                  voort te kunnen zetten.
                </Typography>
              ),
            },
            {
              title: "Wat kan ik doen tijdens het stellen van de diagnose?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Dit kan soms even duren. Daarom kan de garage aan u vragen of u ergens anders wilt wachten. Misschien kunt u in de buurt iets eten of drinken, een bezienswaardigheid bekijken of een wandeling maken. Bereid u voor om eventueel anders terug te keren naar uw verblijf of een hotel in de buurt van de garage te boeken. Dit kunt u het beste zelf regelen. Mocht de garage nieuws hebben, dan nemen zij contact op met de Alarmcentrale. Wij houden u op de hoogte.
                  <ul>
                    <li>
                      Tussen 12:00 en 14:00 uur zijn garages in Frankrijk gesloten voor lunch.
                    </li>
                  </ul>
                </Typography>
              ),
            },
            {
              title:
                "Kan de garage in Frankrijk zomaar starten met de reparatie van mijn voertuig?",
              content: (
                <Typography variant="body-text" tagName="p">
                  De garage in Frankrijk heeft altijd uw toestemming nodig om aan een reparatie te
                  kunnen beginnen. Soms is het probleem tijdens het stellen van de diagnose al
                  opgelost, dan betaalt u alleen de diagnosekosten.
                </Typography>
              ),
            },
            {
              title: "Wat moet ik doen als ik de garage niet begrijp?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Bel de Alarmcentrale via de belknop rechtsboven voor ondersteuning, vertaalhulp of
                  andere vragen.
                </Typography>
              ),
            },
            {
              title: "Ik wil mijn voertuig liever in Nederland laten repareren, kan dat?",
              content: (
                <Typography variant="body-text" tagName="p">
                  De Alarmcentrale probeert u zo snel mogelijk met uw eigen voertuig weer op weg te
                  helpen. Een snelle reparatie in het buitenland helpt daarbij.
                  <ul>
                    <li>
                      U bent niet verplicht uw voertuig in het buitenland te laten repareren. Als uw
                      situatie voldoet aan de voorwaarden van de mobiliteitsservice, kunnen we uw
                      voertuig naar Nederland transporteren. Dit gebeurt altijd in overleg met de
                      Alarmcentrale.
                    </li>
                  </ul>
                </Typography>
              ),
            },
            {
              title: "Hoe betaal ik de reparatiekosten?",
              content: (
                <Typography variant="body-text" tagName="p">
                  U betaalt de reparatiekosten met een creditcard of Nederlandse bankpas.
                </Typography>
              ),
            },
          ]}
        />

        <Typography variant="content-title" id="vervangend-vervoer">
          Vervangend vervoer
        </Typography>

        <GtmAccordion
          content={[
            {
              title: "Heb ik recht op vervangend vervoer?",
              content: (
                <Typography variant="body-text" tagName="p">
                  De Alarmcentrale regelt vervangend vervoer als uw situatie voldoet aan de voorwaarden van de mobiliteitsservice. In geval van een huurauto sluit u een contract af bij een verhuurbedrijf. De voorwaarden die het verhuurbedrijf stelt zijn voor u van toepassing (bijvoorbeeld het hebben van een creditcard). Mocht u geen creditcard hebben, lees dan verderop wat wij voor u kunnen regelen.
                </Typography>
              ),
            },
            {
              title: "Hoelang duurt het regelen van vervangend vervoer?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Wij doen ons best om de meest geschikte optie voor u te organiseren binnen de voorwaarden van de mobiliteitsservice. Dit kan soms wat tijd in beslag nemen. We overleggen met u wat de beste vorm van vervangend vervoer is. Dit hoeft niet altijd een huurauto te zijn, maar kan ook een treinreis, busreis of vlucht zijn.
                  <ul>
                    <li>
                      Tijdens de schoolvakanties kost het meer tijd om passend vervangend vervoer te
                      vinden. Bij alle Europese verhuurbedrijven is er dan veel vraag naar
                      vervangend vervoer.
                    </li>
                    <li>Niet alle huurauto's mag u inleveren in Nederland.</li>
                  </ul>
                </Typography>
              ),
            },
            {
              title: "Heb ik een creditcard nodig?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Ja. De Europese huurauto maatschappijen vragen altijd een creditcard als borg voor de huurauto. Dit is een garantie voor de verhuurder bij eventuele schade, diefstal, fraude of nalatigheid. De borg is verplicht, maar elke aanbieder bepaalt zelf de hoogte van het bedrag. Het kan bijvoorbeeld ook de hoogte van het eigen risico zijn. Uw creditcard reserveert deze borg. Uw creditcard moet voldoende bestedingslimiet hebben, want u kunt het ingehouden bedrag niet meer uitgeven. De borg wordt namelijk op u creditcard gereserveerd; contant- of pinbetaling is meestal niet mogelijk. Het kan daarom handig zijn om eventueel uw bestedingslimiet te verhogen. De huurkosten worden niet van uw creditcard afgeschreven maar worden betaald door uw mobiliteitsservice.
                  <ul>
                    <li>
                      Lever het vervangend vervoer netjes, volgetankt, zonder schade en op tijd in. Dan schrijven we niets van uw creditcard af en geven we het gereserveerde bedrag weer vrij. Dit kan een paar werkdagen duren.
                    </li>
                  </ul>
                </Typography>
              ),
            },
            {
              title: "Ik heb geen creditcard, wat nu?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Het inzetten van een buitenlands vervangend voertuig wordt dan moeilijk. Dit
                  betekent dat we moeten gaan kijken naar andere vormen van vervangend vervoer. Denk
                  hierbij aan een treinreis, busreis of vlucht. Ook kan u uzelf laten ophalen door
                  familie/vrienden uit Nederland. De Alarmcentrale kan hiervoor een vergoeding met u
                  bespreken.
                </Typography>
              ),
            },
            {
              title: "Moet ik een extra verzekeringen afsluiten bij de verhuurder?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Nee, u hoeft geen extra verzekeringen af te sluiten zoals een bandenverzekering, het afkopen van eigen risico of een onduidelijke upgrade. De Alarmcentrale heeft hierover afspraken gemaakt met de verhuurmaatschappijen. Hierdoor kunt u met een gerust hart ‘nee’ zeggen tegen al die extra opties bij het ophalen van uw huurauto.
                </Typography>
              ),
            },
            {
              title: "Huurauto ophalen? Hier moet u op letten!",
              content: (
                <Typography variant="body-text" tagName="p">
                  <strong>Houd alles bij de hand.</strong>
                  <br />
                  Goede voorbereiding is het halve werk en voorkomt kopzorgen aan de balie. Iets waar u niet op zit te wachten als u net een reis achter de rug hebt. Zorg daarom dat u de volgende documenten paraat hebt als u uw huurauto ophaalt:
                  <ul>
                    <li>Rijbewijs</li>
                    <li>Creditcard op naam van de hoofdbestuurder</li>
                    <li>ID of paspoort</li>
                  </ul>
                  <strong>Maak wanneer mogelijk altijd foto’s van bestaande schades.</strong>
                  <br />
                  Teken de formulieren pas nádat u de auto hebt gezien en u eventuele schades op de papieren hebt aangegeven. Zorg dat er iemand van het verhuurbedrijf bij de controle aanwezig is, zodat u de schades ook kunt aantonen. Maak wanneer mogelijk altijd foto’s van al bestaande schades!
                </Typography>
              ),
            },
            {
              title: "Huurauto inleveren? Hier moet u op letten!",
              content: (
                <Typography variant="body-text" tagName="p">
                  <strong>Zorg dat er voldoende brandstof in de auto zit</strong>
                  <br />
                  Dus: zoals u heeft afgesproken. Levert u de auto in zonder volle tank
                  terwijl dit wel de bedoeling was? Dan rekent de verhuurder de brandstofkosten aan
                  u door en die vallen meestal hoger uit.
                  <strong>Zorg voor bewijs dat er geen nieuwe schade is</strong>
                  <br />
                  Let er bij het inleveren van de auto op dat een medewerker van het verhuurbedrijf na controle een bewijs tekent. Dan weet u zeker dat er geen nieuwe beschadigingen zijn. Het is daarom niet verstandig om de auto buiten kantooruren terug te brengen. Dan is er vaak niemand van het verhuurbedrijf aanwezig.
                </Typography>
              ),
            },
          ]}
        />

        <Typography variant="content-title" id="voertuig-nederland">
          Transport voertuig naar Nederland
        </Typography>

        <GtmAccordion
          content={[
            {
              title: "Mag mijn voertuig naar Nederland?",
              content: (
                <Typography variant="body-text" tagName="p">
                  We transporteren uw voertuig naar Nederland wanneer uw situatie aan de voorwaarden van de mobiliteitsservice voldoet. Dit gaat altijd in overleg met de Alarmcentrale.
                  <span>
                    {" "}
                    Als uw situatie niet aan de voorwaarden voldoet, is het mogelijk een aanvraag te
                    doen voor{" "}
                    <Typography variant="link-text" href="https://www.logicx.nl/transport/">
                      transport op eigen kosten via de firma Logicx
                    </Typography>
                    .{" "}
                  </span>
                </Typography>
              ),
            },
            {
              title: "Waar moet ik op letten bij transport naar Nederland?",
              content: (
                <Typography variant="body-text" tagName="p">
                  <ul>
                    <li>Laat geen voedsel en kostbaarheden in uw voertuig achter.</li>
                    <li>Laat een kopie van het kentekenbewijs achter.</li>
                    <li>
                      Laat de sleutels van uw voertuig en die van de eventuele dakkoffer of
                      fietsendrager achter.
                    </li>
                    <li>
                      Als er een dakkoffer op uw voertuig zit, plaats deze in het voertuig, wanneer
                      mogelijk.
                    </li>
                    <li>
                      Maak foto's van uw voertuig zodat duidelijk is hoe u deze ter plekke hebt
                      achtergelaten.
                    </li>
                    <li>
                      Informeer uw garage in Nederland over de pech en geef aan dat uw voertuig bij hen aankomt.
                    </li>
                    <li>
                      Informeer of uw garage in Nederland openblijft of juist sluit tijdens de
                      vakantie.
                    </li>
                    <li>
                      Spreek met uw garage af dat zij u bellen zodra het voertuig bij hen is
                      aangekomen.
                    </li>
                  </ul>
                </Typography>
              ),
            },
            {
              title: "Wanneer is mijn voertuig in Nederland?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Uw voertuig wordt met andere gestrande voertuigen verzameld en naar Nederland vervoerd. De transporttijd hangt af van:
                  <ul>
                    <li>
                      De plek waar uw voertuig staat, drukte, afstand, weekenden, rijtijdenwet en
                      wetgeving voor internationaal transport.
                    </li>
                    <li>
                      Wij rekenen de transporttijd vanaf het moment dat wij besluiten uw voertuig naar Nederland te brengen. Dit gebeurt niet vanaf het moment dat u met pech stond. U ontvangt informatie over het transport via SMS en/of deze web app.
                    </li>
                  </ul>
                </Typography>
              ),
            },
          ]}
        />
      </section>
    </>
  );
};
