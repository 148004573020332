import Icon from "@anwb/icon";
import { FunctionComponent } from "react";
import Typography from "@anwb/typography";
import type { StatusUpdateMessage } from "@status-updates/types";

import { StyledHeader } from "./styled";
import { useGoogleTagManager } from "../../hooks/useGoogleTagManager";
import { getCurrentStatus } from "../../routes/home/utils";
import { Logo } from "../logo/Logo";

type Props = {
  assistancePhoneNumber?: string;
  contractMarket?: string;
  contractLabelId?: string;
  lastMessage?: StatusUpdateMessage;
};

const Header: FunctionComponent<Props> = ({
  assistancePhoneNumber = "+31703141414",
  contractMarket,
  contractLabelId,
  lastMessage,
}) => {
  const sendEventToGtm = useGoogleTagManager();
  const gtmPhoneClick: () => void = () => {
    sendEventToGtm({
      type: "page",
      component: "telefoon",
      field: assistancePhoneNumber,
      action: "click",
      actionValue: lastMessage ? getCurrentStatus(lastMessage.messageType) : undefined,
    });
  };
  const needsAnwbStyling =
    contractMarket === "coma" || !contractLabelId || contractLabelId === "anwbvoordezaak";

  return (
    <StyledHeader $needsAnwbStyling={needsAnwbStyling}>
      <section>
        <div className="logo">
          <Logo contractMarket={contractMarket} contractLabelId={contractLabelId} />
        </div>
        <nav>
          <a onClick={gtmPhoneClick} href={`tel: ${assistancePhoneNumber}`}>
            <span className="label">
              <Typography variant={"button-link"}>24/7 bereikbaar</Typography>
            </span>
            <Icon variant="phone" type="ui" size="lg" />
          </a>
        </nav>
      </section>
    </StyledHeader>
  );
};

export default Header;
