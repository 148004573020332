import { useEffect, useRef, useState } from "react";
import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";

interface Props {
  origin: google.maps.LatLngLiteral;
  destination: google.maps.LatLngLiteral;
}

export function showDirections(
  { origin, destination }: Props,
  setGoogleDistance: React.Dispatch<React.SetStateAction<google.maps.DistanceMatrixResponse | null>>
) {
  const map = useMap();
  const routesLibrary = useMapsLibrary("routes");
  const [directionsService, setDirectionsService] = useState<google.maps.DirectionsService>();
  const [directionsRenderer, setDirectionsRenderer] = useState<google.maps.DirectionsRenderer>();
  const [distanceMatrixService, setDistanceMatrixService] =
    useState<google.maps.DistanceMatrixService>();
  const rendered = useRef(false);

  useEffect(() => {
    if (!routesLibrary || !map) {
      return;
    }
    setDirectionsService(new routesLibrary.DirectionsService());
    setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map, suppressMarkers: true }));
    setDistanceMatrixService(new routesLibrary.DistanceMatrixService());
  }, [routesLibrary, map]);

  useEffect(() => {
    if (!directionsService || !directionsRenderer || !distanceMatrixService || rendered.current) {
      return;
    }
    directionsService
      .route({ origin, destination, travelMode: google.maps.TravelMode.DRIVING })
      .then(
        (result) => {
          directionsRenderer.setDirections(result);
          rendered.current = true;
        },
        (reason) => console.error(reason)
      );
    distanceMatrixService
      .getDistanceMatrix({
        origins: [origin],
        destinations: [destination],
        travelMode: google.maps.TravelMode.DRIVING,
      })
      .then(
        (result) => {
          setGoogleDistance(result);
        },
        (reason) => console.error(reason)
      );
  }, [directionsService, directionsRenderer, distanceMatrixService, rendered]);

  // Only recalculate if we get a new destination or origin
  useEffect(() => {
    if (rendered.current) {
      rendered.current = false;
    }
  }, [destination.lat, destination.lng, origin.lat, origin.lng]);
}
