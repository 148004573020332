import { ButtonTertiary } from "@anwb/button";
import Typography from "@anwb/typography";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router";
import { useLinkBuilder } from "../../../../../../components/link";
import { useStatusUpdates } from "../../../../hooks/useStatusUpdates";
import { ButtonsContainer } from "./InternationalTransport/Styles/styled";

export const NotFound: FunctionComponent = () => {
  const navigate = useNavigate();
  const returnLink = useLinkBuilder("/");
  const { isComa } = useStatusUpdates();

  return (
    <>
      <Typography>
        Er is technisch iets misgegaan met het ophalen van de informatie over{" "}
        {isComa ? "jouw" : "uw"} hulpverlening.
      </Typography>
      <ButtonsContainer>
        <ButtonTertiary
          icon="arrow-left"
          iconPosition="before"
          onClick={() => navigate(returnLink)}
        >
          Terug
        </ButtonTertiary>
      </ButtonsContainer>
    </>
  );
};
