import { createGoogleMapsLink } from "../../utils/address-styler";
import { LogicxTransportStatusDetails } from "@status-updates/types";
import Typography from "@anwb/typography";

export function getEindbestemming(details: LogicxTransportStatusDetails) {
  const location = details.location;

  const locationName =
    location?.name === undefined || location?.name.toLowerCase() === "onbekend"
      ? undefined
      : location.name;

  if (!locationName) {
    return;
  }

  return (
    <>
      <br />
      <br />
      <b>Eindbestemming</b> <br />
      {formatLocationName(locationName)}
      {location?.latitude && location?.longitude && (
        <>
          <Typography
            href={createGoogleMapsLink(location.latitude, location.longitude)}
            variant="link-text"
          >
            Bekijk adres
          </Typography>
          <br />
        </>
      )}
    </>
  );
}

function formatLocationName(locationName: string) {
  return (
    <>
      {locationName} <br />
    </>
  );
}
