import { StatusUpdateMessage, TypedStatusUpdateMessage } from "@status-updates/types";
import { DateTime } from "luxon";
import { FeedbackButton } from "../components/feedback-button";

import { Illustration, Variants } from "../components/illustration";

export interface FrontendStateOptions {
  illustration: Variants;
  title: string;
}

export type StateMetadata = {
  token: string;
  assistancePhonenumber?: string;
  countryName?: string;
  countryCode?: string;
  contractMarket?: string;
  label?: string;
  isReadOnly?: boolean;
  showFeedback: boolean;
  showDigitalCancelButton?: boolean;
};

export type TimelineItemData = {
  timestamp: DateTime;
  title: string;
  content: JSX.Element | (() => JSX.Element);
  feedback: JSX.Element | null;
};

export abstract class FrontendState<T extends StatusUpdateMessage["messageDetails"]> {
  protected readonly isComa: boolean;
  constructor(
    protected readonly message: TypedStatusUpdateMessage<T>,
    protected readonly metadata: StateMetadata,
    protected readonly options: FrontendStateOptions
  ) {
    /**
     * Probably because of a bug in the jest-preset-preact the message instance variable is undefined for subclasses.
     * This hack is not needed for runtime, only for unit testing, so we could check in the future if the hack can be removed.
     */
    this.message = message;
    this.options = options;
    this.metadata = metadata;
    this.isComa = metadata.contractMarket === "coma";
  }

  renderHeader(): JSX.Element {
    return <Illustration variant={this.options.illustration} />;
  }

  abstract renderTimelineItems(): TimelineItemData[];

  getStatusForGoogleTagManager(): string {
    return this.options.title;
  }

  /* 
    returns either the first argument (in case of coma marketType)
    or the second argument if not coma marketType
  */
  static getInformalOrFormal(
    informal: string,
    formal: string,
    contractMarket: string | undefined
  ): string {
    return this.getFormalOrInformalForMarketType(informal, formal, contractMarket === "coma");
  }

  getInformalOrFormal(informal: string, formal: string): string {
    return FrontendState.getFormalOrInformalForMarketType(informal, formal, this.isComa);
  }

  private static getFormalOrInformalForMarketType(
    informal: string,
    formal: string,
    isComa: boolean
  ) {
    return isComa ? informal : formal;
  }

  renderFeedback(): JSX.Element | null {
    if (this.metadata.showFeedback && this.message.metadata?.caseNumber !== undefined) {
      return (
        <FeedbackButton
          timestamp={this.message.created}
          messageType={this.message.messageType}
          caseNumber={this.message.metadata.caseNumber}
          marketType={this.metadata.contractMarket}
          country={this.metadata.countryCode}
          label={this.metadata.label}
        />
      );
    }
    return null;
  }
}
