import { UseFormRegister } from "react-hook-form";
import { Radio, RadioProps } from "@anwb/forms";
import Typography from "@anwb/typography";
import styled from "styled-components";

const Style = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
`;

const RadioContainer = styled.div`
  display: flex;
  gap: 24px;
`;

type RestrictiveRadioProps = RadioProps & {
  title: string;
  valid: boolean;
  register: UseFormRegister<Record<string, unknown>>;
};

export const RestrictiveRadio: React.FC<RestrictiveRadioProps> = ({ register, ...props }) => {
  return (
    <Style>
      <Typography variant="body-text" style={{ margin: 0 }}>
        {props.title}
      </Typography>
      <RadioContainer>
        <Radio
          required={true}
          valid={props.valid}
          value={"true"}
          data-e2e-test-type="radio-true"
          reference={`${props.reference}-true`}
          {...register(props.reference, {
            required: "error",
          })}
        ></Radio>

        <Radio
          required={true}
          valid={props.valid}
          value={"false"}
          reference={`${props.reference}-false`}
          {...register(props.reference, {
            required: "error",
          })}
        ></Radio>
      </RadioContainer>
    </Style>
  );
};
