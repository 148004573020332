import { ButtonSecondary, ButtonTertiary } from "@anwb/button";
import { Checkbox, Form, FormField, RadioCheckboxGroup } from "@anwb/forms";
import Panel from "@anwb/panel";
import Typography from "@anwb/typography";
import { useStateMachine } from "little-state-machine";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { FormBreadcrumb } from "../../../../../../../../components/bread-crumb/ReturnToStatusUpdatesBreadCrumb";
import { StyledFooter } from "../styles/Footer";
import { StyledForm } from "../styles/Form";
import { StyledHeader } from "../styles/Header";
import { updateDocumentsAndEquipment } from "../form-store";
import { TravelFormProps, TravelInfoForm } from "../TravelInformationForm";
import { HeaderContent } from "../components/HeaderContent";
import { RestrictiveRadio } from "../components/RestrictiveRadio";
import { allPropertiesUndefined, createGATravelInformationEvent } from "../util";
import { useGoogleTagManager } from "../../../../../../../../hooks/useGoogleTagManager";

export const TravelInformationFormSecond = (props: TravelFormProps) => {
  const {
    actions,
    state: { travelInformationForm: formData },
  } = useStateMachine({ updateDocumentsAndEquipment });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Partial<TravelInfoForm>>({
    defaultValues: {
      travelDocuments: {
        ...formData?.travelDocuments,
      },
      travelEquipment: {
        ...formData?.travelEquipment,
      },
    },
  });

  const navigate = useNavigate();
  const sendEventToGtm = useGoogleTagManager();

  const onSubmit: SubmitHandler<Partial<TravelInfoForm>> = (data) => {
    actions.updateDocumentsAndEquipment({
      travelDocuments: {
        ...data.travelDocuments,
      },
      travelEquipment: {
        ...data.travelEquipment,
      },
    });

    const valid =
      // @ts-expect-error not writing out all properties names for this function, so types dont match
      !allPropertiesUndefined(formData.travelDocuments) && Object.keys(errors).length === 0;
    sendEventToGtm(
      createGATravelInformationEvent("verder", "Heb je dit bij je?", valid ? "valid" : "invalid")
    );
    navigate("../samenvatting");
  };

  return (
    <>
      <FormBreadcrumb />
      <StyledHeader>
        <HeaderContent isComa={props.isComa} showBodyText percentage={70} />
      </StyledHeader>
      <StyledForm>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Panel style={{ paddingTop: 0 }}>
            <FormField>
              <Typography variant="content-title" style={{ marginTop: "0px" }}>
                {props.isComa ? "Heb je dit bij je" : "Heeft u dit bij zich"}?
              </Typography>

              <div
                style={{
                  display: "flex",
                  gap: "24px",
                  justifyContent: "end",
                }}
              >
                <Typography variant="label-title" style={{ margin: 0, marginRight: "auto" }}>
                  Documenten
                </Typography>
                <Typography variant="label-title" style={{ margin: 0 }}>
                  Ja
                </Typography>
                <Typography variant="label-title" style={{ margin: 0 }}>
                  Nee
                </Typography>
              </div>

              <RestrictiveRadio
                reference="travelDocuments.hasIdentificationCard"
                name="travelDocuments.hasIdentificationCard"
                title="Identiteitsbewijs"
                valid={!errors?.travelDocuments?.hasIdentificationCard}
                register={register}
              />
              <RestrictiveRadio
                reference="travelDocuments.hasDriversLicense"
                name="travelDocuments.hasDriversLicense"
                title="Rijbewijs"
                valid={!errors?.travelDocuments?.hasDriversLicense}
                register={register}
              />
              <RestrictiveRadio
                reference="travelDocuments.hasCreditCard"
                name="travelDocuments.hasCreditCard"
                title="Creditcard"
                valid={!errors?.travelDocuments?.hasCreditCard}
                register={register}
              />
              <RestrictiveRadio
                reference="travelDocuments.hasDebitCard"
                name="travelDocuments.hasDebitCard"
                title="Bankpas"
                valid={!errors?.travelDocuments?.hasDebitCard}
                register={register}
              />
              {errors?.travelDocuments && (
                <FormField.Error aria-live="assertive">
                  Maak bij alle opties een keuze.
                </FormField.Error>
              )}
            </FormField>
          </Panel>
          <Panel style={{ paddingTop: 0 }}>
            <FormField>
              <Typography variant="content-title" style={{ marginTop: "0px" }}>
                {props.isComa ? "Heb je" : "Heeft u"} nog meer mee?
              </Typography>

              <RadioCheckboxGroup>
                <Checkbox
                  reference="travelEquipment.hasTrailer"
                  {...register("travelEquipment.hasTrailer")}
                >
                  Aanhangwagen
                </Checkbox>
                <Checkbox
                  reference="travelEquipment.hasCaravan"
                  {...register("travelEquipment.hasCaravan")}
                >
                  Caravan
                </Checkbox>
                <Checkbox
                  reference="travelEquipment.hasFoldingCamper"
                  {...register("travelEquipment.hasFoldingCamper")}
                >
                  Vouwwagen
                </Checkbox>
                <Checkbox
                  reference="travelEquipment.hasBikeRack"
                  {...register("travelEquipment.hasBikeRack")}
                >
                  Fietsendrager
                </Checkbox>
                <Checkbox
                  reference="travelEquipment.hasTowbarLuggage"
                  {...register("travelEquipment.hasTowbarLuggage")}
                >
                  Trekhaak koffer/bagagebox
                </Checkbox>
                <Checkbox
                  reference="travelEquipment.hasRoofLuggage"
                  {...register("travelEquipment.hasRoofLuggage")}
                >
                  Dakkoffer/daktas
                </Checkbox>
                <Checkbox
                  reference="travelEquipment.hasBoatTrailer"
                  {...register("travelEquipment.hasBoatTrailer")}
                >
                  Boottrailer
                </Checkbox>
              </RadioCheckboxGroup>
            </FormField>
          </Panel>
          <Form.Footer>
            <StyledFooter>
              <ButtonSecondary type="submit" data-e2e-test-type="submit">
                Verder
              </ButtonSecondary>
              <ButtonTertiary
                type="button"
                icon="arrow-left"
                iconPosition="before"
                onClick={() => {
                  sendEventToGtm(createGATravelInformationEvent("verder", "Heb je dit bij je?"));
                  navigate("../reisplanning");
                }}
              >
                Terug
              </ButtonTertiary>
            </StyledFooter>
          </Form.Footer>
        </Form>
      </StyledForm>
    </>
  );
};
