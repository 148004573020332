import { TypedStatusUpdateMessage, WegenwachtInTransitMessageDetails } from "@status-updates/types";
import Typography from "@anwb/typography";
import { ButtonPrimary, ButtonTertiary } from "@anwb/button";
import { DateTime } from "luxon";
import { FrontendState, StateMetadata } from "../frontend-state";
import { ButtonLink } from "../../components/link";
import { ButtonContainer } from "./styled";
import { MeldingAnnuleren } from "./melding-annuleren";
import { useGoogleTagManager } from "../../hooks/useGoogleTagManager";

export class WegenwachtInTransitState extends FrontendState<WegenwachtInTransitMessageDetails> {
  constructor(
    message: TypedStatusUpdateMessage<WegenwachtInTransitMessageDetails>,
    metadata: StateMetadata
  ) {
    super(message, metadata, {
      illustration: "wegenwacht",
      title: "De wegenwacht is onderweg!",
    });
  }

  renderTimelineItems() {
    const sendEventToGtm = useGoogleTagManager();
    const gtmFollowClick: () => void = () => {
      sendEventToGtm({
        type: "page",
        component: "volg de wegenwacht",
        action: "click",
      });
    };

    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: this.options.title,
        content: (
          <Typography
            data-e2e-test-type="status-updates-wegenwacht-in-transit-message"
            key={this.message.metadata?.messageId}
            tagName="div"
          >
            <p>Klik op de knop hieronder om de wegenwacht te volgen via de live pagina.</p>
            <p>
              Zorg dat {this.getInformalOrFormal("je bij jouw", "u bij uw")} voertuig bent als de
              wegenwacht aankomt. Houd {this.getInformalOrFormal("je", "uw")} (digitale) ledenpas
              en/of legitimatie bij de hand.
            </p>
            <ButtonContainer>
              <ButtonLink
                container={this.metadata.showDigitalCancelButton ? ButtonPrimary : ButtonTertiary}
                href={`waar-is-de-wegenwacht/${this.message.metadata?.messageId}`}
                data-testid="follow-wegenwacht"
                onClick={gtmFollowClick}
              >
                Volg de wegenwacht
              </ButtonLink>
              {this.metadata.showDigitalCancelButton && (
                <MeldingAnnuleren
                  contractMarket={this.metadata.contractMarket}
                  assistancePhonenumber={this.metadata.assistancePhonenumber}
                />
              )}
            </ButtonContainer>
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
