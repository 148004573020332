import styled from "styled-components";

export const AddressWrapper = styled.div`
  p {
    margin: 0;
  }

  h3 {
    margin-bottom: 0;
  }
`;
