import { ButtonPrimary, ButtonTertiary } from "@anwb/button";
import Card from "@anwb/card";
import { Form } from "@anwb/forms";
import Typography from "@anwb/typography";
import { useStateMachine } from "little-state-machine";
import { useNavigate } from "react-router";
import { createClient } from "../../../../../../../../features/api/backendClient";
import { useStatusUpdates } from "../../../../../../hooks/useStatusUpdates";
import { TravelData } from "../components/summary/TravelData";
import { TravelItem } from "../components/summary/TravelItem";
import { StyledFooter } from "../styles/Footer";
import { StyledHeader } from "../styles/Header";
import { FormBreadcrumb } from "../../../../../../../../components/bread-crumb/ReturnToStatusUpdatesBreadCrumb";
import { TravelFormProps } from "../TravelInformationForm";
import { HeaderContent } from "../components/HeaderContent";
import { createGATravelInformationEvent, mapFromDataToRequest, mapTravelType } from "../util";
import { useGoogleTagManager } from "../../../../../../../../hooks/useGoogleTagManager";

export const TravelInformationFormSummary = (props: TravelFormProps) => {
  const {
    state: { travelInformationForm: formData },
  } = useStateMachine();
  const { token } = useStatusUpdates();

  const navigate = useNavigate();
  const sendEventToGtm = useGoogleTagManager();

  async function submit() {
    if (formData === undefined) {
      console.log("Form data not found");
      return;
    }

    const form = mapFromDataToRequest(formData);

    try {
      await createClient().default.postFormsTravelInformation(token, form);
    } catch (error) {
      console.error(error);
    }

    sendEventToGtm(createGATravelInformationEvent("verstuur", "overzicht"));

    navigate("../bedankt");
  }

  return (
    <>
      <FormBreadcrumb />
      <StyledHeader>
        <HeaderContent isComa={props.isComa} showBodyText={false} percentage={100} />
      </StyledHeader>
      <Card
        editable
        mode="read"
        onModeChange={(mode) => mode === "write" && navigate("../reisplanning")}
      >
        <Card.Header reference="reisplanning">
          {props.isComa ? "Kun je" : "Kunt u"} ons wat vertellen over {props.isComa ? "je" : "uw"}{" "}
          reisplanning?
        </Card.Header>
        <Card.Content reference="journeyStart">
          <TravelData title="Vertrek" value={formData?.travelInformation?.journeyStart ?? ""} />
        </Card.Content>

        <Card.Content reference="journeyEnd">
          <TravelData title="Thuiskomst" value={formData?.travelInformation?.journeyEnd ?? ""} />
        </Card.Content>

        <Card.Content reference="travelType">
          <TravelData
            title="Reistype"
            value={mapTravelType(formData?.travelInformation?.travelType) ?? ""}
          />
        </Card.Content>

        {formData?.travelInformation?.travelType === "RONDREIS" ? (
          <Card.Content reference="returnsAtBreakdownLocation">
            <TravelData
              title="Terugkerend langs pechlocatie"
              value={formData?.travelInformation?.returnsAtBreakdownLocation ? "Ja" : "Nee"}
            />
          </Card.Content>
        ) : undefined}

        {formData?.travelInformation?.travelType === "RONDREIS" &&
          formData?.travelInformation?.returnsAtBreakdownLocation === "true" && (
            <Card.Content reference="returnsAtBreakdownLocationDate">
              <TravelData
                title="Datum van terugkomst"
                value={formData?.travelInformation?.returnsAtBreakdownLocationDate ?? ""}
              />
            </Card.Content>
          )}

        {formData?.travelInformation?.comment && (
          <Card.Content reference="comment">
            <TravelData title="Toelichting" value={formData?.travelInformation?.comment} />
          </Card.Content>
        )}
      </Card>

      <Card
        editable
        mode="read"
        onModeChange={(mode) => mode === "write" && navigate("../wie-en-wat")}
      >
        <Card.Header reference="">Wat heb je bij je?</Card.Header>
        <Card.Content reference="travelDocuments">
          <Typography variant="component-subtitle" style={{ margin: "0" }}>
            (Reis)documenten
          </Typography>

          <div style={{ alignContent: "center" }}>
            <TravelItem
              title="Identiteitskaart"
              value={formData?.travelDocuments?.hasIdentificationCard}
            />
            <TravelItem title="Rijbewijs" value={formData?.travelDocuments?.hasDriversLicense} />
            <TravelItem title="Creditcard" value={formData?.travelDocuments?.hasCreditCard} />
            <TravelItem title="Bankpas" value={formData?.travelDocuments?.hasDebitCard} />
          </div>
        </Card.Content>

        <Card.Content reference="campingEquipment">
          <Typography variant="component-subtitle" style={{ margin: "0" }}>
            Aanwezig
          </Typography>

          <div style={{ alignContent: "center" }}>
            <TravelItem title="Aanhangwagen" value={formData?.travelEquipment?.hasTrailer} />
            <TravelItem title="Caravan" value={formData?.travelEquipment?.hasCaravan} />
            <TravelItem title="Vouwwagen" value={formData?.travelEquipment?.hasFoldingCamper} />
            <TravelItem title="Fietsendrager" value={formData?.travelEquipment?.hasBikeRack} />
            <TravelItem
              title="Trekhaak koffer/bagagebox"
              value={formData?.travelEquipment?.hasTowbarLuggage}
            />
            <TravelItem
              title="Dakkoffer/daktas"
              value={formData?.travelEquipment?.hasRoofLuggage}
            />
            <TravelItem title="Boottrailer" value={formData?.travelEquipment?.hasBoatTrailer} />
          </div>
        </Card.Content>
      </Card>

      <Form.Footer>
        <StyledFooter>
          <ButtonPrimary type="button" data-e2e-test-type="submit" onClick={submit}>
            Verstuur
          </ButtonPrimary>
          <ButtonTertiary
            type="button"
            icon="arrow-left"
            iconPosition="before"
            onClick={() => {
              sendEventToGtm(createGATravelInformationEvent("terug", "overzicht"));
              navigate("../wie-en-wat");
            }}
          >
            Terug
          </ButtonTertiary>
        </StyledFooter>
      </Form.Footer>
    </>
  );
};
