import { FunctionComponent, useState } from "react";
import { Navigation } from "../../../../components/navigation";
import { useNavigate } from "react-router";
import { useGoogleTagManager } from "../../../../hooks/useGoogleTagManager";
import { StyledInformation } from "./styled";
import { useStatusUpdates } from "../../hooks/useStatusUpdates";
import LicensePlate from "@anwb/form-field-license-plate";
import { ButtonPrimary, ButtonSecondary } from "@anwb/button";
import Footer from "../../../../components/footer";
import Form from "@anwb/form";
import Spinner from "@anwb/spinner";
import Notification from "@anwb/notification";
import SuccessfullCancel from "./components/successfull-cancel";
import { useLinkBuilder } from "../../../../components/link";
import { getInformalOrFormal } from "../../utils";

type LicensePlateFormValues = {
  licensePlate: string;
};

const Cancel: FunctionComponent = () => {
  const sendEventToGtm = useGoogleTagManager();
  const { caseReceivedMessage } = useStatusUpdates();
  const isComa = caseReceivedMessage?.contractMarket === "coma";
  const caseNumber = caseReceivedMessage?.caseNumber;
  const navigate = useNavigate();

  const [showSpinner, setShowSpinner] = useState(false);
  const [requestFailed, setRequestFailed] = useState(false);
  const [requestSuccessfull, setRequestSuccessfull] = useState(false);
  const [showCallButton, setShowCallButton] = useState(false);

  async function sendCancelRequest(
    caseNumber: string | undefined,
    licensePlate: string | undefined
  ) {
    setShowSpinner(true);
    let response;

    try {
      response = await fetch(`${window.statusUpdatesSettings.apiBaseUrl}/cancel`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-anwb-client-id": window.statusUpdatesSettings.apiKey,
        },
        body: JSON.stringify({
          caseNumber,
          licensePlate,
        }),
      });

      setShowSpinner(false);

      if (!response.ok) {
        setRequestFailed(true);
        setShowCallButton(true);
      } else {
        setRequestSuccessfull(true);
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
      setShowSpinner(false);
      setRequestFailed(true);
    }
  }

  function gtmPhoneClick(assistancePhoneNumber: string | undefined) {
    sendEventToGtm({
      type: "page",
      component: "telefoon",
      field: assistancePhoneNumber,
      action: "click",
      actionValue: "Melding annuleren pagina, blauwe belknop",
    });
    return true;
  }

  const gtmCancelClick: () => void = () => {
    sendEventToGtm({
      type: "page",
      component: "melding annuleren",
      action: "click",
    });
  };

  const informationLink = useLinkBuilder(`informatie`);
  const safetyLink = useLinkBuilder(`veiligheid`);
  const breakdownLocationLink = useLinkBuilder(`pechlocatie`);

  return (
    <StyledInformation>
      <Navigation />
      {!requestSuccessfull ? (
        <div className="cancel-page">
          <h1>Melding annuleren</h1>
          <p>
            Om {getInformalOrFormal("je", "uw", isComa)} melding te kunnen annuleren, hebben we het
            kenteken van {getInformalOrFormal("jouw", "uw", isComa)} voertuig nodig ter controle.
          </p>
          <p>
            Let op: {getInformalOrFormal("Heb je", "Heeft u", isComa)} een{" "}
            <b>voertuig zonder kenteken</b>? Druk dan op de belknop rechtsboven.
          </p>
          <Form
            errorNotification={{
              variant: "error",
              title: "Onjuist kenteken",
              children: "Het ingevoerde kenteken lijkt ongeldig.",
            }}
            /* eslint-disable */ // onSubmit expects a void return, but sendCancelRequest must be awaited
            onSubmit={async (state) => {
              const { elementsData: data, valid } = state as {
                elementsData: LicensePlateFormValues;
                valid: boolean;
              };

              setRequestFailed(false);

              if (valid) {
                // TODO: dashes dont have to be removed from the license plate anymore as soon as this is fixed in Flow
                const licensePlate = data.licensePlate.replace(/-+/g, "").toUpperCase();
                await sendCancelRequest(caseNumber, licensePlate);
              }
            }}
            /* eslint-enable */
          >
            {requestFailed && (
              <Notification
                variant="error"
                title="Bel de Wegenwacht®"
                class="cancel-failed-notification"
              >
                Het is niet gelukt om de melding te annuleren. Controleer het kenteken en probeer
                het opnieuw, of neem contact met ons op via de belknop hieronder.
              </Notification>
            )}

            <LicensePlate
              autoFocus
              name="licensePlate"
              label="Kenteken"
              reference="licensePlate"
              required={true}
              data-testid="license-plate"
            />
            <ButtonPrimary type="submit" className="cancel-button" onClick={gtmCancelClick}>
              Melding annuleren {showSpinner && <Spinner size="small" className="spinner" />}
            </ButtonPrimary>
            {showCallButton && (
              <ButtonSecondary icon="phone" className="call-button">
                <a
                  href={`tel: ${caseReceivedMessage?.assistancePhoneNumber}`}
                  className="call-button-link"
                  onClick={() => gtmPhoneClick(caseReceivedMessage?.assistancePhoneNumber)}
                >
                  Bel de Wegenwacht®
                </a>
              </ButtonSecondary>
            )}
          </Form>
        </div>
      ) : (
        <SuccessfullCancel />
      )}
      <Footer
        onInformationClick={() => navigate(informationLink)}
        onSafetyClick={() => navigate(safetyLink)}
        onBreakdownLocationClick={() => navigate(breakdownLocationLink)}
      />
    </StyledInformation>
  );
};

export default Cancel;
