import { Address } from "@status-updates/types";
import { localizeAddress } from "@status-updates/shared/src/util/localize-address";
import { jsxJoin } from "./jsx-join";
import Typography from "@anwb/typography";

export function styledNameAndAddressWithMapsLink(
  name: string | undefined,
  address: Address | undefined,
  withLink = true
): JSX.Element[] | JSX.Element {
  const addressFragmentLines = address ? localizeAddress(address) : [];
  const allLines = name ? [name, ...addressFragmentLines] : addressFragmentLines;
  const styledNameAndAddress = jsxJoin(allLines, <br />);
  if (address === undefined || address.coordinates === undefined) {
    return styledNameAndAddress;
  }

  return withLink ? (
    <Typography
      variant="link-text"
      href={createGoogleMapsLink(address.coordinates.latitude, address.coordinates.longitude)}
    >
      {styledNameAndAddress}
    </Typography>
  ) : (
    styledNameAndAddress
  );
}

export function createGoogleMapsLink(lat: number, lon: number) {
  return `https://www.google.com/maps?q=${lat},${lon}`;
}
