import { FlexibleAppointmentMessageDetails, TypedStatusUpdateMessage } from "@status-updates/types";
import Typography from "@anwb/typography";
import { DateTime } from "luxon";
import { FrontendState, StateMetadata } from "../frontend-state";
import { MeldingAnnuleren } from "./melding-annuleren";
import { useStatusUpdates } from "../../routes/home/hooks/useStatusUpdates";

export class AppointmentConfirmedState extends FrontendState<FlexibleAppointmentMessageDetails> {
  constructor(
    message: TypedStatusUpdateMessage<FlexibleAppointmentMessageDetails>,
    metadata: StateMetadata
  ) {
    super(message, metadata, {
      illustration: "alarmcentrale",
      title: "Afspraakbevestiging",
    });
  }

  renderTimelineItems() {
    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: this.options.title,
        content: () => {
          const { caseReceivedMessage } = useStatusUpdates();

          return (
            <Typography
              data-e2e-test-type="status-updates-appointment-confirmed-message"
              tagName="div"
            >
              <p>
                We streven ernaar {this.getInformalOrFormal("je", "u")} tussen{" "}
                {/* Non-null since we check dateFrom and dateTo in the message generator */}
                {parseDateTime(this.message.messageDetails.appointment.dateFrom!)} en{" "}
                {parseDateTime(this.message.messageDetails.appointment.dateTo!)} uur te helpen.
                Zodra de wegenwacht onderweg is, {this.getInformalOrFormal("krijg je", "krijgt u")}{" "}
                hier een nieuwe update.
                <br /> <br />
                <strong>Locatie</strong> <br />
                {caseReceivedMessage?.breakdownLocationStreet}{" "}
                {caseReceivedMessage?.breakdownLocationHouseNumber}{" "}
                {caseReceivedMessage?.breakdownLocationHouseNumberExtension}
                <br />
                {caseReceivedMessage?.breakdownLocationPostalCode}{" "}
                {caseReceivedMessage?.breakdownLocationCity}
              </p>

              {this.metadata.showDigitalCancelButton && (
                <MeldingAnnuleren
                  contractMarket={this.metadata.contractMarket}
                  assistancePhonenumber={this.metadata.assistancePhonenumber}
                />
              )}
            </Typography>
          );
        },
        feedback: this.renderFeedback(),
      },
    ];
  }
}

/**
 * Parses a date and returns it in hour:minute format
 *
 * @param etaISO
 */
function parseDateTime(etaISO: string) {
  return DateTime.fromISO(etaISO).toLocaleString(DateTime.TIME_24_SIMPLE);
}
