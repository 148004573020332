import { FunctionComponent } from "react";
import { ButtonContainer } from "./styled";
import { ButtonTertiary } from "@anwb/button";

interface AssistanceButtonProps {
  assistancePhoneNumber: string | undefined;
}

const AssistanceButton: FunctionComponent<AssistanceButtonProps> = ({ assistancePhoneNumber }) => {
  return (
    <ButtonContainer>
      <ButtonTertiary href={`tel:${assistancePhoneNumber}`} icon="phone">
        Melding wijzigen of annuleren
      </ButtonTertiary>
    </ButtonContainer>
  );
};

export default AssistanceButton;
