import Typography from "@anwb/typography";
import { CaseReceivedMessageDetails, TypedStatusUpdateMessage } from "@status-updates/types";
import { ButtonTertiary } from "@anwb/button";
import { DateTime } from "luxon";
import { determineInformationLinkText } from "../../utils/countrySpecificText";

import { FrontendState, StateMetadata } from "../frontend-state";
import { ButtonLink } from "../../components/link";

export class CaseReceivedState extends FrontendState<CaseReceivedMessageDetails> {
  constructor(
    message: TypedStatusUpdateMessage<CaseReceivedMessageDetails>,
    metadata: StateMetadata
  ) {
    super(message, metadata, {
      illustration: "alarmcentrale",
      title: "Melding goed ontvangen",
    });
  }

  renderTimelineItems() {
    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: this.options.title,
        content: (
          <Typography data-e2e-test-type="status-updates-intake-message" tagName="div">
            <p>
              Vervelend dat {this.getInformalOrFormal("je", "u")} pech{" "}
              {this.getInformalOrFormal("hebt", "heeft")}. We hebben{" "}
              {this.getInformalOrFormal("je", "uw")} gegevens goed ontvangen en gaan{" "}
              {this.getInformalOrFormal("je", "u")} zo snel mogelijk helpen.
              <br />
              <br />
              Let op {this.getInformalOrFormal("jouw", "uw")} veiligheid. Klik op de veiligheidsknop
              hieronder voor tips.
            </p>
            <ButtonLink container={ButtonTertiary} href="informatie">
              {determineInformationLinkText("hulpverlening")}
            </ButtonLink>
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
