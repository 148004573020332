import { ButtonPrimary } from "@anwb/button";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useLinkBuilder } from "../../../../../../components/link";
import { WegenwachtUpdate } from "../wegenwacht-update";
import { WegenwachtUpdateArgs } from "../..";

type WegenwachtScheduleChangedProps = WegenwachtUpdateArgs;

export function WegenwachtScheduleChanged({ isFormal }: WegenwachtScheduleChangedProps) {
  const navigate = useNavigate();
  const link = useLinkBuilder("/");

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate(link);
    }, 10_000);

    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <WegenwachtUpdate
      illustration="alarmcentrale"
      title="Planning gewijzigd"
      footer={<ButtonPrimary onClick={() => navigate(link)}>Terug</ButtonPrimary>}
    >
      <p>
        {isFormal ? "U" : "Je"} wordt binnen 10 seconden teruggestuurd naar de pagina waar{" "}
        {isFormal ? "u" : "je"} vandaan komt.
      </p>
    </WegenwachtUpdate>
  );
}
