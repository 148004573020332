import Typography, { PageTitle } from "@anwb/typography";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router";
import { ButtonContainer, ButtonSecondary } from "@anwb/button";
import { Illustration } from "../../../../../../../../components/illustration";
import { useLinkBuilder } from "../../../../../../../../components/link";
import styled from "styled-components";

const AssistanceEndContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${ButtonContainer} {
    width: 100%;
    display: inline-flex;
  }
}
  `;

export const AssistanceEnd: FunctionComponent = () => {
  const navigate = useNavigate();
  const link = useLinkBuilder("/");

  return (
    <AssistanceEndContainer>
      <PageTitle>Geen updates van de hulpdienst beschikbaar</PageTitle>
      <Illustration variant="sleepwagen-onderweg" />
      <Typography>
        Er zijn geen updates van de hulpdienst meer beschikbaar. Ga terug naar de{" "}
        <Typography variant="link-text" href={link}>
          status updates
        </Typography>{" "}
        om de nieuwste updates te bekijken.
      </Typography>
      <ButtonSecondary icon={null} onClick={() => navigate(link)}>
        Terug naar status updates
      </ButtonSecondary>
    </AssistanceEndContainer>
  );
};
