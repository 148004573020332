import { ContentTitle } from "@anwb/typography";
import styled from "styled-components";

export const StyledInformation = styled.section`
  .country-information {
    margin: 0 auto;
    padding: 5px 12px;
    max-width: ${({ theme }) => `${theme.viewportBreakpoint.sm}px`};
  }

  h1 {
    padding-top: 5.065rem;
    font-style: normal;
    margin: 1rem 0;
  }

  ${ContentTitle} {
    scroll-margin-top: ${({ theme }) =>
      `calc(${theme.header.height} + ${theme.header.borderWidth})`};
    margin-top: -5.3rem;
    padding-top: 8.5rem;
  }

  }
`;
