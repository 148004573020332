import Typography, { LabelTitle, PageTitle } from "@anwb/typography";
import Spinner from "@anwb/spinner";
import Icon from "@anwb/icon";
import { PanelContainer } from "@anwb/panel";
import { ButtonTertiary } from "@anwb/button";
import { CaseReceivedMessageDetails } from "@status-updates/types";
import { useQuery } from "@tanstack/react-query";
import React, { FunctionComponent } from "react";
import { useParams } from "react-router";
import { DateTime } from "luxon";

import { StyledHulpdienstContainer } from "../Styles/styled";
import { createClient } from "../../../../../../../../features/api/backendClient";
import { ForeignRoadsideAssistanceContainer } from "../Styles/styled";
import {
  formatAverageWaitingTime,
  formatTimestampTo20MinTimeSlot,
} from "../../../../../../../../utils/timestamp";
import { useStatusUpdates } from "../../../../../../hooks/useStatusUpdates";
import { NotFound } from "../../NotFound";
import { foreignRoadsideAssistanceFeed } from "../../../../../../../../features/api/generated/Backend";
import {
  IconWegenwacht,
  IconWithPanel,
} from "../../../../../waar-is-de-wegenwacht/components/wegenwacht-update/styled";
import { Divider } from "../../../../../../../../components/divider";
import { AssistanceEnd } from "./AssistanceEndPage";

export const ForeignRoadsideAssistancePage: FunctionComponent = () => {
  const { token, id } = useParams();

  if (!token || !id) {
    return <NotFound />;
  }

  const data = useQuery([`feed-${id}`], async () => createClient().default.getFeed(id, token), {
    staleTime: 60_000,
    refetchOnWindowFocus: true,
    retry: (failureCount) => {
      if (failureCount > 1) {
        return false;
      }
      return true;
    },
  });

  return (
    <ForeignRoadsideAssistanceContainer>
      {data.isLoading && <Spinner message="Bezig met ophalen van informatie over de hulpdienst" />}
      {data.isError && <AssistanceEnd />}
      {data.isSuccess && (
        <ForeignRoadsideAssistanceFeed {...(data.data as foreignRoadsideAssistanceFeed)} />
      )}
    </ForeignRoadsideAssistanceContainer>
  );
};

export const ForeignRoadsideAssistanceFeed: FunctionComponent<foreignRoadsideAssistanceFeed> = ({
  eta,
  serviceProvider,
  lastUpdated,
  averageWaitingTime,
}) => {
  const { isComa, messages } = useStatusUpdates();
  const isFormal = !isComa;

  const estimatedTime = formatTimestampTo20MinTimeSlot(eta);
  const CaseReceivedMessage = messages.find(
    ({ messageType }) => messageType === "CaseReceivedMessage"
  );
  const countryName = (CaseReceivedMessage!.messageDetails as CaseReceivedMessageDetails)
    .breakdownLocationCountryName;

  const averageWaitingTimeText = averageWaitingTime
    ? formatAverageWaitingTime(averageWaitingTime)
    : "2 uur";

  const iconText = estimatedTime
    ? `De hulpdienst is onderweg en is ${estimatedTime} bij ${isFormal ? "u" : "je"}!`
    : `Onze partner heeft laten weten dat de hulpdienst naar ${isFormal ? "u" : "je"} toekomt.`;
  const additionalText = estimatedTime
    ? `De hulpdienst kan iets te vroeg of te laat zijn. Blijf in de buurt van  ${
        isFormal ? "uw" : "je"
      } voertuig en houd ${isFormal ? "uw" : "je"} telefoon bij de hand.`
    : `De hulpdienst weet nog niet hoe laat ze ongeveer bij ${
        isFormal ? "u" : "je"
      } zijn. Zodra er meer nieuws is, ${isFormal ? "krijgt u" : "krijg je"} een nieuwe update.`;

  const averageWaitingTimeTextParagraph = `De gemiddelde wachttijd op de hulpdienst in 
  ${countryName}
is op dit moment ${averageWaitingTimeText}. Blijf in de buurt van ${
    isFormal ? "uw" : "je"
  } voertuig en houd ${isFormal ? "uw" : "je"} telefoon bij de hand.`;

  return (
    <>
      <PageTitle>Updates hulpdienst</PageTitle>
      <>
        <IconWithPanel>
          <IconWegenwacht>
            <Icon type="illustrative" size="xxl" variant="tow-truck" />
          </IconWegenwacht>
          <PanelContainer $hasMedia={false}>
            <LabelTitle>{iconText}</LabelTitle>
          </PanelContainer>
        </IconWithPanel>
        {lastUpdated && (
          <Typography variant="support-text" className="registration-updated-time">
            Laatste update: {DateTime.fromISO(lastUpdated).toLocaleString(DateTime.TIME_24_SIMPLE)}
          </Typography>
        )}
        <Typography>{additionalText}</Typography>
        {!estimatedTime && <Typography>{averageWaitingTimeTextParagraph}</Typography>}
        {serviceProvider && (
          <StyledHulpdienstContainer>
            <Divider />
            <Typography variant="content-title" tagName="i" style={{ marginBottom: "0.375em" }}>
              Deze hulpdienst gaat je helpen
            </Typography>
            <Typography>{serviceProvider}</Typography>
          </StyledHulpdienstContainer>
        )}
        <br />
        <ButtonTertiary icon="phone" href={CaseReceivedMessage?.metadata?.assistancePhoneNumber}>
          Melding annuleren
        </ButtonTertiary>
      </>
    </>
  );
};
