import type { FunctionComponent } from "react";
import { Outlet, useParams } from "react-router-dom";

import { StatusUpdatesProvider } from "./StatusUpdatesProvider";
import { StyledHome } from "./styled";
import Header from "../../components/header";

const HomeLayout: FunctionComponent = () => {
  const { token } = useParams();
  const isReadOnly: boolean =
    new URLSearchParams(window.location.search).get("isReadOnly") === "true";

  if (token === undefined) {
    console.error("Token is undefined, cannot continue");
    return null;
  }

  return (
    <StatusUpdatesProvider token={token} isReadOnly={isReadOnly}>
      {({ caseReceivedMessage, messages }) => (
        <StyledHome>
          <Header
            contractMarket={caseReceivedMessage?.contractMarket}
            contractLabelId={caseReceivedMessage?.contractLabelId}
            assistancePhoneNumber={caseReceivedMessage?.assistancePhoneNumber}
            lastMessage={messages[0]}
          />
          <main>
            <Outlet />
          </main>
        </StyledHome>
      )}
    </StatusUpdatesProvider>
  );
};

export default HomeLayout;
