import { PageTitle } from "@anwb/typography";
import type { FunctionComponent } from "react";

import "./style.css";

const EmptyToken: FunctionComponent = () => {
  return (
    <div className="emptyToken">
      <PageTitle>Status updates</PageTitle>
      <p role="paragraph">
        Via deze pagina houden wij je op de hoogte met nieuwe updates over de status van je pechmelding. Je leest hier ook hoe wij jou kunnen helpen. Wij sturen je een sms-bericht met jouw persoonlijke link nadat je een pechmelding hebt gedaan. Dit is geen persoonlijke pagina. Je kunt deze pagina sluiten.
      </p>
    </div>
  );
};

export default EmptyToken;
