import { FunctionComponent, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UtmSourceIdentifier } from "@status-updates/shared/src/util/utmSourceIdentifier";

function utmParams(utmCampaign: string) {
  /* eslint-disable */
  return {
    utm_source: "mijnpech.info",
    utm_medium: "sms",
    utm_campaign: utmCampaign,
  };
  /* eslint-enable */
}

/**
 * Redirects the customer to a url with the appropriate 'utm' parameters for google analytics
 *
 * Including all necessary parameters makes the url much longer than a normal one. As the link
 * is sent using SMS, the number of characters is limited. For that reason, an additional
 * redirect step is used to send the user to the correct url
 */
export const RedirectWithUtm: FunctionComponent = () => {
  const { token, source } = useParams<{ source: string; token: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    let additionalParams = {};
    // eslint-disable-next-line default-case
    switch (source) {
      case UtmSourceIdentifier.FLOW:
        additionalParams = { isReadOnly: true };
        break;
      case UtmSourceIdentifier.FIRST_SMS:
        additionalParams = utmParams("1-eerste-bezoek");
        break;
      case UtmSourceIdentifier.RECURRENT_SMS:
        additionalParams = utmParams("2-terugkerend-bezoek");
        break;
      case UtmSourceIdentifier.WEB_PUSH:
        additionalParams = utmParams("3-webpush-bezoek");
        break;
    }

    navigate(`/${token}?${new URLSearchParams(additionalParams) as unknown as string}`);
  }, [source, token, navigate]);

  return null;
};
