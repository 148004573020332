import Typography from "@anwb/typography";
import { ButtonTertiary } from "@anwb/button";
import { DateTime } from "luxon";
import { LogicxTransportPlannedDetails, TypedStatusUpdateMessage } from "@status-updates/types";
import { FrontendState, StateMetadata } from "../../frontend-state";
import { ButtonLink } from "../../../components/link";

export class InternationalTransportIsPlannedState extends FrontendState<LogicxTransportPlannedDetails> {
  private readonly vehicleType: string;
  constructor(
    message: TypedStatusUpdateMessage<LogicxTransportPlannedDetails>,
    metadata: StateMetadata
  ) {
    super(message, metadata, {
      illustration: "internationaal-transport",
      title: `${FrontendState.getInformalOrFormal("Jouw", "Uw", metadata.contractMarket)} ${
        message.messageDetails.vehicleType
      } is ingepland voor transport`,
    });

    this.vehicleType = message.messageDetails.vehicleType;
  }

  renderTimelineItems() {
    const created = DateTime.fromISO(this.message.created);
    return [
      {
        timestamp: created,
        title: this.options.title,
        content: (
          <>
            <Typography
              data-e2e-test-type="status-updates-international-transport-is-planned"
              tagName="div"
            >
              Aankomst hangt af van verkeer, weekenden, rij- en rusttijden.{" "}
              {this.getInformalOrFormal("Je", "U")} ontvangt een bericht zodra{" "}
              {this.getInformalOrFormal("jouw", "uw")} {this.vehicleType} op de eindbestemming is
              aangekomen.
            </Typography>
            <br />
            <ButtonLink href="informatie#voertuig-nederland" container={ButtonTertiary}>
              Informatie, tips en contact
            </ButtonLink>
          </>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
