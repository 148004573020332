import styled from "styled-components";

export const StyledPushNotification = styled.section`
  box-sizing: border-box;
  align-items: flex-start;
  padding: 12px;
  gap: 12px;

  background: #ffffff;
  border: 2px solid ${({ theme }) => theme.colors.medium.borderFormField};
  border-radius: 12px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 22px;

  .header {
    display: flex;
    flex-direction: row;
  }

  label {
    width: 100%;
    span {
      font-style: normal;
      font-weight: bold;
      line-height: 21px;
      color: ${({ theme }) => theme.colors.base.textTitles};
    }
  }

  input[type="checkbox"] {
    visibility: hidden;
  }

  .notification-description {
    color ${({ theme }) => theme.colors.informative.titleNotification};
    display: flex;
    padding-left: 2.13333rem;
  }
`;
