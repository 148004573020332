import { Interval, DateTime, Duration } from "luxon";
import { holidays } from "../../../shared/src/util/holidays";

export function formatTimestamp(timestamp: DateTime): string {
  return timestamp.setLocale("nl-NL").toFormat("HH':'mm' - 'd MMMM yyyy");
}

export function formatTimestampToDate(timestamp: DateTime): string {
  return timestamp.setLocale("nl-NL").toFormat("d MMMM");
}

export function formatTimestampTo20MinTimeSlot(eta: string | undefined): string | undefined {
  if (!eta) {
    return;
  }

  const timestamp = DateTime.fromISO(eta, {
    locale: "nl-NL",
  });

  const dateText =
    timestamp.startOf("day").toMillis() === DateTime.now().startOf("day").toMillis()
      ? ""
      : `op ${timestamp.toFormat("d MMMM")} `;
  const beginTime = timestamp.toLocaleString(DateTime.TIME_24_SIMPLE);
  const endTime = timestamp.plus({ minutes: 20 }).toLocaleString(DateTime.TIME_24_SIMPLE);
  return `${dateText}tussen ${beginTime}-${endTime}`;
}

export function formatAverageWaitingTime(averageWaitingTime: number): string {
  if (averageWaitingTime >= 60) {
    const hours = Math.floor(averageWaitingTime / 60);
    const spareMinutes = averageWaitingTime % 60;
    return spareMinutes === 0
      ? `${hours} uur`
      : spareMinutes === 1
      ? `${hours} uur en 1 minuut`
      : `${hours} uur en ${spareMinutes} minuten`;
  } else {
    return averageWaitingTime === 1 ? "1 minuut" : `${averageWaitingTime} minuten`;
  }
}

export function calculateDaysDifference(eta: string | undefined): number | undefined {
  if (!eta) {
    return undefined;
  }

  const now = DateTime.local().startOf("day");
  const etaDateTime = DateTime.fromISO(eta).startOf("day");
  const diff = etaDateTime.diff(now, "days").toObject();
  return diff.days !== undefined && diff.days >= 0 ? diff.days : 0;
}

export function calculateWorkDaysDifference(eta: string | undefined): number | undefined {
  if (!eta) {
    return undefined;
  }
  const now = DateTime.local().startOf("day");
  const etaDateTime = DateTime.fromISO(eta).startOf("day");
  const interval = Interval.fromDateTimes(now, etaDateTime);
  const intervalDateArray = interval.splitBy({ days: 1 }).map((d) => d.start);
  let numOfNonWorkdays = Duration.fromObject({ days: 0 });

  intervalDateArray.forEach((day) => {
    if (day.weekday === 6 || day.weekday === 7 || holidays.includes(day.toMillis())) {
      numOfNonWorkdays = numOfNonWorkdays.plus({ days: 1 });
      return;
    }
  });

  const diff = etaDateTime.diff(now, "days");
  const diffWorkdays = diff.minus(numOfNonWorkdays);
  return diffWorkdays.toObject().days !== undefined && diffWorkdays.toObject().days! >= 0
    ? diffWorkdays.toObject().days
    : 0;
}
