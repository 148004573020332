import { Fragment } from "react";

export function jsxJoin(
  addressFragmentLines: string[],
  separator: JSX.Element | undefined = undefined
): JSX.Element[] {
  return addressFragmentLines.map((addressLine, index) => (
    <Fragment key={index}>
      {!!index && separator}
      {addressLine}
    </Fragment>
  ));
}
