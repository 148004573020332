import { useQuery } from "@tanstack/react-query";
import { createClient } from "../../features/api/backendClient";
import { useStatusUpdates } from "../../routes/home/hooks/useStatusUpdates";
import type { form, feed } from "../../features/api/generated/Backend";
import styled from "styled-components";
import { BlockContainer } from "@anwb/block";

type Props = {
  children: (feeds: feed[], forms: form[]) => React.ReactNode;
  setIsLoading: (value: boolean) => void;
};

const ActionTilesContainer = styled.section`
  ${BlockContainer} {
    margin: 12px 10px;

    h4 {
      margin-top: 0;
    }
  }
`;

export const ActionTiles: React.FC<Props> = ({ children, setIsLoading }) => {
  const { token } = useStatusUpdates();
  const formsQuery = useQuery(["formTiles"], async () => {
    return createClient().default.getForms(token);
  });

  const feedsQuery = useQuery(["feedTiles"], async () => {
    return createClient().default.getFeeds(token);
  });

  setIsLoading(formsQuery.isLoading || feedsQuery.isLoading);

  if (formsQuery.data && feedsQuery.data) {
    return (
      <ActionTilesContainer>{children(feedsQuery.data, formsQuery.data)}</ActionTilesContainer>
    );
  }

  return null;
};
