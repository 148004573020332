import { useQuery } from "@tanstack/react-query";
import { FunctionComponent } from "react";
import { createClient } from "../../../../../../../../features/api/backendClient";
import { useNavigate, useParams } from "react-router";
import { Illustration } from "../../../../../../../../components/illustration";
import Typography, { PageTitle } from "@anwb/typography";
import { Divider } from "@anwb/panel/dist/variants/PanelContact/components";
import { ButtonSecondary, ButtonTertiary } from "@anwb/button";
import { ButtonsContainer, CenteredImage, InternationalTransportContainer } from "../Styles/styled";
import { useLinkBuilder } from "../../../../../../../../components/link";
import {
  calculateDaysDifference,
  calculateWorkDaysDifference,
} from "../../../../../../../../utils/timestamp";
import { useStatusUpdates } from "../../../../../../hooks/useStatusUpdates";
import { DateTime } from "luxon";
import { NotFound } from "../../NotFound";
import Spinner from "@anwb/spinner";
import { internationalTransportFeed } from "../../../../../../../../features/api/generated/Backend";

export const InternationalTransportPage: FunctionComponent = () => {
  const { token, id } = useParams();

  if (!token || !id) {
    return <NotFound />;
  }

  const data = useQuery([`feed-${id}`], async () => createClient().default.getFeed(id, token), {
    staleTime: 60_000,
    refetchOnWindowFocus: false,
  });

  return (
    <InternationalTransportContainer>
      <PageTitle>Status Transport</PageTitle>
      <CenteredImage>
        <Illustration variant="internationaal-transport" />
      </CenteredImage>
      {data.isLoading && <Spinner message="Bezig met ophalen van informatie over het transport" />}
      {data.isError && <NotFound />}
      {data.isSuccess && (
        <InternationalTransportFeed {...(data.data as internationalTransportFeed)} />
      )}
    </InternationalTransportContainer>
  );
};

export const InternationalTransportFeed: FunctionComponent<internationalTransportFeed> = ({
  eta,
  vehicleType,
  status,
}) => {
  const { isComa } = useStatusUpdates();
  const returnLink = useLinkBuilder("/");
  const infoLink = useLinkBuilder("informatie#voertuig-nederland");
  const navigate = useNavigate();
  const vehicleIsAlmostAtDestination = status === "ALMOST_AT_DESTINATION";
  const days = vehicleIsAlmostAtDestination
    ? calculateWorkDaysDifference(eta)
    : calculateDaysDifference(eta);
  const statusText = vehicleIsAlmostAtDestination
    ? "er bijna en bereikt de eindbestemming over ongeveer:"
    : "onderweg naar de eindbestemming en komt daar aan over ongeveer:";

  const isExpired = isEtaExpired(eta);

  return (
    <>
      {!isExpired && (
        <>
          <Typography>
            {isComa ? "Jouw" : "Uw"} {vehicleType} is {statusText}
          </Typography>
          <PageTitle variant="page-title">
            {days} {`${vehicleIsAlmostAtDestination ? "werk" : ""}dag${days === 1 ? "" : "en"}`}
          </PageTitle>
          <Divider />
          <Typography>
            Aankomst hangt af van verkeer, weekenden, rij- en rusttijden. {isComa ? "Je" : "U"}{" "}
            ontvangt een bericht zodra {isComa ? "jouw" : "uw"} voertuig op de eindbestemming is
            aangekomen.{" "}
          </Typography>
          <Typography>
            Zorg dat {isComa ? "je zelf jouw" : "u zelf uw"} garage op de hoogte brengt, voordat{" "}
            {isComa ? "jouw" : "uw"} voertuig op de eindbestemming aankomt.
          </Typography>{" "}
        </>
      )}
      <Typography>
        {isComa ? "Heb je" : "Heeft u"} vragen over transport? Bel dan onze logistieke afdeling op:{" "}
        <Typography variant="link-text" href="tel: +31882695255">
          +31882695255
        </Typography>
        .
      </Typography>
      {!isExpired && (
        <Typography>
          We geven {isComa ? "je" : "u"} graag informatie en tips over het transport van{" "}
          {isComa ? "jouw" : "uw"} voertuig.
        </Typography>
      )}
      <ButtonsContainer>
        {isExpired ? (
          <ButtonSecondary icon="arrow-right" onClick={() => navigate(returnLink)}>
            Sluit
          </ButtonSecondary>
        ) : (
          <>
            <ButtonSecondary icon="arrow-right" onClick={() => navigate(infoLink)}>
              Informatie, tips en contact
            </ButtonSecondary>
            <ButtonTertiary
              icon="arrow-left"
              iconPosition="before"
              onClick={() => navigate(returnLink)}
            >
              Terug
            </ButtonTertiary>
          </>
        )}
      </ButtonsContainer>
    </>
  );
};

function isEtaExpired(eta: string) {
  const etaTime = DateTime.fromISO(eta);

  return DateTime.now() > etaTime;
}
