import { ButtonPrimary } from "@anwb/button";
import { Textarea } from "@anwb/forms";
import Typography from "@anwb/typography";
import { ChangeEvent, FunctionComponent } from "react";
import { useState } from "react";
import { StatusUpdatesMessageType } from "@status-updates/types";
import { useQuery } from "@tanstack/react-query";

import { StyledFeedback } from "./style";
import { sendFeedback } from "./utils";

export type FeedbackStatus = "INITIAL" | "DISMISSED" | "SENT";
type Props = {
  messageType: StatusUpdatesMessageType;
  caseNumber: string;
  timestamp: string;
  marketType?: string;
  country?: string;
  label?: string;
};

export const FeedbackButton: FunctionComponent<Props> = ({
  messageType,
  caseNumber,
  timestamp,
  marketType,
  country,
  label,
}) => {
  const [isPositiveFeedback, setIsPositiveFeedback] = useState<boolean | undefined>(undefined);
  const [isHidden, setIsHidden] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [explanation, setExplanation] = useState("");
  const [thanksTimeoutElapsed, setThanksTimeoutElapsed] = useState<boolean>(false);

  const { status, isSuccess, isError } = useQuery(
    [{ timestamp }],
    async () =>
      sendFeedback({
        messageType,
        caseNumber,
        isPositiveFeedback: isPositiveFeedback!,
        explanation,
        marketType,
        country,
        label,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      enabled: shouldFetch,
      retry: false,
      staleTime: Infinity,
    }
  );

  function submit() {
    setTimeout(() => {
      // removes thank message after one minute
      setThanksTimeoutElapsed(true);
    }, 1000 * 10);

    if (isPositiveFeedback !== undefined) {
      setShouldFetch(true);
    }
  }

  if (status === "loading" && !isHidden && !isSuccess) {
    return (
      <StyledFeedback>
        <Typography variant="label-title" className="feedback">
          Was dit status bericht nuttig?
          <ButtonPrimary
            data-testid="cross"
            className="cross"
            icon="cross"
            onClick={() => setIsHidden(true)}
            children=""
          />
          <br />
          {(isPositiveFeedback === true || isPositiveFeedback === undefined) && (
            <ButtonPrimary
              data-testid="thumb-up"
              iconPosition="before"
              icon="thumb-up"
              className="button"
              onClick={(): void => setIsPositiveFeedback(true)}
            >
              Nuttig
            </ButtonPrimary>
          )}
          {(isPositiveFeedback === false || isPositiveFeedback === undefined) && (
            <ButtonPrimary
              data-testid="thumb-down"
              iconPosition="before"
              icon="thumb-down"
              className="button"
              onClick={(): void => setIsPositiveFeedback(false)}
            >
              Niet nuttig
            </ButtonPrimary>
          )}
          {isPositiveFeedback !== undefined && (
            <>
              <br />
              Toelichting <br />
              <Textarea
                name="feedback"
                className="textarea"
                placeholder="Typ hier je toelichting..."
                valid={true}
                reference="feedbackTextarea"
                data-testid="feedbackTextarea"
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                  setExplanation(e.target?.value.trim())
                }
              />
              <br />
              <ButtonPrimary data-testid="send" onClick={() => submit()}>
                Versturen
              </ButtonPrimary>
            </>
          )}
        </Typography>
      </StyledFeedback>
    );
  }

  if ((isSuccess || isError) && !thanksTimeoutElapsed) {
    return (
      <StyledFeedback>
        <Typography data-testid="thanks" variant="label-title" className="feedback">
          Bedankt voor de feedback!
        </Typography>
      </StyledFeedback>
    );
  }

  return null;
};
