import { createStore, GlobalState } from "little-state-machine";
import { TravelInfoForm } from "./TravelInformationForm";

export function initStore() {
  createStore(
    {
      travelInformationForm: {
        travelInformation: {
          journeyStart: undefined,
          journeyEnd: undefined,
          travelType: undefined,
          countryOfDestination: undefined,
          returnsAtBreakdownLocation: undefined,
          returnsAtBreakdownLocationDate: undefined,
          comment: undefined,
        },
        travelDocuments: {
          hasIdentificationCard: undefined,
          hasDriversLicense: undefined,
          hasCreditCard: undefined,
          hasDebitCard: undefined,
        },
        travelEquipment: {
          hasTrailer: undefined,
          hasCaravan: undefined,
          hasFoldingCamper: undefined,
          hasBikeRack: undefined,
          hasTowbarLuggage: undefined,
          hasRoofLuggage: undefined,
        },
      },
    },
    {
      name: "travel-information",
    }
  );
}

export function updateTravelInformation(state: GlobalState, updateData: Partial<TravelInfoForm>) {
  return {
    travelInformationForm: {
      travelInformation: {
        ...updateData.travelInformation,
      },
      travelDocuments: {
        ...state.travelInformationForm?.travelDocuments,
      },
      travelEquipment: {
        ...state.travelInformationForm?.travelEquipment,
      },
    },
  };
}

export function updateDocumentsAndEquipment(
  state: GlobalState,
  updateData: Partial<TravelInfoForm>
) {
  return {
    travelInformationForm: {
      travelInformation: {
        ...state.travelInformationForm?.travelInformation,
      },
      travelDocuments: {
        ...updateData.travelDocuments,
      },
      travelEquipment: {
        ...updateData.travelEquipment,
      },
    },
  };
}
