import styled from "styled-components";

export const CenteredWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px; // to accommodate for the fixed navigation
  margin-left: auto;
  margin-right: auto;

  padding: 14px 0 0 0;

  .PONCHO-typography--page-title {
    margin-bottom: 1rem;
  }
`;
