import Icon from "@anwb/icon";
import Typography from "@anwb/typography";
import { FunctionComponent } from "react";
import { StyledFooter } from "./styled";
import { useStatusUpdates } from "../../routes/home/hooks/useStatusUpdates";

type FooterProps = {
  onInformationClick: () => void;
  onSafetyClick: () => void;
  onBreakdownLocationClick: () => void;
};

const Footer: FunctionComponent<FooterProps> = ({
  onInformationClick,
  onSafetyClick,
  onBreakdownLocationClick,
}) => {
  const { messages, caseReceivedMessage } = useStatusUpdates();

  const lastMessageType: string = messages[0].messageType;

  const showSafety: boolean =
    caseReceivedMessage?.breakdownLocationCountry === "NL" || determineShowSafety(lastMessageType);

  const breakdownCoordinates = caseReceivedMessage
    ? caseReceivedMessage.breakdownCoordinates
    : undefined;
  const showBreakDownLocation =
    caseReceivedMessage?.breakdownLocationCountry === "NL" &&
    breakdownCoordinates?.latitude !== undefined &&
    breakdownCoordinates?.longitude !== undefined;

  const showInformation = caseReceivedMessage?.breakdownLocationCountry !== "NL";

  return (
    <StyledFooter>
      <section>
        <Buttons
          showSafety={showSafety}
          showInformation={showInformation}
          showBreakDownLocation={showBreakDownLocation}
          onInformationClick={onInformationClick}
          onSafetyClick={onSafetyClick}
          onBreakdownLocationClick={onBreakdownLocationClick}
        />
      </section>
    </StyledFooter>
  );
};

export default Footer;

type ButtonsProps = {
  showSafety: boolean;
  showInformation: boolean;
  showBreakDownLocation: boolean;
  onInformationClick: () => void;
  onSafetyClick: () => void;
  onBreakdownLocationClick: () => void;
};

const Buttons: FunctionComponent<ButtonsProps> = ({
  showSafety,
  showInformation,
  showBreakDownLocation,
  onInformationClick,
  onSafetyClick,
  onBreakdownLocationClick,
}) => {
  const safetyButton = (
    <button type="button" onClick={onSafetyClick}>
      <Icon type="ui" variant="warning" size="lg" />
      &nbsp;
      <Typography variant="footer-link">Veiligheid</Typography>
    </button>
  );
  const informationButton = (
    <button type="button" onClick={onInformationClick}>
      <Icon type="ui" variant="information" size="lg" />
      &nbsp;
      <Typography variant="footer-link">Informatie</Typography>
    </button>
  );

  const breakdownLocationButton = (
    <button type="button" onClick={onBreakdownLocationClick}>
      <Icon type="ui" variant="pinpoint" size="lg" />
      &nbsp;
      <Typography variant="footer-link">Mijn pechlocatie</Typography>
    </button>
  );

  return (
    <div style={{ display: "flex" }}>
      {showInformation && informationButton}
      {showSafety && safetyButton}
      {showBreakDownLocation && breakdownLocationButton}
    </div>
  );
};

function determineShowSafety(lastMessageType: string): boolean {
  return lastMessageType === "CaseReceivedMessage" || lastMessageType === "ServiceMessage";
}
