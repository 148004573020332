import { FunctionComponent } from "react";
import { InformationFrComa } from "../../../../components/country-information/InformationFrComa";
import { InformationFrZama } from "../../../../components/country-information/InformationFrZama";

export type Market = "kzama" | "gzama" | "coma";
type Country = "FR";

type InformationPage = {
  country: Country;
  market: Market[];
  component: FunctionComponent;
};

export const availableInformationPages: InformationPage[] = [
  { country: "FR", market: ["kzama", "gzama"], component: InformationFrZama },
  { country: "FR", market: ["coma"], component: InformationFrComa },
];
