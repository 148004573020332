import { FunctionComponent } from "react";
import { ThemeProvider } from "styled-components";
import { Fonts } from "@anwb/theme";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import EmptyToken from "../routes/emptyToken";
import { StatusUpdatesDefaultTheme } from "./theme";
import { RedirectWithUtm } from "./redirectWithUtm";
import { NotFoundErrorState } from "./content-state/states/error";
import Safety from "../routes/home/pages/safety";
import Information from "../routes/home/pages/information";
import BreakdownLocation from "../routes/home/pages/breakdown-location";
import WaarIsDeWegenwacht from "../routes/home/pages/waar-is-de-wegenwacht";
import Layout from "../routes/home/layout";
import StatusUpdates from "../routes/home/pages/status-updates";
import Cancel from "../routes/home/pages/cancel";
import { Scenario } from "../routes/home/pages/scenario";
import { Status } from "../routes/home/pages/status";
import { OnPageChange } from "../monitoring/OnPageChange";
import FAQ from "./phv-faq/FAQ";
import { APIProvider } from "@vis.gl/react-google-maps";

const queryClient = new QueryClient();
const gtmParams = {
  id: window.statusUpdatesSettings.gtmId,
};

export enum STATUS_PATH {
  INTERNATIONAL_TRANSPORT = "transport-internationaal",
  FOREIGN_ROADSIDE_ASSISTANCE = "updates-hulpdienst",
}

const App: FunctionComponent = () => {
  return (
    <GTMProvider state={gtmParams}>
      <ThemeProvider theme={StatusUpdatesDefaultTheme}>
        <Fonts />
        <APIProvider apiKey={window.statusUpdatesSettings.googleMapsKey}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <OnPageChange />
              <Routes>
                <Route path="phv-faq" element={<FAQ />} />
                <Route index element={<EmptyToken />} />
                <Route path=":token" element={<Layout />}>
                  <Route index element={<StatusUpdates />} />
                  <Route path="veiligheid" element={<Safety />} />
                  <Route path="informatie" element={<Information />} />
                  <Route path="pechlocatie" element={<BreakdownLocation />} />
                  <Route
                    path={"waar-is-de-wegenwacht/:identifier"}
                    element={<WaarIsDeWegenwacht />}
                  />
                  <Route path="scenario/:id/*" element={<Scenario />} />
                  <Route path="status/:statusType/:id" element={<Status />} />
                  <Route path="annuleren" element={<Cancel />} />
                </Route>
                <Route path=":source/:token" element={<RedirectWithUtm />} />
                <Route path="*" element={<NotFoundErrorState />} />
              </Routes>
            </BrowserRouter>
          </QueryClientProvider>
        </APIProvider>
      </ThemeProvider>
    </GTMProvider>
  );
};

export default App;
