import { DateTime } from "luxon";

export const holidays = [
  DateTime.fromObject({ day: 27, month: 4, year: 2024 }).toMillis(),
  DateTime.fromObject({ day: 9, month: 5, year: 2024 }).toMillis(),
  DateTime.fromObject({ day: 19, month: 5, year: 2024 }).toMillis(),
  DateTime.fromObject({ day: 20, month: 5, year: 2024 }).toMillis(),
  DateTime.fromObject({ day: 25, month: 12, year: 2024 }).toMillis(),
  DateTime.fromObject({ day: 26, month: 12, year: 2024 }).toMillis(),
  DateTime.fromObject({ day: 1, month: 1, year: 2025 }).toMillis(),
  DateTime.fromObject({ day: 18, month: 4, year: 2025 }).toMillis(),
  DateTime.fromObject({ day: 20, month: 4, year: 2025 }).toMillis(),
  DateTime.fromObject({ day: 21, month: 4, year: 2025 }).toMillis(),
  DateTime.fromObject({ day: 26, month: 4, year: 2025 }).toMillis(),
  DateTime.fromObject({ day: 29, month: 5, year: 2025 }).toMillis(),
  DateTime.fromObject({ day: 8, month: 6, year: 2025 }).toMillis(),
  DateTime.fromObject({ day: 9, month: 6, year: 2025 }).toMillis(),
  DateTime.fromObject({ day: 25, month: 12, year: 2025 }).toMillis(),
  DateTime.fromObject({ day: 26, month: 12, year: 2025 }).toMillis(),
  DateTime.fromObject({ day: 1, month: 1, year: 2026 }).toMillis(),
  DateTime.fromObject({ day: 3, month: 4, year: 2026 }).toMillis(),
  DateTime.fromObject({ day: 5, month: 4, year: 2026 }).toMillis(),
  DateTime.fromObject({ day: 6, month: 4, year: 2026 }).toMillis(),
  DateTime.fromObject({ day: 27, month: 4, year: 2026 }).toMillis(),
  DateTime.fromObject({ day: 14, month: 5, year: 2026 }).toMillis(),
  DateTime.fromObject({ day: 24, month: 5, year: 2026 }).toMillis(),
  DateTime.fromObject({ day: 25, month: 5, year: 2026 }).toMillis(),
  DateTime.fromObject({ day: 25, month: 12, year: 2026 }).toMillis(),
  DateTime.fromObject({ day: 26, month: 12, year: 2026 }).toMillis(),
  DateTime.fromObject({ day: 1, month: 1, year: 2027 }).toMillis(),
  DateTime.fromObject({ day: 26, month: 3, year: 2027 }).toMillis(),
  DateTime.fromObject({ day: 28, month: 3, year: 2027 }).toMillis(),
  DateTime.fromObject({ day: 29, month: 3, year: 2027 }).toMillis(),
  DateTime.fromObject({ day: 27, month: 4, year: 2027 }).toMillis(),
  DateTime.fromObject({ day: 6, month: 5, year: 2027 }).toMillis(),
  DateTime.fromObject({ day: 16, month: 5, year: 2027 }).toMillis(),
  DateTime.fromObject({ day: 17, month: 5, year: 2027 }).toMillis(),
  DateTime.fromObject({ day: 25, month: 12, year: 2027 }).toMillis(),
  DateTime.fromObject({ day: 26, month: 12, year: 2027 }).toMillis(),
  DateTime.fromObject({ day: 1, month: 1, year: 2028 }).toMillis(),
  DateTime.fromObject({ day: 14, month: 4, year: 2028 }).toMillis(),
  DateTime.fromObject({ day: 16, month: 4, year: 2028 }).toMillis(),
  DateTime.fromObject({ day: 17, month: 4, year: 2028 }).toMillis(),
  DateTime.fromObject({ day: 27, month: 4, year: 2028 }).toMillis(),
  DateTime.fromObject({ day: 25, month: 5, year: 2028 }).toMillis(),
  DateTime.fromObject({ day: 4, month: 6, year: 2028 }).toMillis(),
  DateTime.fromObject({ day: 5, month: 6, year: 2028 }).toMillis(),
  DateTime.fromObject({ day: 25, month: 12, year: 2028 }).toMillis(),
  DateTime.fromObject({ day: 26, month: 12, year: 2028 }).toMillis(),
  DateTime.fromObject({ day: 1, month: 1, year: 2029 }).toMillis(),
  DateTime.fromObject({ day: 30, month: 3, year: 2029 }).toMillis(),
  DateTime.fromObject({ day: 1, month: 4, year: 2029 }).toMillis(),
  DateTime.fromObject({ day: 2, month: 4, year: 2029 }).toMillis(),
  DateTime.fromObject({ day: 27, month: 4, year: 2029 }).toMillis(),
  DateTime.fromObject({ day: 10, month: 5, year: 2029 }).toMillis(),
  DateTime.fromObject({ day: 20, month: 5, year: 2029 }).toMillis(),
  DateTime.fromObject({ day: 21, month: 5, year: 2029 }).toMillis(),
  DateTime.fromObject({ day: 25, month: 12, year: 2029 }).toMillis(),
  DateTime.fromObject({ day: 26, month: 12, year: 2029 }).toMillis(),
];
