import { ButtonSecondary, ButtonTertiary } from "@anwb/button";
import { DateInput, FieldLayout, Form, FormField, Input, Label, Select } from "@anwb/forms";
import Panel from "@anwb/panel";
import Typography from "@anwb/typography";
import { useLinkBuilder } from "@status-updates/frontend/src/components/link";
import { useStateMachine } from "little-state-machine";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { CountryList } from "../components/CountryList";
import { StyledFooter } from "../styles/Footer";
import { StyledForm } from "../styles/Form";
import { StyledHeader } from "../styles/Header";
import { FormBreadcrumb } from "../../../../../../../../components/bread-crumb/ReturnToStatusUpdatesBreadCrumb";
import { updateTravelInformation } from "../form-store";
import { TravelFormProps, TravelInfoForm } from "../TravelInformationForm";
import { HeaderContent } from "../components/HeaderContent";
import { createGATravelInformationEvent } from "../util";
import { useGoogleTagManager } from "../../../../../../../../hooks/useGoogleTagManager";
import { travelInformationData } from "../../../../../../../../features/api/generated/Backend";

const travelTypeOptions: {
  value: NonNullable<NonNullable<travelInformationData>["travelInformation"]>["travelType"];
  label: string;
  id: string;
}[] = [
  {
    value: "HEENREIS",
    id: "heenreis",
    label: "Ik ben op heenreis",
  },
  {
    value: "TERUGREIS",
    id: "terugreis",
    label: "Ik ben op terugreis",
  },
  {
    value: "RONDREIS",
    id: "rondreis",
    label: "Ik ben op rondreis",
  },
  {
    value: "PLAATS_VAN_BESTEMMING",
    id: "plaats-van-bestemming",
    label: "Ik ben op mijn bestemming",
  },
];
export const TravelInformationFormFirst = (props: TravelFormProps) => {
  const {
    actions,
    state: { travelInformationForm: formData },
  } = useStateMachine({ updateTravelInformation });
  const link = useLinkBuilder("/");
  const {
    register,
    unregister,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Partial<TravelInfoForm>>({
    defaultValues: {
      travelInformation: {
        ...formData?.travelInformation,
      },
    },
    shouldUnregister: true,
  });

  const sendEventToGtm = useGoogleTagManager();
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<Partial<TravelInfoForm>> = (data) => {
    if (data.travelInformation?.comment === "") {
      data.travelInformation.comment = undefined;
    }
    actions.updateTravelInformation(data);

    const valid =
      (Object.keys(errors).length === 0 && formData?.travelInformation?.journeyStart) || false;
    sendEventToGtm(
      createGATravelInformationEvent(
        "verder",
        "Kun je ons wat vertellen over je reisplanning?",
        valid ? "valid" : "invalid"
      )
    );

    navigate("../wie-en-wat");
  };

  const [travelType, returnsAtBreakdownLocation] = watch([
    "travelInformation.travelType",
    "travelInformation.returnsAtBreakdownLocation",
  ]);

  // By unregistering fields useForms stops tracking the field. When submitting, the value is therefore not passed to the state
  // and since these fields are dependent on the travelType this is exactly what we want.
  useEffect(() => {
    if (travelType === "RONDREIS") {
      register("travelInformation.returnsAtBreakdownLocation");
      unregister("travelInformation.countryOfDestination");
    } else {
      register("travelInformation.countryOfDestination");
      unregister("travelInformation.returnsAtBreakdownLocation");
      unregister("travelInformation.returnsAtBreakdownLocationDate");
    }
  }, [travelType]);

  useEffect(() => {
    if (returnsAtBreakdownLocation === "true") {
      register("travelInformation.returnsAtBreakdownLocationDate");
    } else {
      unregister("travelInformation.returnsAtBreakdownLocationDate");
    }
  }, [returnsAtBreakdownLocation]);

  const colorContext = "on-light";
  const datePattern = RegExp("^\\d{4}-\\d{2}-\\d{2}$");

  return (
    <>
      <FormBreadcrumb />
      <StyledHeader>
        <HeaderContent isComa={props.isComa} showBodyText percentage={20} />
      </StyledHeader>
      <StyledForm>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Panel>
            <Typography variant="content-title">
              {props.isComa ? "Kun je" : "Kunt u"} ons wat vertellen over{" "}
              {props.isComa ? "je" : "uw"} reisplanning?
            </Typography>
            <FieldLayout>
              <FormField
                size="xl"
                label={
                  <Label
                    text="Vertrek heenreis"
                    reference="journeyStartLabel"
                    colorContext={colorContext}
                    required
                  />
                }
              >
                <DateInput
                  reference="journeyStart"
                  colorContext={colorContext}
                  value={formData?.travelInformation?.journeyStart}
                  forceCustomDatepicker
                  data-e2e-test-type="journey-start"
                  valid={errors?.travelInformation?.journeyStart === undefined}
                  {...register("travelInformation.journeyStart", {
                    min: {
                      value: DateTime.now().minus({ year: 1 }).toString(),
                      message: "Datum moet binnen een jaar vallen.",
                    },
                    max: {
                      value: DateTime.now().plus({ year: 1 }).toString(),
                      message: "Datum moet binnen een jaar vallen.",
                    },
                    pattern: {
                      value: datePattern,
                      message: "Vul een geldige datum in.",
                    },
                  })}
                />
                {errors?.travelInformation?.journeyStart && (
                  <FormField.Error aria-live="assertive">
                    {errors.travelInformation.journeyStart.message}
                  </FormField.Error>
                )}
              </FormField>

              <FormField
                size="xl"
                label={
                  <Label
                    text="Thuiskomst"
                    reference="journeyEndLabel"
                    colorContext={colorContext}
                    required
                  />
                }
              >
                <DateInput
                  reference="journeyEnd"
                  colorContext={colorContext}
                  value={formData?.travelInformation?.journeyEnd}
                  forceCustomDatepicker
                  data-e2e-test-type="journey-end"
                  valid={errors?.travelInformation?.journeyEnd === undefined}
                  {...register("travelInformation.journeyEnd", {
                    required: "Dit veld is verplicht.",
                    min: {
                      value: DateTime.now().minus({ year: 1 }).toString(),
                      message: "Datum moet binnen een jaar vallen.",
                    },
                    max: {
                      value: DateTime.now().plus({ year: 1 }).toString(),
                      message: "Datum moet binnen een jaar vallen.",
                    },
                    pattern: {
                      value: datePattern,
                      message: "Vul een geldige datum in.",
                    },
                  })}
                />
                {errors?.travelInformation?.journeyEnd && (
                  <FormField.Error aria-live="assertive">
                    {errors.travelInformation.journeyEnd.message}
                  </FormField.Error>
                )}
              </FormField>

              <FormField
                size="xl"
                label={
                  <Label
                    text="Op welk punt van je reis ben je nu?"
                    reference="travelTypeLabel"
                    colorContext={colorContext}
                    required
                  />
                }
              >
                <Select
                  reference="travelType"
                  required
                  colorContext={colorContext}
                  placeholder="Maak een keuze"
                  defaultValue={""}
                  valid={errors?.travelInformation?.travelType === undefined}
                  data-testid="travelType"
                  data-e2e-test-type="travel-type"
                  {...register("travelInformation.travelType")}
                >
                  {travelTypeOptions.map(({ value, id, label }) => (
                    <Select.Option value={value} data-testid={id} key={id} data-e2e-test-type={id}>
                      {label}
                    </Select.Option>
                  ))}
                </Select>
                {errors?.travelInformation?.travelType && (
                  <FormField.Error aria-live="assertive">
                    {errors.travelInformation.travelType.message}
                  </FormField.Error>
                )}
              </FormField>

              {travelType === "RONDREIS" && (
                <FormField
                  size="xl"
                  label={
                    <Label
                      text={`${props.isComa ? "Kom je" : "Komt u"} tijdens ${
                        props.isComa ? "je" : "uw"
                      } rondreis nog langs deze locatie?`}
                      reference="returnAtLocationLabel"
                      colorContext={colorContext}
                      required
                    />
                  }
                >
                  <Select
                    reference="returnsAtBreakdownLocation"
                    colorContext={colorContext}
                    placeholder="Maak een keuze"
                    defaultValue=""
                    data-testid="returnAtLocation"
                    data-e2e-test-type="return-at-location"
                    valid={errors?.travelInformation?.returnsAtBreakdownLocation === undefined}
                    {...register("travelInformation.returnsAtBreakdownLocation")}
                  >
                    <Select.Option value="true">Ja</Select.Option>
                    <Select.Option value="false" data-e2e-test-type="false">
                      Nee
                    </Select.Option>
                  </Select>
                  {errors?.travelInformation?.returnsAtBreakdownLocation && (
                    <FormField.Error aria-live="assertive">
                      {errors.travelInformation.returnsAtBreakdownLocation.message}
                    </FormField.Error>
                  )}
                </FormField>
              )}
              {returnsAtBreakdownLocation === "true" && (
                <FormField
                  size="xl"
                  label={
                    <Label
                      text={`Wanneer ${
                        props.isComa ? "kom je" : "komt u"
                      } terug langs deze locatie?`}
                      reference="returnsAtBreakdownLocationDateLabel"
                      required
                      colorContext={colorContext}
                    />
                  }
                >
                  <DateInput
                    reference="returnsAtBreakdownLocationDate"
                    colorContext={colorContext}
                    value={formData?.travelInformation?.returnsAtBreakdownLocationDate}
                    forceCustomDatepicker
                    valid={errors?.travelInformation?.returnsAtBreakdownLocationDate === undefined}
                    {...register("travelInformation.returnsAtBreakdownLocationDate", {
                      min: {
                        value: DateTime.now().minus({ year: 1 }).toString(),
                        message: "Datum moet binnen een jaar vallen.",
                      },
                      max: {
                        value: DateTime.now().plus({ year: 1 }).toString(),
                        message: "Datum moet binnen een jaar vallen.",
                      },
                      pattern: {
                        value: datePattern,
                        message: "Vul een geldige datum in.",
                      },
                    })}
                  />
                  {errors?.travelInformation?.returnsAtBreakdownLocationDate && (
                    <FormField.Error aria-live="assertive">
                      {errors.travelInformation.returnsAtBreakdownLocationDate.message}
                    </FormField.Error>
                  )}
                </FormField>
              )}

              {travelType !== "RONDREIS" && (
                <FormField
                  size="xl"
                  label={
                    <Label
                      text={`Welk land is ${props.isComa ? "je" : "uw"} bestemming?`}
                      reference="countryOfDestinationLabel"
                      colorContext={colorContext}
                      required
                    />
                  }
                >
                  <CountryList
                    name="travelInformation.countryOfDestination"
                    reference="travelInformation.countryOfDestination"
                    colorContext={colorContext}
                    register={register}
                    valid={errors?.travelInformation?.countryOfDestination === undefined}
                  />
                  {errors?.travelInformation?.countryOfDestination && (
                    <FormField.Error aria-live="assertive">
                      {errors.travelInformation.countryOfDestination.message}
                    </FormField.Error>
                  )}
                </FormField>
              )}

              <FormField
                size="xl"
                label={
                  <Label
                    text="Toelichting"
                    reference="commentLabel"
                    colorContext={colorContext}
                    required
                  />
                }
              >
                <Input
                  reference="comment"
                  colorContext={colorContext}
                  placeholder="Typ hier..."
                  {...register("travelInformation.comment", {})}
                />
              </FormField>
            </FieldLayout>
          </Panel>
          <Form.Footer>
            <StyledFooter>
              <ButtonSecondary type="submit" data-e2e-test-type="submit">
                Verder
              </ButtonSecondary>
              <ButtonTertiary
                type="button"
                icon="arrow-left"
                iconPosition="before"
                onClick={() => {
                  createGATravelInformationEvent(
                    "verder",
                    "Kun je ons wat vertellen over je reisplanning?"
                  );
                  navigate(link);
                }}
              >
                Terug
              </ButtonTertiary>
            </StyledFooter>
          </Form.Footer>
        </Form>
      </StyledForm>
    </>
  );
};
