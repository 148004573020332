import { useState } from "react";
import { useParams } from "react-router";

export const SW_REGISTRATION_NOT_FOUND =
  "ServiceWorker could not be found, cannot send PushNotifications without it";

function getWebSubscriptionUrl(): string {
  return `${window.statusUpdatesSettings.apiBaseUrl}/webpush-subscribe`;
}

export async function registerAndSendSubscription(token: string): Promise<void> {
  // navigator is an api for the browser itself, we only create a subscription if it is available in the browser
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.getRegistration();
      const { vapidPublicKey } = window.statusUpdatesSettings;

      if (registration === undefined) {
        throw new Error(
          "ServiceWorker could not be found, cannot send PushNotifications without it"
        );
      }

      // register the push in the browser
      const { keys, endpoint } = (
        await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: vapidPublicKey,
        })
      ).toJSON();

      await fetch(getWebSubscriptionUrl(), {
        headers: {
          "x-anwb-client-id": window.statusUpdatesSettings.apiKey,
        },
        method: "POST",
        body: JSON.stringify({
          auth: keys?.auth,
          key: keys?.p256dh,
          endpoint,
          token,
        }),
      });
    } catch (error) {
      console.error(error);
    }
  }
}

type UsePushNotificationReturn = {
  isSupported: boolean;
  permission: "default" | "granted" | "denied";
  requestPermission: () => void;
};

export function usePushNotification(): UsePushNotificationReturn {
  const { token } = useParams<{ token: string }>();
  if (token === undefined) {
    throw new Error("Could not find token");
  }

  const [permission, setPermission] = useState<"default" | "granted" | "denied">(() => {
    if (!("Notification" in window)) {
      return "default";
    }

    return Notification.permission;
  });

  return {
    isSupported: "Notification" in window && !isiOS(window),
    permission,
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    requestPermission: async () => {
      const givenPermission = await Notification.requestPermission();
      if (givenPermission === "granted") {
        void registerAndSendSubscription(token);
      }
      setPermission(givenPermission);
    },
  };
}

/**
 * iOS is excluded in the web push since it is only experimental and not user friendly at the moment
 */
function isiOS(window: Window): boolean {
  const userAgent = window.navigator.userAgent;
  return (
    userAgent.includes("Mac") || userAgent.includes("iOS") || /iPad|iPhone|iPod/.test(userAgent)
  );
}
