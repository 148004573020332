import styled from "styled-components";

export const StyledHeader = styled.header<{ $needsAnwbStyling: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ theme }) => theme.header.height};
  border-bottom: ${({ theme }) =>
    `${theme.header.borderWidth} solid ${theme.colors.accent.bgMainNavigation}`};
  background-color: ${({ theme }) => theme.colors.blanc.bgSite};
  z-index: 9999;

  section {
    box-sizing: border-box;
    padding: ${({ theme }) => `${theme.spacings.horizontalPadding} 12px 0`};
    display: flex;
    max-width: ${({ theme }) => `${theme.viewportBreakpoint.sm}px`};
    margin: 0 auto;

    .logo {
      flex-grow: 1;
    }

    nav {
      justify-content: flex-end;
      display: flex;
      margin-right: 6px;

      a {
        color: ${({ theme }) => theme.colors.base.iconButton};
        text-decoration: none;

        span.label {
          margin-right: 7px;
        }
      }
    }
  }

  ${({ $needsAnwbStyling }) => !$needsAnwbStyling && "border-color: white"};
`;
