import Icon from "@anwb/icon";
import { IconVariantKey } from "@anwb/icon-utils";
import { FunctionComponent, ReactNode } from "react";
import queryString from "query-string";
import Typography, { BreadcrumbText } from "@anwb/typography";

import { useStatusUpdates } from "../../routes/home/hooks/useStatusUpdates";
import { LinkContainer } from "./styled";
import { useNavigate } from "react-router-dom";
import { ButtonPrimary, ButtonSecondary, ButtonTertiary } from "@anwb/button";

type LinkProps = {
  href: string;
  className?: string;
  icon?: {
    variant: IconVariantKey;
    position: "left" | "right";
  };
  inline?: boolean;
  children: ReactNode;
};

type InlineLinkProps = {
  href: string;
  children: ReactNode;
};

type BreadcrumbProps = {
  href: string;
  children: ReactNode;
};

export const useLinkBuilder = (href: string) => {
  const { token } = useStatusUpdates();
  const link = queryString.pick(
    queryString.stringifyUrl({
      url:
        token.length > 0
          ? href === "/"
            ? `/${token}`
            : `/${token}/${href}`
          : href === "/"
          ? "/"
          : `/${href}`,
      query: queryString.parse(window.location.search),
    }),
    ["isReadOnly"]
  );

  return link;
};

/**
 * Link component which will try to build a safe URL for navigating within Status Updates.
 * It does this by trying to append the `token` to the route, as well as parsing the
 * `isReadOnly` query param if present.
 *
 * @example
 * <Link href='information'>To the information page!</Link>
 *
 * window.location = '/token_if_possible/information[?isReadOnly=true]
 *
 * <Link href='/'>Test</Link>
 *
 * window.location = '/token_if_possible/[?isReadOnly=true]'
 *
 * @returns
 */
export const Link: FunctionComponent<LinkProps> = ({
  href,
  icon,
  className,
  inline = false,
  children,
}) => {
  const link = useLinkBuilder(href);

  return (
    <LinkContainer $inline={inline} className={className} to={link}>
      {icon && <Icon variant={icon.variant} className={`link-icon link-icon--${icon.position}`} />}
      <Typography variant="button-link" className="link-text">
        {children}
      </Typography>
    </LinkContainer>
  );
};

export const BreadCrumbLink: FunctionComponent<BreadcrumbProps> = ({ href, children }) => {
  const link = useLinkBuilder(href);

  return <BreadcrumbText href={link}>{children}</BreadcrumbText>;
};

export const InlineLink: FunctionComponent<InlineLinkProps> = ({ href, children }) => {
  const link = useLinkBuilder(href);

  return (
    <Typography variant="link-text" href={link}>
      {children}
    </Typography>
  );
};

export const ButtonLink = ({
  container: Container,
  onClick,
  href,
  children,
}: {
  container: typeof ButtonPrimary | typeof ButtonSecondary | typeof ButtonTertiary;
  onClick?: () => void;
  href: string;
  children: ReactNode;
}) => {
  const link = useLinkBuilder(href);
  const navigate = useNavigate();

  function onClickHandler() {
    if (onClick) {
      onClick();
    }
    navigate(link);
  }

  return (
    <Container icon="arrow-right" onClick={() => onClickHandler()}>
      {children}
    </Container>
  );
};
