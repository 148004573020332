import Typography from "@anwb/typography";
import { DateTime } from "luxon";
import {
  RepairNotYetFinishedMessageDetails,
  TypedStatusUpdateMessage,
} from "@status-updates/types";
import { FrontendState, StateMetadata } from "../frontend-state";

export class RepairNotYetFinishedState extends FrontendState<RepairNotYetFinishedMessageDetails> {
  constructor(
    message: TypedStatusUpdateMessage<RepairNotYetFinishedMessageDetails>,
    metadata: StateMetadata
  ) {
    super(message, metadata, {
      illustration: "auto-op-brug",
      title: message.messageDetails.isDelayedForParts
        ? "De reparatie is vertraagd"
        : "De reparatie is nog niet klaar",
    });
  }

  renderTimelineItems() {
    const feedback = this.renderFeedback();
    if (this.message.messageDetails.isDelayedForParts) {
      return this.getRepairPartsDelayedUpdate(
        this.message,
        "De reparatie is nog niet klaar",
        feedback
      );
    }
    return this.getRepairNotyetFinishedUpdate(this.message, this.options.title, feedback);
  }

  getRepairNotyetFinishedUpdate(
    message: TypedStatusUpdateMessage<RepairNotYetFinishedMessageDetails>,
    title: string,
    feedback: JSX.Element | null
  ) {
    return [
      {
        timestamp: DateTime.fromISO(message.created),
        title,
        content: (
          <Typography data-e2e-test-type="status-updates-repair-not-yet-finished" tagName="div">
            <p>Wij hebben contact gehad met de garage en deze is nog bezig met de reparatie.</p>
            <p>
              Wij nemen {message.messageDetails.timeFragment} opnieuw contact op met de garage.
              Als wij meer informatie hebben nemen wij contact met{" "}
              {this.getInformalOrFormal("je", "u")} op.
            </p>
          </Typography>
        ),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        feedback,
      },
    ];
  }

  getRepairPartsDelayedUpdate(
    message: TypedStatusUpdateMessage<RepairNotYetFinishedMessageDetails>,
    title: string,
    feedback: JSX.Element | null
  ) {
    return [
      {
        timestamp: DateTime.fromISO(message.created),
        title,
        content: (
          <Typography data-e2e-test-type="status-updates-repair-parts-delayed" tagName="div">
            <p>
              De reparatie van {this.getInformalOrFormal("jouw", "uw")} voertuig is vertraagd. De
              benodigde onderdelen zijn nog niet aangekomen bij de garage.
            </p>
            <p>Wij verwachten dat de onderdelen {message.messageDetails.timeFragment} aankomen.</p>
            <p>
              Wij blijven de reparatie volgen en houden {this.getInformalOrFormal("je", "u")} op de
              hoogte.
            </p>
          </Typography>
        ),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        feedback,
      },
    ];
  }
}
