import { PageTitle } from "@anwb/typography";
import styled from "styled-components";

export const StyledInformation = styled.section`
  .safety-information {
    margin: 0 auto;
    padding: 5px 12px;
    max-width: ${({ theme }) => `${theme.viewportBreakpoint.sm}px`};
  }

  ${PageTitle} {
    padding-top: 2rem;
  }
`;
