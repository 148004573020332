/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.
const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export function register() {
  if ("serviceWorker" in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(window.location.toString());
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebookincubator/create-react-app/issues/2374
      return;
    }

    window.addEventListener("load", () => {
      const swUrl = `/service-worker.js`;

      Promise.resolve()
        .then(async () => {
          if (!isLocalhost) {
            // Is not local host. Just register service worker
            await registerValidSW(swUrl);
          } else {
            // This is running on localhost. Lets check if a service worker still exists or not.
            await checkValidServiceWorker(swUrl);
          }
        })
        .catch((error) => {
          // Handle any errors that might occur during the service worker registration
          console.error("An error occurred:", error);
        });
    });
  }
}

async function registerValidSW(swUrl: string) {
  try {
    await navigator.serviceWorker.register(swUrl).then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing!;
        installingWorker.onstatechange = () => {
          if (installingWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              // At this point, the old content will have been purged and
              // the fresh content will have been added to the cache.
              // It's the perfect time to display a "New content is
              // available; please refresh." message in your web app.
              console.log("New content is available; please refresh.");
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log("Content is cached for offline use.");
            }
          }
        };
      };
    });
  } catch (error) {
    console.error("Error during service worker registration:", error);
  }
}

async function checkValidServiceWorker(swUrl: string) {
  try {
    await fetch(swUrl).then(async (response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      if (
        response.status === 404 ||
        response.headers.get("content-type")!.indexOf("javascript") === -1
      ) {
        // No service worker found. Probably a different app. Reload the page.
        await navigator.serviceWorker.ready.then(async (registration) => {
          await registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        await registerValidSW(swUrl);
      }
    });
    // Check if the service worker can be found. If it can't reload the page.
  } catch {
    console.error("No internet connection found. App is running in offline mode.");
  }
}

export async function unregister() {
  if ("serviceWorker" in navigator) {
    await navigator.serviceWorker.ready.then(async (registration) => {
      await registration.unregister();
    });
  }
}
