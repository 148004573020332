import { ProviderAddress } from "@status-updates/types";

// Specific formatting, based on https://github.com/OpenCageData/address-formatting/blob/2736c198168d217cf6622bd625c43500876855f6/conf/countries/worldwide.yaml
const formatGeneric1 = ({
  houseNumber = "",
  postalCode = "",
  street = "",
  city = "",
}: ProviderAddress) => [`${street} ${houseNumber}`, `${postalCode} ${city}`];

const formatGeneric3 = ({
  houseNumber = "",
  postalCode = "",
  street = "",
  city = "",
}: ProviderAddress) => [`${houseNumber} ${street}`, `${postalCode} ${city}`];

const formatGeneric15 = ({
  houseNumber = "",
  postalCode = "",
  street = "",
  city = "",
}: ProviderAddress) => [`${street}, ${houseNumber}`, `${postalCode} ${city}`];

const formatGeneric16 = ({ houseNumber = "", street = "", city = "" }: ProviderAddress) => [
  `${houseNumber} ${street}`,
  city,
];

const formatGeneric23 = ({
  houseNumber = "",
  postalCode = "",
  street = "",
  city = "",
}: ProviderAddress) => [`${houseNumber} ${street}`, city, postalCode];

const formatGeneric24 = ({
  houseNumber = "",
  postalCode = "",
  street = "",
  city = "",
}: ProviderAddress) => [city, `${houseNumber} ${street}`, postalCode];

export const addressFormats: Record<string, (address: ProviderAddress) => string[]> = {
  AT: formatGeneric1,
  BE: formatGeneric1,
  CH: formatGeneric1,
  DE: formatGeneric1,
  ES: formatGeneric15,
  FR: formatGeneric3,
  GB: formatGeneric23,
  IT: formatGeneric1,
  NL: formatGeneric1,
  AL: formatGeneric15,
  AD: formatGeneric3,
  BA: formatGeneric1,
  HR: formatGeneric1,
  CZ: formatGeneric1,
  DK: formatGeneric1,
  GI: formatGeneric16,
  HU: formatGeneric24,
  XK: formatGeneric1,
  LI: formatGeneric1,
  LU: formatGeneric3,
  MT: formatGeneric23,
  ME: formatGeneric1,
  MK: formatGeneric1,
  NO: formatGeneric1,
  PL: formatGeneric1,
  MC: formatGeneric3,
  SM: formatGeneric1,
  RS: formatGeneric1,
  SK: formatGeneric1,
  SI: formatGeneric1,
  SE: formatGeneric1,
  VA: formatGeneric1,
};
