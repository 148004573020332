import Typography from "@anwb/typography";
import { DateTime } from "luxon";
import {
  ReplacementVehiclePickUpMessageDetails,
  TypedStatusUpdateMessage,
} from "@status-updates/types";
import { determineRentalOpeningHourInformation } from "../../../src/utils/countrySpecificText";
import { FrontendState, StateMetadata } from "../frontend-state";
import {
  renderBeOnTime,
  renderInformationLink,
  renderPickUpInfo,
  renderPickupList,
} from "./renderReplacementVehicleInfo";

export class ReplacementVehiclePickUpState extends FrontendState<ReplacementVehiclePickUpMessageDetails> {
  constructor(
    message: TypedStatusUpdateMessage<ReplacementVehiclePickUpMessageDetails>,
    metadata: StateMetadata
  ) {
    super(message, metadata, {
      illustration: "huurauto-sleutels",
      title: "We hebben een huurauto gereserveerd",
    });
  }

  renderTimelineItems() {
    const handInDeadline = DateTime.fromISO(this.message.messageDetails.handInDeadline, {
      locale: "nl-NL",
    });
    const formattedReturnDate = handInDeadline.toFormat("d MMMM");
    const formattedReturnTime = handInDeadline.toLocaleString(DateTime.TIME_24_SIMPLE);

    const openingHourInformation = determineRentalOpeningHourInformation(
      this.message.metadata?.breakdownLocationCountryName
    );

    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: this.options.title,
        content: (
          <Typography data-e2e-test-type="status-updates-replacement-vehicle-pickup" tagName="div">
            {renderPickupList()}
            {renderInformationLink(this.metadata.contractMarket === "coma")}
            {renderPickUpInfo(
              {
                name: this.message.messageDetails.rentalCompany.name,
                address: this.message.messageDetails.rentalCompany.address,
              },
              this.message.messageDetails.pickUpTimestamp,
              false
            )}
            {renderBeOnTime(this.metadata.contractMarket === "coma")}
            {this.metadata.contractMarket === "coma" && (
              <p>
                Vervoer naar het ophaalpunt regel je zelf, tenzij anders afgesproken. Kosten voor
                openbaar vervoer (2e klas) naar het ophaalpunt worden vergoed. Bewaar je
                betaalbewijzen goed! Meer informatie over declareren vind je via deze{" "}
                <Typography
                  variant="link-text"
                  href="https://www.anwb.nl/wegenwacht/service/declareren"
                >
                  link
                </Typography>
                .{" "}
              </p>
            )}
            <p>
              <strong>Inleveren</strong>
              <br />
              {formattedReturnDate} uiterlijk om {formattedReturnTime} uur
              <br />
              Locatie volgt in een latere update.
            </p>
            <p>{openingHourInformation}</p>
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
