import { DefaultDetails, TypedStatusUpdateMessage } from "@status-updates/types";
import Typography from "@anwb/typography";
import { DateTime } from "luxon";
import { FrontendState, StateMetadata } from "../frontend-state";
import { MeldingAnnuleren } from "./melding-annuleren";

export class WegenwachtBusyInArea extends FrontendState<DefaultDetails> {
  constructor(message: TypedStatusUpdateMessage<DefaultDetails>, metadata: StateMetadata) {
    super(message, metadata, {
      illustration: "alarmcentrale",
      title: `Het is druk in ${FrontendState.getInformalOrFormal(
        "jouw",
        "uw",
        metadata.contractMarket
      )} regio`,
    });
  }

  renderTimelineItems() {
    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: this.options.title,
        content: (
          <Typography
            data-e2e-test-type="status-updates-wegenwacht-busy-in-area-message"
            tagName="div"
          >
            <p>
              Het is vandaag drukker dan verwacht. We doen er alles aan om{" "}
              {this.getInformalOrFormal("je", "u")} zo snel mogelijk te helpen.
              <br />
              <br />
              Zodra er meer nieuws is, {this.getInformalOrFormal("krijg je", "krijgt u")} een nieuwe
              update. Dit kan even duren.
            </p>
            {this.metadata.showDigitalCancelButton && (
              <MeldingAnnuleren
                contractMarket={this.metadata.contractMarket}
                assistancePhonenumber={this.metadata.assistancePhonenumber}
              />
            )}
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
