import {
  ProviderAddress,
  ServiceMessageDetails,
  TypedStatusUpdateMessage,
} from "@status-updates/types";
import { DateTime } from "luxon";
import Typography from "@anwb/typography";
import { FrontendState, StateMetadata } from "../frontend-state";
import { serviceProviderText } from "../../utils/serviceProviderText";

export class DeploymentServiceState extends FrontendState<ServiceMessageDetails> {
  constructor(message: TypedStatusUpdateMessage<ServiceMessageDetails>, metadata: StateMetadata) {
    super(message, metadata, {
      illustration: "sleepwagen-onderweg",
      title: `We sturen een hulpdienst naar ${FrontendState.getInformalOrFormal(
        "jou",
        "u",
        metadata.contractMarket
      )} toe`,
    });
  }

  renderTimelineItems() {
    const { messageDetails } = this.message;

    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: this.options.title,
        content: this.formatText(
          messageDetails.provider,
          messageDetails.isDelegatedService,
          messageDetails.averageWaitingTimeText,
          messageDetails.eta?.roundedEstimateInMinutes,
          this.metadata.countryName
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }

  formatText(
    providerAddress: ProviderAddress,
    isDelegatedService: boolean,
    averageWaitingTimeText: string,
    roundedEta?: number,
    countryName?: string
  ): JSX.Element {
    return (
      <Typography>
        {isDelegatedService || !roundedEta
          ? this.createAverageWaitingTimeFragment(averageWaitingTimeText, countryName!)
          : this.createStandardFragment(roundedEta)}{" "}
        Blijf in de buurt van {this.getInformalOrFormal("je", "uw")} voertuig en houd{" "}
        {this.getInformalOrFormal("je", "uw")} telefoon bij de hand. <br />
        <br /> De hulpdienst of sleepwagen kan kleine (nood-)oplossingen ter plaatse uitvoeren. Als
        de hulpdienst {this.getInformalOrFormal("je", "u")} niet ter plaatse kan helpen, dan brengen
        zij {this.getInformalOrFormal("jouw", "uw")} voertuig naar een garage. Als het nodig is, dan
        kan {this.getInformalOrFormal("je", "u")} in overleg met de hulpdienst meerijden.
        <br />
        <br />
        {!isDelegatedService && serviceProviderText(providerAddress, providerAddress.providerName)}
      </Typography>
    );
  }

  createStandardFragment(roundedEta: number): JSX.Element {
    return (
      <a data-e2e-test-type="status-updates-service-message">
        Deze verwacht over ongeveer {roundedEta} minuten bij {this.getInformalOrFormal("je", "u")}{" "}
        te zijn. De hulpdienst kan iets te vroeg of te laat zijn.
      </a>
    );
  }

  createAverageWaitingTimeFragment(
    averageWaitingTimeText: string,
    countryName: string
  ): JSX.Element {
    return (
      <a data-e2e-test-type="status-updates-service-message-average-waiting-time">
        De gemiddelde wachttijd op de hulpdienst in {countryName} is op dit moment{" "}
        {averageWaitingTimeText}.
      </a>
    );
  }
}
