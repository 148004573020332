import { FunctionComponent, ReactNode, useState } from "react";
import { CaseReceivedMessageDetails, StatusUpdateMessage } from "@status-updates/types";
import styled from "styled-components";

import { StatusUpdatesInformation } from "./information";
import { StatusUpdatesTimeline } from "./timeline/timeline";
import { FrontendState } from "../../states/frontend-state";
import { ActionTiles } from "../action-tiles/ActionTiles";
import { ActionTile } from "../action-tiles/ActionTile";

type Props = {
  caseReceivedMessage?: CaseReceivedMessageDetails;
  states?: FrontendState<StatusUpdateMessage["messageDetails"]>[];
};

const TopSection = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.blanc.bgSite};

  position: sticky;
  top: ${({ theme }) => `calc(${theme.header.height} + ${theme.header.borderWidth})`};
  z-index: 999;

  position: "sticky";
`;

const StatusUpdates: FunctionComponent<Props> = ({ caseReceivedMessage, states = [] }) => {
  if (caseReceivedMessage === undefined) {
    console.log("No caseReceivedMessage found");
    return null;
  }

  const [actionTilePresent, setActionTilePresent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      <TopSection>
        <StatusUpdatesInformation
          registrationTime={caseReceivedMessage.registrationTime}
          caseNumber={caseReceivedMessage.caseNumber}
        />
      </TopSection>
      <ActionTiles setIsLoading={setIsLoading}>
        {(feedData, formData) => {
          const tiles: ReactNode[] = [...feedData, ...formData].map((actionTile, index) => (
            <ActionTile {...actionTile} key={index} />
          ));

          if (tiles.length > 0) {
            setActionTilePresent(true);
          }

          return tiles;
        }}
      </ActionTiles>
      {!actionTilePresent && !isLoading && states[0]?.renderHeader()}
      <StatusUpdatesTimeline states={states} />
    </>
  );
};

export default StatusUpdates;
