import Typography from "@anwb/typography";
import { DateTime } from "luxon";
import { TaskPostponedMessageDetails, TypedStatusUpdateMessage } from "@status-updates/types";
import { FrontendState, StateMetadata } from "../frontend-state";

export class DiagnosisReadyNoAnswerCustomerState extends FrontendState<TaskPostponedMessageDetails> {
  constructor(
    message: TypedStatusUpdateMessage<TaskPostponedMessageDetails>,
    metadata: StateMetadata
  ) {
    super(message, metadata, {
      illustration: "alarmcentrale",
      title: "De diagnose is bekend",
    });
  }

  renderTimelineItems() {
    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: this.options.title,
        content: (
          <Typography
            data-e2e-test-type="status-updates-diagnosis-ready-no-answer-customer"
            tagName="div"
          >
            Het is ons niet gelukt {this.getInformalOrFormal("je", "u")} te bereiken. Graag
            bespreken we de volgende stappen met {this.getInformalOrFormal("je", "u")}.{" "}
            {this.getInformalOrFormal("Kun je", "Kunt u")} ons terugbellen via de belknop
            rechtsboven?
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
