import Typography from "@anwb/typography";
import { DateTime } from "luxon";
import { LogicxTransportStatusDetails, TypedStatusUpdateMessage } from "@status-updates/types";
import { FrontendState, StateMetadata } from "../frontend-state";
import { calculateWorkDaysDifference } from "../../utils/timestamp";

export class VehicleAlmostAtDestinationState extends FrontendState<LogicxTransportStatusDetails> {
  private readonly vehicleType: string;
  constructor(
    message: TypedStatusUpdateMessage<LogicxTransportStatusDetails>,
    metadata: StateMetadata
  ) {
    super(message, metadata, {
      illustration: "internationaal-transport",
      title: `${FrontendState.getInformalOrFormal("Jouw", "Uw", metadata.contractMarket)} ${
        message.messageDetails.vehicleType ?? "voertuig"
      } is er bijna`,
    });

    this.vehicleType = message.messageDetails.vehicleType;
  }

  renderTimelineItems() {
    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: this.options.title,
        content: (
          <Typography
            data-e2e-test-type="status-updates-vehicle-almost-at-destination"
            tagName="div"
          >
            {this.getInformalOrFormal("Jouw", "Uw")} {this.vehicleType} komt binnen 2 werkdagen op
            de eindbestemming aan.
            <br />
            <br />
            Wij sturen {this.getInformalOrFormal("je", "u")} een bericht zodra{" "}
            {this.getInformalOrFormal("je", "uw")} {this.vehicleType} bij{" "}
            {this.getInformalOrFormal("jouw", "uw")} garage naar keuze afgeleverd wordt.{" "}
            {this.getInformalOrFormal("Heb je", "Heeft u")} vragen over transport? Bel dan onze
            logistieke afdeling op:{" "}
            <Typography variant="link-text" href="tel: +31882695255">
              +31882695255
            </Typography>
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
