/**
 * Short identifier to be used in the URL to avoid long google analytics query parameters
 *
 * The source is a short identifier which is added to the url, which is translated later on to
 * parameters to be used with google analytics. This is done because the number of characters in an sms message
 * is limited.
 *
 * For example:
 *
 * https://mijnpech.info/3/abcdef will translate to https://mijnpech.info/abcdef?utm_source=mijnpech.info&utm_medium=sms&utm_campaign=3-some-campaign
 */
export enum UtmSourceIdentifier {
  FLOW = "0",
  FIRST_SMS = "1",
  RECURRENT_SMS = "2",
  WEB_PUSH = "3",
}
