import Typography from "@anwb/typography";
import { DateTime } from "luxon";
import { ServiceMessageDetails, TypedStatusUpdateMessage } from "@status-updates/types";
import { ButtonTertiary } from "@anwb/button";

import { FrontendState, StateMetadata } from "../frontend-state";
import { ButtonLink } from "../../components/link";
import { determineInformationLinkText } from "../../../src/utils/countrySpecificText";

export class FindReplacementVehicleState extends FrontendState<ServiceMessageDetails> {
  constructor(message: TypedStatusUpdateMessage<ServiceMessageDetails>, metadata: StateMetadata) {
    super(message, metadata, {
      illustration: "alarmcentrale",
      title: "Wij gaan op zoek naar vervangend vervoer",
    });
  }

  renderTimelineItems() {
    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: this.options.title,
        content: (
          <Typography data-e2e-test-type="status-updates-find-replacement-vehicle" tagName="div">
            <p>
              Wij gaan kijken naar mogelijkheden in {this.getInformalOrFormal("jouw", "uw")} regio.
              Het vinden van geschikt vervangend vervoer kan enige tijd duren. Zodra wij meer
              informatie hebben, nemen wij contact met {this.getInformalOrFormal("je", "u")} op.
            </p>
            <p>
              Zorg dat {this.getInformalOrFormal("je", "u")} bereikbaar blijft. Mocht{" "}
              {this.getInformalOrFormal("je", "u")} zelf vervoer gevonden hebben, laat het ons dan
              weten.
            </p>
            <ButtonLink container={ButtonTertiary} href="informatie#vervangend-vervoer">
              {determineInformationLinkText("vervangend vervoer")}
            </ButtonLink>
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
