import { useEffect, type FunctionComponent } from "react";
import { useLocation } from "react-router";
import { useMonitoring } from "./rum-client";

export const OnPageChange: FunctionComponent = () => {
  const { pathname } = useLocation();
  const monitoringClient = useMonitoring();

  useEffect(() => {
    if (monitoringClient !== undefined) {
      // Record the page view to monitor the user' path through the application
      monitoringClient.recordPageView(pathname);
    }
  }, [pathname, monitoringClient]);

  return null;
};
