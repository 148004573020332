import Typography from "@anwb/typography";
import { ButtonTertiary } from "@anwb/button";
import { DateTime } from "luxon";
import { LogicxTransportPlannedDetails, TypedStatusUpdateMessage } from "@status-updates/types";
import { FrontendState, StateMetadata } from "../frontend-state";
import { ButtonLink } from "../../components/link";

export class InternationalTransportPlannedState extends FrontendState<LogicxTransportPlannedDetails> {
  private readonly vehicleType: string;
  constructor(
    message: TypedStatusUpdateMessage<LogicxTransportPlannedDetails>,
    metadata: StateMetadata
  ) {
    super(message, metadata, {
      illustration: "internationaal-transport",
      title: `${FrontendState.getInformalOrFormal("Jouw", "Uw", metadata.contractMarket)} ${
        message.messageDetails.vehicleType ?? "voertuig"
      } wordt ingepland voor transport`,
    });

    this.vehicleType = message.messageDetails.vehicleType ?? "voertuig";
  }

  renderTimelineItems() {
    const created = DateTime.fromISO(this.message.created);
    return [
      {
        timestamp: created,
        title: this.options.title,
        content: (
          <Typography
            data-e2e-test-type="status-updates-international-transport-planned"
            tagName="div"
          >
            Binnen twee dagen kunnen wij {this.getInformalOrFormal("je", "u")} meer informatie geven
            over de verwachte aankomsttijd.
            <br />
            <br />
            We geven {this.getInformalOrFormal("je", "u")} graag wat informatie en tips over het
            transport van {this.getInformalOrFormal("jouw", "uw")} {this.vehicleType}.
            <br />
            <br />
            <ButtonLink href="informatie#voertuig-nederland" container={ButtonTertiary}>
              Informatie en tips
            </ButtonLink>
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
