import Typography from "@anwb/typography";
import { DateTime } from "luxon";
import { DefaultDetails, TypedStatusUpdateMessage } from "@status-updates/types";
import { FrontendState, StateMetadata } from "../frontend-state";

export class NeedHelpState extends FrontendState<DefaultDetails> {
  constructor(message: TypedStatusUpdateMessage<DefaultDetails>, metadata: StateMetadata) {
    super(message, metadata, {
      illustration: "auto-gerepareerd",
      title: `${metadata.contractMarket === "coma" ? "Heb je" : "Heeft u"} nog extra hulp nodig?`,
    });
  }

  renderTimelineItems() {
    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: this.options.title,
        content: (
          <Typography data-e2e-test-type="status-updates-need-help-message" tagName="div">
            Wij hopen dat {this.getInformalOrFormal("je", "uw")} reis verder voorspoedig verloopt.
            Mochten wij nog iets voor {this.getInformalOrFormal("je", "u")} kunnen betekenen, klik
            dan op de belknop rechtsboven.
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
