import { Select, SelectProps } from "@anwb/forms";
import { UseFormRegister } from "react-hook-form";

type Country = {
  name: string;
  code: string;
};

export const supportedCountries: Country[] = [
  {
    name: "Albanië",
    code: "AL",
  },
  {
    name: "Algerije",
    code: "DZ",
  },
  {
    name: "Andorra",
    code: "AD",
  },
  {
    name: "De Azoren",
    code: "PT",
  },
  {
    name: "België",
    code: "BE",
  },
  {
    name: "Bosnië-Herzegovina",
    code: "BA",
  },
  {
    name: "Bulgarije",
    code: "BG",
  },
  {
    name: "De Canarische Eilanden",
    code: "ES",
  },
  {
    name: "Ceuta",
    code: "ES",
  },
  {
    name: "Cyprus",
    code: "CY",
  },
  {
    name: "Denemarken",
    code: "DK",
  },
  {
    name: "Duitsland",
    code: "DE",
  },
  {
    name: "Egypte",
    code: "EG",
  },
  {
    name: "Estland",
    code: "EE",
  },
  {
    name: "Faeröer eilanden",
    code: "FO",
  },
  {
    name: "Finland",
    code: "FI",
  },
  {
    name: "Frankrijk",
    code: "FR",
  },
  {
    name: "Gibraltar",
    code: "GI",
  },
  {
    name: "Griekenland",
    code: "GR",
  },
  {
    name: "Hongarije",
    code: "HU",
  },
  {
    name: "Ierland",
    code: "IE",
  },
  {
    name: "IJsland",
    code: "IS",
  },
  {
    name: "Israël",
    code: "IL",
  },
  {
    name: "Italië",
    code: "IT",
  },
  {
    name: "Kosovo",
    code: "XK",
  },
  {
    name: "Kroatië",
    code: "HR",
  },
  {
    name: "Letland",
    code: "LV",
  },
  {
    name: "Liechtenstein",
    code: "LI",
  },
  {
    name: "Litouwen",
    code: "LT",
  },
  {
    name: "Luxemburg",
    code: "LU",
  },
  {
    name: "Macedonië",
    code: "MK",
  },
  {
    name: "Madeira",
    code: "PT",
  },
  {
    name: "Malta",
    code: "MT",
  },
  {
    name: "Marokko",
    code: "MA",
  },
  {
    name: "Melilla",
    code: "ES",
  },
  {
    name: "Monaco",
    code: "MC",
  },
  {
    name: "Montenegro",
    code: "ME",
  },
  {
    name: "Nederland",
    code: "NL",
  },
  {
    name: "Noorwegen",
    code: "NO",
  },
  {
    name: "Oekraïne",
    code: "UA",
  },
  {
    name: "Oostenrijk",
    code: "AT",
  },
  {
    name: "Polen",
    code: "PL",
  },
  {
    name: "Portugal",
    code: "PT",
  },
  {
    name: "Roemenië",
    code: "RO",
  },
  {
    name: "Rusland",
    code: "RU",
  },
  {
    name: "San Marino",
    code: "SM",
  },
  {
    name: "Servië",
    code: "RS",
  },
  {
    name: "Slovenië",
    code: "SI",
  },
  {
    name: "Slowakije",
    code: "SK",
  },
  {
    name: "Spanje",
    code: "ES",
  },
  {
    name: "Tunesië",
    code: "TN",
  },
  {
    name: "Tsjechische Republiek",
    code: "CZ",
  },
  {
    name: "Turkije",
    code: "TR",
  },
  {
    name: "Vaticaanstad",
    code: "VA",
  },
  {
    name: "Verenigd Koninkrijk",
    code: "GB",
  },
  {
    name: "Zweden",
    code: "SE",
  },
  {
    name: "Zwitserland",
    code: "CH",
  },
];

type Props = SelectProps & {
  register: UseFormRegister<Record<string, unknown>>;
  valid?: boolean;
  selected?: string;
};

export const CountryList: React.FC<Props> = ({ register, ...props }) => {
  return (
    <Select
      required
      placeholder="Maak een keuze"
      defaultValue=""
      {...props}
      {...register(props.reference, {
        value: props.selected,
      })}
    >
      {supportedCountries.map((country, index) => {
        return (
          <Select.Option value={country.code} key={index}>
            {country.name}
          </Select.Option>
        );
      })}
    </Select>
  );
};
