import { FunctionComponent } from "react";
import Typography from "@anwb/typography";
import { Navigation } from "../navigation";
import { InformationPageTitle } from "../information/informationPageTitle";
import { GtmAccordion } from "./GtmAccordion";

export const InformationDefaultComa: FunctionComponent = () => {
  return (
    <>
      <Navigation
        links={[
          { title: "Hulp ter plekke", link: "#hulp-ter-plekke" },
          { title: "Diagnose & Reparatie", link: "#diagnose-reparatie" },
          { title: "Vervangend Vervoer", link: "#vervangend-vervoer" },
          { title: "Transport voertuig naar Nederland", link: "#voertuig-nederland" },
          { title: "Invoeren en Slopen", link: "#invoeren-en-slopen" },
          { title: "Declareren van gemaakte kosten", link: "#declareren-van-gemaakte-kosten" },
          { title: "Algemene voorwaarden", link: "#algemene-voorwaarden" },
        ]}
      />

      <section className="country-information">
        <InformationPageTitle />

        <Typography variant="intro-text" tagName="div" data-testid="default-coma">
          Blijf kalm en zorg voor veiligheid voor jou en je reisgenoten. De ANWB Alarmcentrale doet
          er intussen alles aan om je zo snel mogelijk te helpen. Die hulp is net even anders dan in
          Nederland. Uiteraard houden we je op de hoogte via de overzichtspagina, sms en telefonisch
          contact. De status van jouw pechgeval wordt bij nieuwe informatie automatisch bijgewerkt
          op de overzichtspagina. Hieronder lees je antwoorden op veel gestelde vragen over onze
          hulpverlening.
        </Typography>

        <Typography variant="content-title" id="hulp-ter-plekke">
          Hulp ter plekke
        </Typography>

        <GtmAccordion
          content={[
            {
              title: "Wat gaat er gebeuren met mijn voertuig?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Wij sturen een hulpdienst naar jou toe. Deze probeert jouw voertuig ter plaatse te repareren. Lukt dat niet, dan brengt die je voertuig met een sleepauto naar een geschikte garage in de buurt.
                  <ul>
                    <li>Wij vergoeden deze kosten vanuit je Wegenwacht Europa Service.</li>
                  </ul>
                </Typography>
              ),
            },
            {
              title: "Mijn voertuig kunnen ze niet ter plekke repareren, wat nu?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Kan uw voertuig ter plekke niet gerepareerd worden? Wij brengen je voertuig en de inzittenden naar een geschikte garage in de buurt. Deze garage kan verder weg zijn dan je in Nederland gewend bent. De garage stelt een diagnose. Hiermee kan de ANWB Alarmcentrale de verdere hulp bepalen.
                  <ul>
                    <li>
                      Sta je langs de weg, dan zal de pechhulp je ophalen. Als niet iedereen mee kan, zorgen wij voor extra vervoer.
                    </li>
                    <li>
                      Ben je bijvoorbeeld op de camping en hoef je niet mee te rijden? Dan houdt de ANWB Alarmcentrale contact met de garage.
                    </li>
                    <li>Wij vergoeden deze kosten vanuit de Wegenwacht Europa Service.</li>
                    <li>
                      Geef voertuigsleutels, voertuigpapieren en je telefoonnummer aan de garage. Ze kunnen dan snel met je voertuig beginnen!
                    </li>
                  </ul>
                </Typography>
              ),
            },
            {
              title: "De garage is al dicht, en nu?",
              content: (
                <Typography variant="body-text" tagName="p">
                  <ul>
                    <li>
                      Het is belangrijk dat je in dat geval de voertuigsleutels, de voertuigpapieren
                      en je telefoonnummer bij de hulpdienst afgeeft.
                    </li>
                    <li>
                      Vraag de sleepdienst advies over een overnachtingsplek en laat hen een taxi voor je bellen. Of zoek zelf via internet naar een beschikbare overnachtingsplek in de omgeving. Bewaar hiervan de betaalbewijzen goed.
                    </li>
                    <li>
                      Onverwachte uitgaven, zoals een overnachting, kun je vaak ter beoordeling bij
                      de reisverzekering indienen.
                    </li>
                    <li>
                      Heb je een{" "}
                      <Typography
                        variant="link-text"
                        href="https://www.anwb.nl/wegenwacht/europa"
                      >
                        Wegenwacht Europa Compleet
                      </Typography>{" "}
                      pakket afgesloten, dan kan je deze kosten tot € 250,- per reisgezelschap bij de ANWB{" "}
                      <Typography
                        variant="link-text"
                        href="https://www.anwb.nl/wegenwacht/service/declareren"
                      >
                        declareren
                      </Typography>
                      .
                    </li>
                  </ul>
                </Typography>
              ),
            },
            {
              title: "Mag mijn voertuig naar een dealer?",
              content: (
                <Typography variant="body-text" tagName="p">
                  <ul>
                    <li>
                      In het hoogseizoen heeft een dealer vaak geen tijd om je te helpen. De dealer
                      geeft voorrang aan zijn eigen klanten.{" "}
                    </li>
                    <li>
                      We brengen jouw voertuig naar een bekende garage in het ANWB partner-netwerk. Zo helpen we je snel weer op weg. We hebben goede ervaringen met de garage en weten wat hun capaciteiten zijn.
                    </li>
                  </ul>
                </Typography>
              ),
            },
            {
              title: "Welke kosten vergoedt de ANWB wel en welke niet?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Wel:
                  <ul>
                    <li>
                      De ANWB Alarmcentrale vergoedt de kosten voor hulpverlening ter plekke. Wij betalen ook de taxikosten van de pechlocatie naar de garage of het depot. Daarnaast regelen we eventueel transport van het voertuig naar een nabijgelegen garage.
                    </li>
                  </ul>
                  Niet:
                  <ul>
                    <li>
                      De kosten van onderdelen, brandstof en olie tijdens de hulpverlening zijn voor eigen rekening. Je betaalt deze ter plekke.
                    </li>
                    <li>
                      Diagnosekosten en eventuele reparatiekosten zijn voor eigen rekening. Je kunt
                      bij alle garages betalen met een bankpas of creditcard.
                    </li>
                    <li>Eventuele tolkosten tijdens het transport zijn voor eigen rekening.</li>
                  </ul>
                  Heb je een{" "}
                  <Typography
                    variant="link-text"
                    href="https://www.anwb.nl/wegenwacht/europa/europaplus"
                  >
                    Wegenwacht Europa Plus
                  </Typography>{" "}
                  pakket? Dan heb je naast bovengenoemde punten ook recht op hulpverlening ter
                  plekke, sleephulp en toezending van onderdelen.
                </Typography>
              ),
            },
            {
              title: "Waar kan ik op deze service rekenen?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Ons dekkingsgebied Europa bestaat uit deze landen:
                  <ul>
                    <li>Albanië</li>
                    <li>Andorra</li>
                    <li>België</li>
                    <li>Bosnië en Herzegovina</li>
                    <li>Bulgarije</li>
                    <li>Cyprus</li>
                    <li>Denemarken</li>
                    <li>Duitsland</li>
                    <li>Estland</li>
                    <li>Finland</li>
                    <li>Frankrijk</li>
                    <li>Gibraltar</li>
                    <li>Griekenland</li>
                    <li>Hongarije</li>
                    <li>Ierland</li>
                    <li>Italië</li>
                    <li>Kosovo</li>
                    <li>Kroatië</li>
                    <li>Letland</li>
                    <li>Liechtenstein</li>
                    <li>Litouwen</li>
                    <li>Luxemburg</li>
                    <li>Macedonië</li>
                    <li>Malta</li>
                    <li>Monaco</li>
                    <li>Montenegro</li>
                    <li>Noorwegen</li>
                    <li>Oostenrijk</li>
                    <li>Polen</li>
                    <li>Portugal (m.u.v. de Azoren en Madeira)</li>
                    <li>Roemenië</li>
                    <li>San Marino</li>
                    <li>Servië</li>
                    <li>Slovenië</li>
                    <li>Slowakije</li>
                    <li>Spanje (m.u.v. de Canarische Eilanden, Ceuta en Melilla)</li>
                    <li>Tsjechische Republiek</li>
                    <li>Turkije tot de Bosporus</li>
                    <li>Vaticaanstad</li>
                    <li>Verenigd Koninkrijk</li>
                    <li>Zweden</li>
                    <li>Zwitserland</li>
                  </ul>
                  <br />
                  Landen met dekking van{" "}
                  <Typography
                    variant="link-text"
                    href="https://www.anwb.nl/wegenwacht/europa/europaplus"
                  >
                    Wegenwacht Europa Plus
                  </Typography>{" "}:
                  <ul>
                    <li>Algerije</li>
                    <li>De Azoren</li>
                    <li>De Canarische Eilanden</li>
                    <li>Ceuta</li>
                    <li>Egypte</li>
                    <li>Faeröer eilanden</li>
                    <li>Israël</li>
                    <li>IJsland</li>
                    <li>Madeira</li>
                    <li>Marokko</li>
                    <li>Melilla</li>
                    <li>Moldavië</li>
                    <li>Oekraïne</li>
                    <li>Rusland (Europese deel)</li>
                    <li>Tunesië</li>
                    <li>Turkije vanaf de Bosporus</li>
                    <li>Wit-Rusland</li>
                  </ul>
                  Let op: In bovenstaande landen bieden wij alleen hulp ter plekke, transport naar
                  een lokale garage en transport naar Nederland. De vervangend vervoer service geldt
                  niet voor bovenstaande landen.
                  <br /> <br />
                  Let op: Je hebt geen dekking in de genoemde landen als het Ministerie van Buitenlandse Zaken een negatief reisadvies geeft vóór of na de vertrekdatum. Bij een pandemie heb je in de gebieden met een rood reisadvies (rode gebieden) beperkt dekking en kunnen wij jou alleen helpen met advies. Voor de gebieden met een oranje reisadvies (oranje gebieden) geldt volledige dekking, tenzij wij je als gevolg van maatregelen van de (lokale) overheden niet of beperkt kunnen helpen.
                </Typography>
              ),
            },
            {
              title: "Hoe verloopt de hulp ’s nachts?",
              content: (
                <Typography variant="body-text" tagName="p">
                  's Nachts repareren garages geen voertuigen. Je voertuig wordt door de hulpdienst veiliggesteld bij een depot. Als je daarna nog hulp nodig hebt, neem dan de volgende dag contact op met de ANWB. We kunnen dan de vervolghulp bespreken. Zorg voor een slaapplek of een plek waar je tot de ochtend rusten kunt. Pas in de ochtend zal de garage mogelijkheden hebben je auto te onderzoeken. Blijkt uit de diagnose dat de reparatie langer dan twee dagen duurt? Dan krijg je vervangend vervoer als dit meeverzekerd is.
                  <ul>
                    <li>
                      Je kunt de sleepdienst advies vragen over een overnachtingsplek en vragen een
                      taxi voor je te bellen. Bewaar hiervan de betaalbewijzen goed.
                    </li>
                    <li>
                      Onverwachte uitgaven, zoals een overnachting, kun je vaak ter beoordeling bij
                      de reisverzekering indienen.
                    </li>
                    <li>
                      Heb je een{" "}
                      <Typography
                        variant="link-text"
                        href="https://www.anwb.nl/wegenwacht/europa"
                      >
                        Wegenwacht Europa Compleet
                      </Typography>{" "}
                      pakket afgesloten, dan kan je deze kosten tot € 250,- per reisgezelschap bij de ANWB{" "}
                      <Typography
                        variant="link-text"
                        href="https://www.anwb.nl/wegenwacht/service/declareren"
                      >
                        declareren
                      </Typography>
                      .
                    </li>
                  </ul>
                </Typography>
              ),
            },
          ]}
        />

        <Typography variant="content-title" id="diagnose-reparatie">
          Diagnose & Reparatie
        </Typography>

        <GtmAccordion
          content={[
            {
              title: "Waarom is het stellen van een diagnose belangrijk?",
              content: (
                <Typography variant="body-text" tagName="p">
                  We willen graag weten wat er aan de hand is met het voertuig en hoelang het gaat
                  duren. Aan de hand hiervan kunnen we bekijken welke hulp je nodig hebt om jouw
                  reis voort te kunnen zetten.
                  <ul>
                    <li>
                      De Wegenwacht Europa Service vergoedt diagnosekosten niet, omdat deze onder de reparatie vallen.
                    </li>
                  </ul>
                </Typography>
              ),
            },
            {
              title: "Wat kan ik doen tijdens het stellen van de diagnose?",
              content: (
                <Typography variant="body-text" tagName="p">
                    Dit kan soms even duren. Daarom kan de garage je vragen of je ergens anders wilt wachten. Misschien kun je in de buurt iets eten of drinken, een bezienswaardigheid bekijken of een wandeling maken. Het is goed mogelijk dat een diagnose niet dezelfde dag volgt. Bereid je voor om eventueel anders terug te keren naar je verblijf of een hotel in de buurt van de garage te boeken. Dit kun je het beste zelf regelen. Mocht de garage nieuws hebben, dan nemen zij contact op met de ANWB Alarmcentrale. Wij houden je op de hoogte.
                  <ul>
                    <li>Houd er rekening mee dat garages tijdens de lunch gesloten kunnen zijn.</li>
                  </ul>
                </Typography>
              ),
            },
            {
              title: "Kan de garage zomaar starten met de reparatie van mijn voertuig?",
              content: (
                <Typography variant="body-text" tagName="p">
                  De garage heeft altijd je toestemming nodig om aan een reparatie te kunnen
                  beginnen. Soms is het probleem tijdens het stellen van de diagnose al opgelost,
                  dan betaal je alleen de diagnosekosten.
                </Typography>
              ),
            },
            {
              title: "Wat moet ik doen als ik de garage niet begrijp?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Bel de ANWB Alarmcentrale via de belknop rechtsboven voor ondersteuning,
                  vertaalhulp of andere vragen.
                </Typography>
              ),
            },
            {
              title: "De reparatiekosten aan mijn voertuig zijn hoog, wat nu?",
              content: (
                <Typography variant="body-text" tagName="p">
                  In het buitenland kunnen reparatiekosten soms duurder zijn dan in Nederland. De garage maakt een prijsopgave inclusief manuren en btw.
                  <br /> <br />
                  Neem contact op met de ANWB Alarmcentrale via de belknop rechtsboven als je twijfelt aan de reparatiekosten.
                </Typography>
              ),
            },
            {
              title: "De garage ziet er anders uit dan ik had verwacht?",
              content: (
                <Typography variant="body-text" tagName="p">
                  In het buitenland zijn niet alle garages zo ingericht als in Nederland. Dit zegt
                  niet direct iets over de kwaliteit van de reparatie. Je krijgt niet standaard een
                  kopje koffie aangeboden zoals je misschien gewend bent.
                </Typography>
              ),
            },
            {
              title: "Ik wil mijn voertuig liever in Nederland laten repareren, kan dat?",
              content: (
                <Typography variant="body-text" tagName="p">
                  De ANWB Alarmcentrale probeert jou zo snel mogelijk met je eigen voertuig weer op
                  weg te helpen. Een snelle reparatie in het buitenland helpt daarbij.
                  <ul>
                    <li>
                      Je bent niet verplicht je voertuig in het buitenland te laten repareren. Als
                      je situatie voldoet aan de voorwaarden van de Wegenwacht Europa Service,
                      kunnen we je voertuig naar Nederland transporteren.
                    </li>
                    <li>
                      De hoogte van reparatiekosten is geen reden voor transport naar Nederland.
                    </li>
                    <li>
                      Als je situatie niet voldoet aan de voorwaarden, is het mogelijk om een
                      aanvraag te doen voor{" "}
                      <Typography variant="link-text" href="https://www.logicx.nl/transport/">
                        {" "}
                        transport op eigen kosten via onze dochteronderneming Logicx
                      </Typography>
                      .
                    </li>
                  </ul>
                </Typography>
              ),
            },
            {
              title: "Vergoedt de ANWB de kosten van de reparatie?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Nee, als je jouw voertuig bij je eigen garage laat repareren, moet je de reparatie zelf betalen. Dat is in het buitenland net zo. De kosten van onderdelen, manuren en brandstof tijdens de hulpverlening zijn voor eigen rekening. Je betaalt deze ter plekke.
                </Typography>
              ),
            },
            {
              title: "Hoe betaal ik de reparatiekosten?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Je betaalt de reparatiekosten met een creditcard of Nederlandse bankpas.
                </Typography>
              ),
            },
          ]}
        />

        <Typography variant="content-title" id="vervangend-vervoer">
          Vervangend vervoer
        </Typography>

        <GtmAccordion
          content={[
            {
              title: "Heb ik recht op vervangend vervoer?",
              content: (
                <Typography variant="body-text" tagName="p">
                  <p>
                    De ANWB Alarmcentrale regelt vervangend vervoer voor jou als:
                    <ul>
                      <li>
                        je{" "}
                        <Typography
                          variant="link-text"
                          href="https://www.anwb.nl/wegenwacht/europa"
                        >
                          Wegenwacht Europa Standaard
                        </Typography>{" "}
                        of{" "}
                        <Typography
                          variant="link-text"
                          href="https://www.anwb.nl/wegenwacht/europa"
                        >
                          Wegenwacht Europa Compleet
                        </Typography>{" "}
                        hebt afgesloten en
                      </li>
                      <li>
                        wij hebben vastgesteld dat we jouw voertuig ter plekke niet binnen 48 uur na
                        je melding weer aan het rijden kunnen krijgen.
                      </li>
                    </ul>
                    Bij een huurauto sluit je een contract af bij een verhuurbedrijf. De
                    voorwaarden die het verhuurbedrijf stelt, zijn voor jou van toepassing
                    (bijvoorbeeld het hebben van een creditcard). Je staat borg met een creditcard
                    die fysiek aanwezig dient te zijn, de huurkosten zijn voor de ANWB. Mocht je
                    geen creditcard hebben, lees dan verderop wat wij voor je kunnen regelen.
                  </p>
                  <p>
                    Let op: Voor sommige bestemmingen binnen of buiten Europa kun je geen vervangende auto regelen vanwege de voorwaarden van lokale verhuurders. Strand je binnen Europa, dan kijkt de ANWB naar een passende oplossing.
                  </p>
                  <p>
                    Heb je pech buiten Europa met{" "}
                    <Typography
                      variant="link-text"
                      href="https://www.anwb.nl/wegenwacht/europa/europaplus"
                    >
                      Wegenwacht Europa Plus
                    </Typography>
                    , dan heb je helaas geen dekking voor vervangend vervoer. Bekijk de voorwaarden{" "}
                    <Typography
                      variant="link-text"
                      href="https://www.anwb.nl/service-en-contact/voorwaarden"
                    >
                      hier
                    </Typography>
                    .
                  </p>
                </Typography>
              ),
            },
            {
              title: "Hoelang duurt het regelen van vervangend vervoer?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Soms duurt het even voordat de monteur kan vaststellen of die je voertuig binnen 48 uur repareert. Als we ontdekken dat we je voertuig niet binnen 48 uur kunnen repareren, regelen we direct vervangend vervoer voor je. We overleggen met jou wat de beste vorm van vervangend vervoer is. Dit hoeft niet altijd een huurauto te zijn, maar kan ook een treinreis, busreis of vlucht zijn.
                  <ul>
                    <li>
                      De ANWB verwacht dat huurauto's deze zomer beperkt beschikbaar zullen zijn in
                      bijna alle populaire vakantielanden in Europa. Tijdens de schoolvakanties
                      kost het meer tijd om passend vervangend vervoer te vinden. Bij alle Europese
                      verhuurbedrijven is er dan veel vraag naar vervangend vervoer.
                    </li>
                    <li>Niet alle huurauto's mag je inleveren in Nederland.</li>
                  </ul>
                </Typography>
              ),
            },
            {
              title: "Heb ik een creditcard nodig?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Ja. De Europese huurauto maatschappijen vragen altijd een creditcard als borg voor de huurauto. Dit is een garantie voor de verhuurder bij eventuele schade, diefstal, fraude of nalatigheid. De borg is verplicht, maar elke aanbieder bepaalt zelf de hoogte van het bedrag. Het kan bijvoorbeeld ook de hoogte van het eigen risico zijn. Je creditcard reserveert deze borg. Je creditcard moet voldoende bestedingslimiet hebben, want je kunt het ingehouden bedrag niet meer uitgeven. De borg wordt namelijk op je creditcard gereserveerd; contant- of pinbetaling is meestal niet mogelijk. Het kan daarom handig zijn om eventueel je bestedingslimiet te verhogen. De ANWB betaalt de huurkosten op basis van jouw{" "}
                  <Typography
                    variant="link-text"
                    href="https://www.anwb.nl/wegenwacht/europa"
                  >
                    Wegenwacht Europa Standaard
                  </Typography>{" "}
                  of{" "}
                  <Typography
                    variant="link-text"
                    href="https://www.anwb.nl/wegenwacht/europa"
                  >
                    Wegenwacht Europa Compleet
                  </Typography>{" "}
                  en schrijft deze niet van je creditcard af.
                  <ul>
                    <li>
                      Lever het vervangend vervoer netjes, volgetankt, zonder schade en op tijd in. Dan schrijven we niets van je creditcard af en geven we het gereserveerde bedrag weer vrij. Dit kan een paar werkdagen duren.
                    </li>
                  </ul>
                </Typography>
              ),
            },
            {
              title: "Ik heb geen creditcard, wat nu?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Het inzetten van een buitenlands vervangend voertuig wordt dan moeilijk. Dit
                  betekent dat we moeten gaan kijken naar andere vormen van vervangend vervoer. Denk
                  hierbij aan een treinreis, busreis of vlucht. Ook kan je je laten ophalen door
                  familie/vrienden uit Nederland. De ANWB Alarmcentrale kan hiervoor een vergoeding
                  met je bespreken.
                </Typography>
              ),
            },
            {
              title: "Moet ik een extra verzekering afsluiten bij de verhuurder?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Nee, je hoeft geen extra verzekeringen af te sluiten zoals een bandenverzekering, het afkopen van eigen risico of een onduidelijke upgrade. De ANWB heeft hierover afspraken gemaakt met de verhuurmaatschappijen. Hierdoor kun jij met een gerust hart ‘nee’ zeggen tegen al die extra opties bij het ophalen van je huurauto.
                </Typography>
              ),
            },
            {
              title: "Huurauto ophalen? Hier moet je op letten!",
              content: (
                <Typography variant="body-text" tagName="p">
                  <strong>Houd alles bij de hand.</strong>
                  <br />
                  Goede voorbereiding is het halve werk en voorkomt kopzorgen aan de balie. Iets waar je niet op zit te wachten als je net een reis achter de rug hebt. Zorg er daarom voor dat je de volgende documenten bij de hand hebt wanneer je je huurauto ophaalt:
                  <ul>
                    <li>Rijbewijs</li>
                    <li>Creditcard op naam van de hoofdbestuurder</li>
                    <li>ID of paspoort</li>
                  </ul>
                  <strong>Maak wanneer mogelijk altijd foto’s van bestaande schades.</strong>
                  <br />
                  Teken de formulieren pas nádat je de auto hebt gezien en je eventuele schades op de papieren hebt aangegeven. Zorg dat er iemand van het verhuurbedrijf bij de controle aanwezig is, zodat je de schades ook kunt aantonen.
                </Typography>
              ),
            },
            {
              title: "Huurauto inleveren? Hier moet je op letten!",
              content: (
                <Typography variant="body-text" tagName="p">
                  <ul>
                    <li>
                      Zorg dat er voldoende brandstof in de auto zit. Dus: zoals je hebt
                      afgesproken. Lever je de auto in zonder volle tank terwijl dit wel de
                      bedoeling was? Dan rekent de verhuurder de brandstofkosten aan je door en die
                      vallen meestal hoger uit.
                    </li>
                    <li>
                      Zorg voor bewijs dat er geen nieuwe schade is. Let er bij het inleveren van de auto op dat een medewerker van het verhuurbedrijf na controle een bewijs tekent. Dan weet je zeker dat er geen nieuwe beschadigingen zijn. Het is daarom niet verstandig om de auto buiten kantooruren terug te brengen. Dan is er vaak niemand van het verhuurbedrijf aanwezig.
                    </li>
                  </ul>
                </Typography>
              ),
            },
          ]}
        />

        <Typography variant="content-title" id="voertuig-nederland">
          Transport voertuig naar Nederland
        </Typography>

        <GtmAccordion
          content={[
            {
              title: "Mag mijn voertuig naar Nederland?",
              content: (
                <Typography variant="body-text" tagName="p">
                  We transporteren je voertuig naar Nederland wanneer je situatie aan alle drie de voorwaarden voldoet:
                  <ul>
                    <li>De monteurs kunnen het voertuig niet repareren voor de terugreis.</li>
                    <li>
                      Het voertuig kan niet binnen 48 uur worden gerepareerd nadat je dit bij de ANWB hebt gemeld. Op een eiland duurt dit 4 werkdagen.
                    </li>
                    <li>
                      De waarde van je voertuig na pech is hoger dan de kosten van het transport
                      naar Nederland.
                    </li>
                  </ul>
                  Als je situatie niet voldoet aan de voorwaarden, is het mogelijk om een aanvraag
                  te doen voor{" "}
                  <Typography variant="link-text" href="https://www.logicx.nl/transport/">
                    {" "}
                    transport op eigen kosten via onze dochteronderneming Logicx
                  </Typography>
                  .
                </Typography>
              ),
            },
            {
              title: "Waar moet ik op letten bij transport naar Nederland?",
              content: (
                <Typography variant="body-text" tagName="p">
                  <ul>
                    <li>Laat geen voedsel en kostbaarheden in je voertuig achter.</li>
                    <li>Laat het kentekenbewijs achter.</li>
                    <li>
                      Laat de sleutels van je voertuig en die van de eventuele dakkoffer of
                      fietsendrager achter.{" "}
                    </li>
                    <li>
                      Als er een dakkoffer op je voertuig zit, plaats deze in het voertuig, wanneer
                      mogelijk.
                    </li>
                    <li>
                      Maak foto’s van je voertuig zodat duidelijk is hoe je deze ter plekke hebt
                      achtergelaten.
                    </li>
                    <li>
                      Betaal alle openstaande diagnose- of reparatiekosten.
                    </li>
                    <li>
                      Informeer je garage in Nederland over de pech en geef aan dat je voertuig bij hen aankomt.
                    </li>
                    <li>
                      Informeer of je garage in Nederland openblijft of juist sluit tijdens de
                      vakantie.
                    </li>
                    <li>
                      Spreek met jouw garage af dat zij je bellen zodra het voertuig bij hen is
                      aangekomen.
                    </li>
                    <li>
                      Heb je vragen over transport? Bel dan onze logistieke afdeling tijdens
                      werkdagen en werktijden op:{" "}
                      <Typography variant="link-text" href="tel: +31882695255">
                        +31882695255
                      </Typography>
                    </li>
                  </ul>
                </Typography>
              ),
            },
            {
              title: "Wanneer is mijn voertuig in Nederland?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Je voertuig wordt met andere gestrande voertuigen verzameld en naar Nederland vervoerd. De transporttijd hangt af van:
                  <ul>
                    <li>
                      De plek waar je voertuig staat, drukte, afstand, weekenden, rijtijdenwet en
                      wetgeving voor internationaal transport.
                    </li>
                    <li>
                      Wij rekenen de transporttijd vanaf het moment dat wij besluiten je voertuig naar Nederland te brengen. Dit gebeurt niet vanaf het moment dat je met pech stond. Je ontvangt informatie over het transport via SMS en/of deze web app.
                    </li>
                  </ul>
                </Typography>
              ),
            },
          ]}
        />

        <Typography variant="content-title" id="invoeren-en-slopen">
          Invoeren en Slopen
        </Typography>

        <GtmAccordion
          content={[
            {
              title: "Mijn voertuig kan niet meer gerepareerd worden, wat nu?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Als de kosten van het transport hoger zijn dan de waarde van je voertuig na de
                  pech, transporteren wij deze niet naar Nederland. De ANWB kan dan voor jou de
                  invoer en sloop in het buitenland regelen. Dit doen we altijd in overleg met de
                  eigenaar van het voertuig. De eigenaar ontvangt daarvoor alle informatie per
                  e-mail.
                  <ul>
                    <li>
                      Als je wilt dat het voertuig toch naar Nederland wordt getransporteerd, dan kun je op eigen kosten een{" "}
                      <Typography variant="link-text" href="https://www.logicx.nl/transport/">
                      aanvraag indienen bij onze dochteronderneming Logicx
                      </Typography>
                      .
                    </li>
                    <li>
                      De ANWB betaalt stallingskosten voor maximaal 30 dagen vanaf de dag van het melden van de pech.
                    </li>
                    <li>
                      De ANWB vergoedt de sloopkosten op basis van je Wegenwacht Europa Service.
                    </li>
                  </ul>
                </Typography>
              ),
            },
            {
              title: "Krijg ik nog geld voor het voertuig?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Bij het invoeren en slopen van een voertuig is er geen restwaarde. Als de garage het voertuig wilt kopen, regel dan zelf de verkoop en de afmelding bij de{" "}
                  <Typography
                    variant="link-text"
                    href="https://www.rdw.nl/particulier/voertuigen/auto/invoeren-exporteren-doorvoeren/auto-exporteren"
                  >
                    RDW
                  </Typography>
                  .
                </Typography>
              ),
            },
            {
              title: "Moet ik de kentekenplaten meenemen?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Als de ANWB de invoer en sloop in het buitenland regelt, moeten de kentekenplaten op het voertuig blijven. Het is wel belangrijk om een foto of kopie van de voor- en achterkant van de kentekencard te maken.
                </Typography>
              ),
            },
            {
              title: "De garage vraagt mij om papieren te tekenen. Mag dat?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Bel de ANWB Alarmcentrale voor advies via de belknop rechtsboven.
                </Typography>
              ),
            },
            {
              title: "Wanneer kan ik de verzekering stopzetten?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Je zet de verzekering pas stop wanneer het voertuig is gesloopt. Zodra het voertuig is gesloopt, sturen ze je het vrijwaringsbewijs naar huis. Met dit bewijs kan je de verzekering stopzetten. Als de procedure is afgerond, ontvangt de eigenaar van het voertuig een eindbericht.
                </Typography>
              ),
            },
          ]}
        />

        <Typography variant="content-title" id="declareren-van-gemaakte-kosten">
          Declareren van gemaakte kosten
        </Typography>

        <GtmAccordion
          content={[
            {
              title: "Welke kosten kan ik declareren?",
              content: (
                <Typography variant="body-text" tagName="p">
                  <p>
                    Op basis van je Wegenwacht Service vergoedt de Alarmcentrale verschillende kosten na overleg en toestemming. Heb je zelf kosten vooraf betaald en heb je volgens je Wegenwacht Service recht op een vergoeding?&nbsp;
                    <Typography
                      variant="link-text"
                      href="https://www.anwb.nl/wegenwacht/service/declareren"
                    >
                      Declareer
                    </Typography>{" "}
                    dan online via mijn ANWB.
                  </p>
                  <p>
                    Bewaar de originele betaalbewijzen in je administratie. Vanwege fraudebeheersing
                    controleren wij soms en kunnen wij je vragen de gedeclareerde betaalbewijzen
                    alsnog aan ons op te sturen.
                  </p>
                </Typography>
              ),
            },
            {
              title: "Hoe kan ik kosten declareren?",
              content: (
                <Typography variant="body-text" tagName="p">
                  <p>
                    Dit regel je eenvoudig en snel zelf online via Mijn ANWB.
                    <ul>
                      <li>
                        <Typography
                          variant="link-text"
                          href="https://www.anwb.nl/wegenwacht/service/declareren"
                        >
                          Declaratie indienen
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          variant="link-text"
                          href="https://www.anwb.nl/inlogaccount-aanmaken"
                        >
                          ANWB-account aanmaken
                        </Typography>
                      </li>
                    </ul>
                  </p>
                  <p>
                    Let op:
                    <ul>
                      <li>
                        Vermeld bij de declaratie jouw casenummer. Je vindt deze op je persoonlijke webpagina en in de eerste sms die je van de ANWB hebt gekregen.
                      </li>
                      <li>
                        Maak goed gebruik van de mogelijkheid om toelichting te geven. Dit versnelt
                        ook de verwerking van jouw declaratie.
                      </li>
                      <li>
                        Houd de betaalbewijzen van kosten die je wil declareren digitaal bij de hand.  Voeg de factuur toe aan de declaratie (geen pinbon).
                      </li>
                    </ul>
                  </p>
                </Typography>
              ),
            },
            {
              title: "Wanneer verwerken jullie mijn declaratie?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Het verwerken van een declaratie duurt gemiddeld 4 tot 8 weken, houd hier dus
                  rekening mee. Voor meer informatie over de status van jouw declaratie kun je
                  contact opnemen met de afdeling Administratie Hulpverlening via het
                  e-mailadres&nbsp;
                  <Typography variant="link-text" href="mailto:hvadministratie@anwb.nl">
                    hvadministratie@anwb.nl
                  </Typography>{" "}
                  of via telefoonnummer
                  <Typography variant="link-text" href="tel:+3188 269 5885">
                    +3188 269 5885
                  </Typography>
                  .
                </Typography>
              ),
            },
            {
              title: "Mag ik declareren zonder factuur? ",
              content: (
                <Typography variant="body-text" tagName="p">
                  Nee, alleen met een factuur.
                  <p>
                    Let op:
                    <ul>
                      <li>Een pinbon is geen factuur.</li>
                      <li>Een reserveringsbevestiging of huurovereenkomst is ook geen factuur.</li>
                    </ul>
                  </p>
                </Typography>
              ),
            },
          ]}
        />

        <Typography variant="content-title" id="algemene-voorwaarden">
          Algemene voorwaarden
        </Typography>

        <GtmAccordion
          content={[
            {
              title: "Algemene voorwaarden Wegenwacht Service",
              content: (
                <Typography variant="body-text" tagName="p">
                  <Typography
                    variant="link-text"
                    href="https://www.anwb.nl/wegenwacht/service/voorwaarden"
                  >
                    Bekijk hier onze algemene voorwaarden.
                  </Typography>
                </Typography>
              ),
            },
          ]}
        />
      </section>
    </>
  );
};
