import styled from "styled-components";

export const StyledFooter = styled.footer`
  background-color: ${({ theme }) => theme.colors.base.bgFooter};
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 999;

  section {
    max-width: ${({ theme }) => theme.viewportBreakpoint.sm};
    margin: 0 auto;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border-width: 0;
    width: 50%;
    color: ${({ theme }) => theme.colors.blanc.textInverse};
    height: 36px;
    margin: 4px 0;
    cursor: pointer;

    span {
      color: ${({ theme }) => theme.colors.blanc.textInverse};
    }

    :first-child {
      border-right: 1px solid ${({ theme }) => theme.colors.blanc.textInverse};
    }
    :only-child {
      width: 100%;
      border-right: none;
    }
  }
`;
