import type { StatusUpdatesMessageType } from "@status-updates/types";
import { getCurrentStatus } from "../../routes/home/utils";

export async function sendFeedback({
  messageType,
  caseNumber,
  isPositiveFeedback,
  explanation,
  marketType,
  country,
  label,
}: {
  messageType: StatusUpdatesMessageType;
  caseNumber: string;
  isPositiveFeedback: boolean;
  explanation?: string;
  marketType?: string;
  country?: string;
  label?: string;
}) {
  const response = await fetch(`${window.statusUpdatesSettings.apiBaseUrl}/feedback`, {
    method: "POST",
    headers: {
      "x-anwb-client-id": window.statusUpdatesSettings.apiKey,
    },
    body: JSON.stringify({
      subject: getCurrentStatus(messageType),
      caseNumber,
      isPositiveFeedback,
      explanation,
      marketType,
      country,
      label,
    }),
  });

  if (!response.ok) {
    throw new Error(`${response.status}`);
  }

  return response.json();
}
