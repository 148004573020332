import styled from "styled-components";

export const StyledCookieBanner = styled.section`
  background-color: ${({ theme }) => theme.colors.base.bgFooter};
  bottom: 0;
  width: 100%;
  left: 0;
  position: sticky;
  bottom: 2.75em;

  section {
    background-color: white;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0.5em;
  }

  .footer-link {
    color: #003d86;
    font-size: 0.8rem;
  }
`;
