import styled from "styled-components";
import { IconContainer } from "@anwb/icon";
import { PanelContainer } from "@anwb/panel";
import { LabelTitle } from "@anwb/typography";

export const IconWegenwacht = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.accent.bgPanelCrossSell};
  height: 44px;
  border-radius: 50%;
  aspect-ratio: 1/1;
  ${IconContainer} {
    font-size: 2rem;
  }
`;

export const IconWithPanel = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  ${PanelContainer} {
    padding: 16px;
    flex-grow: 1;
    background-color: ${({ theme }) => theme.colors.extralight.bgPanelList};

    ${LabelTitle} {
      padding: 0px;
    }
    h6 {
      margin: 0;

      strong {
        color: ${({ theme }) => theme.colors.highlight.textIntro};
      }
    }
  }
`;
