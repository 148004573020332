import { DateTime } from "luxon";

export type DayPart = "middag" | "avond" | "volgende dag";

export function determineDayPartForDiagnosisDeterminedForIsoString(isoDate: string) {
  const parsedDateTime = DateTime.fromISO(isoDate);
  return determineDayPartForDiagnosisDetermined(parsedDateTime);
}

export function determineDayPartForDiagnosisDetermined(dateTime: DateTime): DayPart {
  const dutchDateTime = dateTime.setZone("Europe/Amsterdam");
  const endOfMorning = dutchDateTime.set({
    hour: 11,
    minute: 30,
    second: 0,
    millisecond: 0,
  });
  const endOfAfternoon = dutchDateTime.set({
    hour: 18,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  if (dateTime < endOfMorning) {
    return "middag";
  } else if (dutchDateTime > endOfAfternoon) {
    return "volgende dag";
  } else {
    return "avond";
  }
}
