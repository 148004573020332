import Typography from "@anwb/typography";
import { WegenwachtInTransitMessageDetails } from "@status-updates/types";
import type { ReactNode } from "react";
import React, { useState } from "react";
import type { WegenwachtUpdateArgs } from "../..";
import carMarker from "../../../../../../../public/markers/car.svg";
import wegenwachtMarker from "../../../../../../../public/markers/wegenwacht.svg";
import { GoogleMaps } from "../../../../../../components/google-maps/Map";
import { WegenwachtUpdate } from "../wegenwacht-update";
import { StyledDurationAndDistanceLabel } from "./styled";
import { MeldingAnnuleren } from "../../../../../../states/dispatch/melding-annuleren";

type WegenwachtInTransitProps = WegenwachtUpdateArgs;

export function WegenwachtInTransit({
  message,
  registrationTime,
  isFormal,
  assistancePhoneNumber,
  locationCustomerHighway,
}: WegenwachtInTransitProps) {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { status, etaMinutes, locationCustomer, locationWegenwacht, lastUpdated } =
    message.messageDetails as WegenwachtInTransitMessageDetails;
  const isNearby = isWegenwachtNearby(message.messageDetails as WegenwachtInTransitMessageDetails);
  const wegenwachtCoords = getCoordinatesWegenwacht(
    locationWegenwacht,
    locationCustomer.coordinates,
    message.messageDetails as WegenwachtInTransitMessageDetails
  );

  const locationCustomerLatLng = {
    lat: Number(locationCustomer.coordinates?.latitude),
    lng: Number(locationCustomer.coordinates?.longitude),
  };

  const hasArrived =
    (message.messageDetails as WegenwachtInTransitMessageDetails).status === "gearriveerd";

  return (
    <>
      {isNearby && (
        <GoogleMaps
          center={locationCustomerLatLng}
          directions={
            !hasArrived
              ? {
                  origin: wegenwachtCoords,
                  destination: locationCustomerLatLng,
                }
              : undefined
          }
          markers={[
            {
              marker: {
                draggable: false,
                position: locationCustomerLatLng,
              },
              img: {
                src: carMarker,
                alt: "pechlocatie pin",
              },
            },
            {
              marker: {
                draggable: false,
                position: wegenwachtCoords,
              },
              img: {
                src: wegenwachtMarker,
                alt: "wegenwacht pin",
              },
            },
          ]}
          isFullscreen={isFullscreen}
          toggleFullscreen={() => {
            setIsFullscreen(!isFullscreen);
          }}
        >
          {(fullscreen, distance) =>
            fullscreen &&
            etaMinutes &&
            distance && (
              <StyledDurationAndDistanceLabel>
                <div>
                  <Typography variant="page-title" className="duration-label" tagName="span">
                    {etaMinutes} min
                  </Typography>
                  <Typography variant="page-title" className="distance-label" tagName="span">
                    {distance}
                  </Typography>
                </div>
              </StyledDurationAndDistanceLabel>
            )
          }
        </GoogleMaps>
      )}
      {isFullscreen === false && (
        <WegenwachtUpdate
          panel={{
            text: createWegenwachtText(etaMinutes, status, isFormal, hasArrived),
            icon: hasArrived ? "mechanic" : "car-wegenwacht",
          }}
          lastUpdated={lastUpdated}
          registrationTime={registrationTime}
          breakdownLocation={locationCustomer}
          breakdownLocationHighway={locationCustomerHighway}
          notification={
            isNearby && !hasArrived
              ? {
                  variant: "info",
                  title: "De wegenwacht belt anoniem",
                  children: `Houd er rekening mee dat de wegenwacht ${
                    isFormal ? "u" : "jou"
                  } kan bellen met een
            anoniem telefoonnummer.`,
                }
              : undefined
          }
          additionalText={
            hasArrived
              ? `Houd ${isFormal ? "uw" : "je"} (digitale) ledenpas en/of legitimatie bij de hand.`
              : undefined
          }
          footer={
            !hasArrived && (
              <MeldingAnnuleren
                contractMarket={message?.metadata?.contractMarket}
                assistancePhonenumber={assistancePhoneNumber}
              />
            )
          }
        />
      )}
    </>
  );
}

function createWegenwachtText(
  etaMinutes: number | undefined,
  status: string,
  isFormal: boolean,
  hasArrived: boolean
): ReactNode {
  if (hasArrived) {
    return "De wegenwacht is er";
  }

  // note: we need to check for undefined because a "0" is falsy
  if (etaMinutes !== undefined) {
    const etaText = etaMinutes <= 0 ? "enkele ogenblikken" : `${etaMinutes} minuten`;
    return (
      <>
        De wegenwacht is {status} en is over {etaText} bij {isFormal ? "u" : "je"}!
      </>
    );
  }
  return `Details over de aankomsttijd ${isFormal ? "ontvangt u" : "ontvang je"} zo snel mogelijk.`;
}

/**
 * lat and lng are filled when wegenwacht is nearby
 */
export function isWegenwachtNearby(messageDetails: WegenwachtInTransitMessageDetails) {
  return (
    (messageDetails.locationWegenwacht !== undefined &&
      messageDetails.locationCustomer.coordinates !== undefined) ||
    messageDetails.status === "gearriveerd"
  );
}

export function getCoordinatesWegenwacht(
  wegenwachtLocation: { latitude: number; longitude: number } | undefined,
  customerLocation: { latitude: number; longitude: number } | undefined,
  messageDetails: WegenwachtInTransitMessageDetails
) {
  const coordinates =
    messageDetails.status === "gearriveerd" ? customerLocation : wegenwachtLocation;

  return { lat: Number(coordinates?.latitude), lng: Number(coordinates?.longitude) };
}
