import Breadcrumb, { BreadcrumbContainer } from "@anwb/breadcrumb";
import React from "react";
import styled from "styled-components";
import { useLinkBuilder } from "../link";

const Style = styled.div`
  ${BreadcrumbContainer} {
    padding: 12px 3px;
    height: 18px;
  }
`;

export const FormBreadcrumb = () => {
  const link = useLinkBuilder("/");

  return (
    <Style>
      <Breadcrumb history={[{ text: "Status Updates", href: link }]} />
    </Style>
  );
};
