import Typography from "@anwb/typography";
import { FC } from "react";
import type { CaseReceivedMessageDetails, StatusUpdateMessage } from "@status-updates/types";

import { determineInformationPageTitle } from "../../../src/utils/countrySpecificText";
import { useStatusUpdates } from "../../routes/home/hooks/useStatusUpdates";

type Props = {
  title?: string;
};

export const InformationPageTitle: FC<Props> = ({ title }) => {
  const { messages, caseReceivedMessage } = useStatusUpdates();

  return (
    <Typography variant="page-title">
      {title
        ? title
        : determineInformationPageTitle(determineCountryName(messages, caseReceivedMessage))}
    </Typography>
  );
};

function determineCountryName(
  messages: StatusUpdateMessage[],
  caseReceivedMessage?: CaseReceivedMessageDetails
): string {
  if (messages.length > 0 && messages[0].metadata?.breakdownLocationCountryName) {
    return messages[0].metadata?.breakdownLocationCountryName;
  }

  // for exisiting cases, only the caseReceivedMessage will contain the countryName. After 3 months, this default becomes obsolete
  return caseReceivedMessage?.breakdownLocationCountryName as string;
}
