import { FunctionComponent, ReactNode } from "react";
import { NotFoundErrorState, GenericErrorState } from "./states/error";
import { LoadingState } from "./states/loading";
import { HttpError, Status } from "../../hooks/usePollMessages";

type Props = {
  status: Status;
  error: unknown;
  retry: () => void;
  children: ReactNode;
};

export const ContentState: FunctionComponent<Props> = ({ status, retry, children, error }) => {
  switch (status) {
    case "error":
      if (isClientError(error)) {
        return <NotFoundErrorState />;
      }
      return <GenericErrorState retry={retry} />;

    case "loading":
      return <LoadingState />;

    case "success":
      return <>{children}</>;
    default:
      return null;
  }
};

function isClientError(error: unknown): boolean {
  return error instanceof HttpError && error.statusCode >= 400 && error.statusCode <= 499;
}
