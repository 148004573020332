import Typography from "@anwb/typography";
import { StyledInformation } from "../../routes/home/pages/information/styled";
import { GtmAccordion } from "../country-information/GtmAccordion";
import { InformationPageTitle } from "../information/informationPageTitle";
import { Navigation } from "../navigation";

const FAQ = () => {
  return (
    <StyledInformation>
      <Navigation
        withStatusUpdatesNavigation={false}
        links={[
          { title: "Familieomstandigheden", link: "#familieomstandigheden" },
          { title: "Verlies/Diefstal", link: "#verlies-diefstal" },
          { title: "Advies bij reisproblemen", link: "#reisproblemen" },
          { title: "Ziekte", link: "#ziekte" },
          { title: "Overlijden", link: "#overlijden" },
          { title: "Polisvoorwaarden", link: "#polisvoorwaarden" },
        ]}
      />

      <section className="country-information">
        <InformationPageTitle title="Veelgestelde Vragen" />

        <Typography variant="body-text" tagName="p">
          Heeft u hulp ingeroepen en zoekt u extra informatie? Hier vindt u antwoorden op vragen
          over medische problemen, familieomstandigheden en verlies of diefstal tijdens uw reis.
          <br />
          <br />
          De Alarmcentrale biedt hulp bij noodsituaties en bemiddelt namens uw verzekeraar, maar wij
          zijn niet direct verbonden met uw verzekeraar en kunnen daarom niet altijd een oordeel
          geven over de dekking van kosten. Raadpleeg daarom altijd de polisvoorwaarden van uw
          specifieke polis. Voor verdere vragen kunt u contact opnemen met onze Alarmcentrale. Wij
          helpen u graag verder.
        </Typography>

        <Typography variant="content-title" id="familieomstandigheden">
          Familieomstandigheden
        </Typography>

        <Typography variant="body-text" tagName="p">
          Familieomstandigheden kunnen uw reis plotseling onderbreken. Op deze pagina leest u wat te
          doen als u snel naar huis moet door ziekte of overlijden van een familielid.
        </Typography>

        <GtmAccordion
          content={[
            {
              title: "Wat te doen bij familieomstandigheden?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Als u in het buitenland bent en hoort dat een familielid of een goede vriend in
                  Nederland ziek is geworden of is overleden, kan dat heel moeilijk voor u zijn.
                  Misschien wilt u dan zo snel mogelijk terug naar Nederland. Uw reisverzekering kan
                  in sommige gevallen helpen met een eerdere terugreis. Wat er gedekt is, hangt af
                  van de situatie en uw polisvoorwaarden. Kijk goed in uw polisvoorwaarden voor
                  details.
                </Typography>
              ),
            },
            {
              title: "Ik wil terug naar Nederland, hoe regel ik dat?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Wilt u terug naar huis vanwege familieomstandigheden? Dan kunt u zelf uw terugreis
                  regelen. Misschien boekt u uw vliegticket via de luchtvaartmaatschappij om.
                  Hierbij betaalt u mogelijk extra kosten. Deze kunt u indienen bij uw
                  reisverzekering. Zij zullen deze kosten beoordelen. Als uw verzekering andere
                  mogelijkheden dekt, kiest u voor de trein, bus of huurt u een auto. Bewaar alle
                  bonnetjes van de kosten die u maakt, dien deze ter beoordeling in bij uw
                  verzekering. De Alarmcentrale kan u niet helpen met het betalen. Wel kunnen we u
                  helpen met het regelen van een vliegticket.
                  <br />
                  <br />
                  Wilt u na uw reis naar Nederland weer terug naar het vakantieadres? Bespreek dit
                  dan met de Alarmcentrale. In veel gevallen kan er een retourticket geboekt worden.
                </Typography>
              ),
            },
            {
              title: "Wat krijg ik vergoed als ik naar het vliegveld moet?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Uw reisverzekering kan de reiskosten naar en van het vliegveld vergoeden, maar
                  alleen voor 2e klasse openbaar vervoer. Deze kosten kunt u zelf voorschieten en
                  daarna declareren bij uw verzekering. Als er geen openbaar vervoer beschikbaar is,
                  neem dan een taxi en bewaar deze bonnen voor uw verzekering. De Alarmcentrale kan
                  hierover geen beslissingen nemen.
                </Typography>
              ),
            },
            {
              title: "Wat als er iemand met mij mee op reis is die niet op mijn polis staat?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Reist er iemand met u mee die niet op uw polis staat? Als die persoon ook de reis
                  wil onderbreken en zelf een doorlopende reisverzekering heeft, vergoedt de
                  reisverzekering misschien ook de kosten voor hun terugreis. Het is slim om de
                  polisvoorwaarden van hun verzekering te checken en neem contact op met hun
                  Alarmcentrale.
                </Typography>
              ),
            },
            {
              title: "Wat gebeurt er met de kosten van de dagen die ik niet heb kunnen genieten?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Als uw vakantie eerder eindigt en u niet van alle betaalde dagen kunt genieten,
                  krijgt u misschien kosten terug via uw annuleringsverzekering. Dien hiervoor de
                  kosten in bij uw verzekering en zij beoordelen of u recht heeft op een vergoeding.
                </Typography>
              ),
            },
          ]}
        />

        <Typography variant="content-title" id="verlies-diefstal">
          Verlies/Diefstal
        </Typography>

        <Typography variant="body-text" tagName="p">
          Als u op reis bent en u verliest spullen of er wordt iets van u gestolen, kan dat erg
          vervelend zijn. Gelukkig kan uw reisverzekering in veel gevallen helpen met de kosten.
          Lees de polisvoorwaarden nauwkeurig door om te weten wat er precies gedekt is.
        </Typography>

        <GtmAccordion
          content={[
            {
              title: "Iemand heeft iets van mij gestolen, wat nu?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Als er spullen van u gestolen zijn, ga dan zo snel mogelijk naar de lokale politie
                  om aangifte te doen. U heeft een aangifterapport nodig om later bij uw verzekering
                  de kosten te kunnen declareren. Dit rapport is heel belangrijk dat u dit ter
                  plekke doet, niet achteraf. Bekijk de polisvoorwaarden van uw reisverzekering voor
                  een duidelijk overzicht van wat precies gedekt is.
                </Typography>
              ),
            },
            {
              title: "Ik ben mijn ID-kaart of paspoort kwijtgeraakt, hoe kan ik terug naar huis?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Als u uw ID-kaart of paspoort, neem dan contact op met het Nederlandse consulaat
                  of ambassade in het land waar u bent. Zij kunnen u helpen met een vervangend
                  reisdocument. Soms kunt u zonder dit document terugreizen binnen Europa, maar het
                  is slim om dit eerst te checken bij uw luchtvaartmaatschappij. Helaas kan de
                  Alarmcentrale u hier niet direct bij helpen.
                  <br />
                  <br />
                  Kijk voor meer informatie op:{" "}
                  <Typography
                    variant="link-text"
                    href="https://www.nederlandwereldwijd.nl/contact/ambassades-consulaten-generaal"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contact met Nederlandse ambassades en consulaten-generaal | NederlandWereldwijd
                  </Typography>
                </Typography>
              ),
            },
            {
              title: "Mijn bankpassen zijn gestolen of kwijt, wat moet ik doen?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Als iemand uw bankpassen steelt, handel dan snel om misbruik van de passen te
                  voorkomen. U kunt online of via de telefoon uw passen blokkeren. Voor hulp vanuit
                  het buitenland, bel de Bankpassen Meldcentrale op +31 88 3855372. Veel banken
                  bieden ook de mogelijkheid om via een app of internetbankieren uw passen zelf
                  direct te blokkeren. Kijk op de website van uw bank voor meer informatie.
                  <br />
                  <br />
                  Mocht u dringend geld nodig hebben, dan kunt u gebruikmaken van Western Union om
                  snel geld te ontvangen. Onthoud wel, de Alarmcentrale kan niet helpen bij het
                  overmaken van geld.
                </Typography>
              ),
            },
            {
              title: "Ik heb extra kosten gemaakt door de diefstal, kan ik die terugkrijgen?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Als u extra kosten heeft gemaakt door de diefstal, zoals het vervangen van
                  gestolen spullen, kunt u deze kosten ter beoordeling indienen bij uw
                  reisverzekering. Bewaar alle bonnetjes goed, want die heeft u nodig voor de
                  declaratie. Controleer uw polisvoorwaarden om te zien of deze kosten gedekt zijn.
                </Typography>
              ),
            },
          ]}
        />

        <Typography variant="content-title" id="reisproblemen">
          Advies bij reisproblemen
        </Typography>

        <Typography variant="body-text" tagName="p">
          Problemen tijdens uw reis kunnen variëren van geannuleerde vluchten tot verloren bagage.
          Hieronder vindt u advies over hoe u met dergelijke situaties kunt omgaan en welke stappen
          u kunt nemen om hulp te krijgen.
        </Typography>

        <GtmAccordion
          content={[
            {
              title: "Mijn luchtvaartmaatschappij heeft mijn vlucht geannuleerd, wat nu?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Wanneer de luchtvaartmaatschappij uw vlucht annuleert, neem dan direct contact met
                  hen op. Zij zijn verantwoordelijk voor het regelen van een nieuwe vlucht voor u.
                  Als u besluit zelf iets te regelen, bewaar dan alle bewijzen van de extra kosten
                  die u maakt. Dien deze kosten later in bij uw reisverzekering om te kijken of ze
                  vergoed worden.
                </Typography>
              ),
            },
            {
              title: "Help, mijn bagage is niet meegekomen!",
              content: (
                <Typography variant="body-text" tagName="p">
                  Is uw bagage niet aangekomen op uw bestemming? Dit is natuurlijk erg vervelend.
                  Neem contact op met uw reisverzekering om te kijken of zij iets kunnen betekenen
                  voor noodzakelijke aankopen. Let op: dit geldt alleen als u een bagagedekking in
                  uw verzekering heeft.
                </Typography>
              ),
            },
            {
              title: "Mijn vakantieadres voldoet niet aan de verwachtingen, wat kan ik doen?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Heeft uw vakantieadres problemen of is het niet zoals verwacht? Neem dan contact
                  op met de verhuurder of de reisorganisatie waar u geboekt heeft. De Alarmcentrale
                  kan helaas geen nieuwe accommodatie voor u regelen, maar als u zelf iets anders
                  vindt, kunt u deze extra kosten proberen te verhalen op uw reisverzekering.
                </Typography>
              ),
            },
            {
              title: "Ik heb schade aan mijn gehuurde voertuig",
              content: (
                <Typography variant="body-text" tagName="p">
                  Heeft u een voertuig gehuurd en is er schade ontstaan? Dit dient u direct te
                  bespreken met de verhuurder volgens uw huurovereenkomst. De Alarmcentrale grijpt
                  hierin niet in en uw reisverzekering dekt geen schade aan gehuurde voertuigen.
                </Typography>
              ),
            },
            {
              title: "Telefoonkosten door bellen met de Alarmcentrale",
              content: (
                <Typography variant="body-text" tagName="p">
                  Belde u vanuit het buitenland met de Alarmcentrale en vraagt u zich af of deze
                  kosten vergoed worden? Check de voorwaarden van uw reisverzekering. Sommige
                  verzekeringen vergoeden communicatiekosten in noodsituaties.
                </Typography>
              ),
            },
            {
              title: "Ik heb kosten voorgeschoten in het buitenland, hoe declareer ik deze?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Heeft u in het buitenland kosten voorgeschoten die uw zorg- of reisverzekering
                  mogelijk dekt? Bewaar dan goed alle bonnetjes en facturen. Deze kunt u indienen
                  bij uw verzekeraar, meestal via hun website of app.
                  <br />
                  Dien zorg gerelateerde kosten altijd eerst in bij uw zorgverzekeraar. Denk hierbij
                  aan voorschoten medische facturen of medicatie.
                  <br />
                  Overige kosten kunt u indienen bij uw reisverzekering.
                  <br />
                  Let op: elke verzekeraar hanteert eigen regels voor het indienen van deze kosten,
                  en zij accepteren niet alle talen.
                </Typography>
              ),
            },
          ]}
        />

        <Typography variant="content-title" id="ziekte">
          Ziekte
        </Typography>

        <Typography variant="body-text" tagName="p">
          Bent u ziek geworden tijdens uw reis? Lees hier wat u moet doen, hoe u medische hulp kunt
          krijgen, en hoe uw verzekering u kan ondersteunen bij medische kosten in het buitenland.
        </Typography>

        <GtmAccordion
          content={[
            {
              title: "Wanneer moet ik naar het ziekenhuis en wie kan mij adviseren?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Als u twijfelt of u medische hulp nodig heeft, kunt u altijd de Alarmcentrale
                  bellen. Een van onze medewerkers kan u doorverbinden met onze vakantiedokter die u
                  advies kan geven over wat te doen.
                </Typography>
              ),
            },
            {
              title: "Mijn zorgpas is verlopen terwijl ik in het buitenland ben, wat nu?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Maakt u geen zorgen als u ontdekt dat uw zorgpas verlopen is. Neem contact op met
                  de Alarmcentrale. Wij kunnen een digitale versie van uw zorgpas naar het
                  ziekenhuis sturen om te zorgen dat u toch de nodige zorg krijgt.
                  <br />
                  Let op: de zorgpas (EHIC) is alleen geldig voor spoedeisende hulp in
                  staatsziekenhuizen.
                </Typography>
              ),
            },
            {
              title: "Wat als mijn zorgpas niet geldig is in het land waar ik ben?",
              content: (
                <Typography variant="body-text" tagName="p">
                  De zorgpas is meestal alleen geldig in staatsziekenhuizen binnen de EU en Europese
                  Economische Ruimte (Liechtenstein, Noorwegen, IJsland en Zwitserland). Als u
                  buiten deze gebieden bent en u heeft een geldige en aanvullende verzekering met
                  toereikende dekking, dan kan de Alarmcentrale een betalingsgarantie afgeven voor
                  spoedeisende medische zorg. Dit geldt ook wanneer u in een privé-ziekenhuis/
                  kliniek bent. De artsen van de Alarmcentrale bepalen de spoedeisendheid.
                </Typography>
              ),
            },
            {
              title: "Hoe declareer ik kosten van medische zorg in het buitenland?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Als u kosten heeft voorgeschoten voor medische zorg, kunt u deze indienen bij uw
                  zorgverzekering. Als er kosten zijn die uw zorgverzekering niet vergoedt, dient u
                  deze mogelijk in bij uw reisverzekering, zolang u een dekking voor medische kosten
                  heeft. Vraag een medisch rapport en de nota in het Engels, Frans of Duits aan,
                  omdat u anders zelf de vertaalkosten moet betalen.
                </Typography>
              ),
            },
            {
              title: "Kan de Alarmcentrale helpen als ik de taal niet spreek?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Het ziekenhuis moet voor een tolk zorgen als u de taal niet spreekt. Dit is om
                  ervoor te zorgen dat u de medische informatie goed begrijpt.
                </Typography>
              ),
            },
            {
              title:
                "Kan de Alarmcentrale bemiddelen bij meningsverschillen met mijn behandelend arts?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Het is belangrijk dat u alle vragen en zorgen bespreekt met uw behandelend arts.
                  De Alarmcentrale kan niet bemiddelen in de medische behandeling.
                </Typography>
              ),
            },
            {
              title: "Wat als de behandelend arts een onderzoek adviseert dat niet spoedeisend is?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Wanneer u in het buitenland bent, dekt uw verzekering zorg die direct
                  noodzakelijk/spoedeisend is. Maar soms kan een behandelend arts een medisch
                  onderzoek of behandeling aanraden die door de alarmcentralearts niet als
                  spoedeisend wordt beschouwd, maar eerder als planbare (electieve) zorg. Voor dit
                  soort zorg geldt dat uw verzekering de kosten vergoedt tot maximaal wat het in
                  Nederland zou kosten. Let op: in veel landen zijn de kosten voor medische zorg
                  hoger dan in Nederland. Dit betekent dat u mogelijk een deel van de kosten zelf
                  moet betalen als de zorg duurder is dan de Nederlandse standaard.
                  <br />
                  <br />
                  De Alarmcentrale biedt helaas geen financiële garantie voor kosten die u maakt
                  voor deze niet-spoedeisende zorg. Als u besluit om zo'n onderzoek of behandeling
                  toch te laten doen tijdens uw verblijf in het buitenland, zult u de kosten eerst
                  zelf moeten voorschieten. Bewaar alle bonnen en medische rapporten goed, want deze
                  heeft u nodig om de kosten later eventueel bij uw verzekering te kunnen indienen.
                </Typography>
              ),
            },
            {
              title: "Wat als ik door ziekte mijn vlucht mis?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Als u een geldige reisverzekering heeft en onze Alarmcentrale ondersteunt deze
                  verzekering, kunnen wij vaak helpen een nieuwe terugreis te regelen op basis van
                  medisch advies. Dit moet medisch verantwoord zijn. Als uw verzekering bij een
                  andere alarmcentrale is, zullen wij het dossier overdragen.
                </Typography>
              ),
            },
            {
              title: "Ik heb geen verblijfplaats meer door mijn ziekenhuisopname, wat kan ik doen?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Als u na uw ziekenhuisopname geen verblijfplaats meer heeft, check dan uw
                  reisverzekering. Vele verzekeringen dekken extra verblijfskosten. U kunt zelf
                  online een nieuwe accommodatie zoeken en boeken.
                </Typography>
              ),
            },
            {
              title: "Wie betaalt de extra kosten als ik moet blijven voor een zieke reisgenoot?",
              content: (
                <Typography variant="body-text" tagName="p">
                  De reisverzekering van uw zieke reisgenoot dekt meestal de kosten voor uw extra
                  verblijf.
                  <br />
                  Contacteer hun alarmcentrale voor meer informatie over de dekking.
                </Typography>
              ),
            },
            {
              title:
                "Ik heb meer ruimte nodig in het vliegtuig vanwege de behandeling, is dat mogelijk?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Neem contact op met onze Alarmcentrale en een arts zal beoordelen wat de veiligste
                  manier is voor uw terugreis naar Nederland.
                </Typography>
              ),
            },
            {
              title: "Kan ik met een aangepast voertuig over de weg terug naar Nederland?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Als uw bestemming relatief dichtbij is, kan het mogelijk zijn om met een aangepast
                  voertuig zoals een taxi, ligtaxi of ambulance terug naar Nederland te gaan. Onze
                  arts zal adviseren wat in uw situatie het beste is. Neem hiervoor contact op met
                  de Alarmcentrale.
                </Typography>
              ),
            },
          ]}
        />

        <Typography variant="content-title" id="overlijden">
          Overlijden
        </Typography>

        <Typography variant="body-text" tagName="p">
          Het verlies van een dierbare tijdens een reis is bijzonder ingrijpend. Hier vindt u
          informatie over wat te doen en hoe uw verzekering kan helpen bij de repatriëring en andere
          noodzakelijke regelingen.
        </Typography>

        <GtmAccordion
          content={[
            {
              title:
                "Is de verzekering geldig voor het terugbrengen van een overledene naar Nederland?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Wanneer een dierbare overlijdt tijdens de reis, kan dit een heel moeilijke tijd
                  zijn. Het is mogelijk dat uw verzekering de kosten voor het terugbrengen van de
                  overledene naar Nederland (repatriëring) dekt. Neem hiervoor direct contact op met
                  de Alarmcentrale. We kunnen samen kijken naar uw verzekeringenverzekering, zoals
                  een aanvullende zorgverzekering of reisverzekering, en bepalen of repatriëring
                  mogelijk is. Omdat het proces van repatriëring complex is, adviseren we u om nog
                  geen uitvaart in Nederland te plannen totdat alle details bekend zijn.
                </Typography>
              ),
            },
            {
              title: "Kan ik gebruik maken van de diensten van een lokale uitvaartondernemer?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Hoewel lokale uitvaartondernemers misschien hulp aanbieden, raden we aan om
                  voorzichtig te zijn met het accepteren van hun diensten zonder overleg met de
                  Alarmcentrale. We werken wereldwijd samen met betrouwbare partners die ervaring
                  hebben met internationale repatriëring. Dit voorkomt mogelijke problemen, zoals
                  het kiezen van een kist die niet geschikt is voor transport naar Nederland of
                  onverwachte hoge kosten.
                </Typography>
              ),
            },
            {
              title: "Hoe bescherm ik de identiteit van de overledene?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Het is belangrijk om persoonlijke documenten zoals een paspoort of
                  identiteitsbewijs bij de overledene te bewaren. Deze maatregel beschermt tegen
                  identiteitsfraude. Bewaar deze documenten veilig en geef ze niet aan onbekenden.
                  Zo beschermen we de identiteit van uw dierbare en voeren we de repatriëring
                  correct en respectvol uit.
                </Typography>
              ),
            },
          ]}
        />

        <Typography variant="content-title" id="polisvoorwaarden">
          Polisvoorwaarden
        </Typography>

        <Typography variant="body-text" tagName="p">
          Uw polisvoorwaarden bevatten belangrijke informatie over wat wel en niet gedekt is door uw
          verzekering. Lees deze goed door om te weten waar u aan toe bent en hoe u claims kunt
          indienen.
        </Typography>

        <GtmAccordion
          content={[
            {
              title: "Polisvoorwaarden: uw gids voor dekking en claims",
              content: (
                <Typography variant="body-text" tagName="p">
                  Wanneer u een verzekering afsluit, krijgt u te maken met polisvoorwaarden. Dit
                  zijn de regels en details die beschrijven wat uw verzekering wel en niet dekt. Het
                  is erg belangrijk dat u deze voorwaarden goed begrijpt, zodat u weet waar u aan
                  toe bent in verschillende situaties.
                </Typography>
              ),
            },
            {
              title: "Waar vind ik mijn polisvoorwaarden?",
              content: (
                <Typography variant="body-text" tagName="p">
                  U ontvangt uw polisvoorwaarden wanneer u uw verzekering afsluit. Deze kunnen
                  digitaal of op papier zijn. Als u ze niet kunt vinden of als u vragen heeft, neem
                  dan contact op met uw verzekeringsmaatschappij. Zij kunnen u een nieuwe kopie
                  geven en extra uitleg bieden.
                </Typography>
              ),
            },
            {
              title: "Hoe lees ik mijn polisvoorwaarden?",
              content: (
                <Typography variant="body-text" tagName="p">
                  Neem de tijd om uw polisvoorwaarden door te nemen. Let vooral op de volgende
                  punten:
                  <ul>
                    <li>
                      <b>Dekking</b>: Wat is wel en niet verzekerd? Kijk vooral naar dekkingen die
                      belangrijk zijn voor uw reis, zoals medische zorg, annulering, en diefstal of
                      verlies van bagage.
                    </li>
                    <li>
                      <b>Uitsluitingen</b>: Dit zijn situaties waarin de verzekering niet uitkeert.
                      Het is belangrijk om te weten wat deze zijn, zodat u niet voor verrassingen
                      komt te staan.
                    </li>
                    <li>
                      <b>Eigen risico</b>: Het bedrag dat u zelf moet betalen voordat de verzekering
                      iets uitkeert. Dit kan per verzekering en per situatie verschillen.
                    </li>
                    <li>
                      <b>Manier van werken voor claims</b>: Hoe en binnen welke termijn moet u een
                      claim indienen als er iets gebeurt? Welke documenten heeft u nodig?
                    </li>
                  </ul>
                </Typography>
              ),
            },
            {
              title: "Tips voor het omgaan met uw polisvoorwaarden:",
              content: (
                <Typography variant="body-text" tagName="p">
                  <ul>
                    <li>
                      Bewaar een kopie van uw polisvoorwaarden op een veilige plaats, zowel digitaal
                      als op papier.
                    </li>
                    <li>
                      Neem een kopie mee op reis of zorg ervoor dat u online toegang heeft tot de
                      documenten.
                    </li>
                    <li>
                      Als u iets niet begrijpt of als u vragen heeft, aarzel dan niet om contact op
                      te nemen met uw verzekeraar voor opheldering.
                    </li>
                    <li>
                      Het begrijpen van uw polisvoorwaarden helpt u beter voorbereid te zijn op uw
                      reis en geeft u duidelijkheid over de dekking van uw verzekering.
                    </li>
                  </ul>
                </Typography>
              ),
            },
          ]}
        />
      </section>
    </StyledInformation>
  );
};

export default FAQ;
