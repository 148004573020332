import styled from "styled-components";

export const StyledFeedback = styled.section`
  .feedback {
    margin-top: 24px !important;
    margin-bottom: 0 !important;
  }
  .cross {
    float: right;
    background-color: ${({ theme }) => theme.colors.blanc.bgSite};
    border: none;
    flex-direction: row;
    align-items: flex-start;
    width: fit-content;
    padding-top: 0px;
  }
  .button {
    border: none;
    background-color: ${({ theme }) => theme.colors.blanc.bgSite};
    flex-direction: row;
    align-items: flex-end;
    width: fit-content;
    padding-top: 0px !important;
    padding-left: 0px;
    padding-right: 20px;
    font-size: 15px;
    align-items: center;
    height: 40px;
  }
  .textarea {
    box-sizing: border-box;
    hight: fit-content;
  }
`;
