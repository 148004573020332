import Typography from "@anwb/typography";
import { DateTime } from "luxon";
import { TaskPostponedMessageDetails, TypedStatusUpdateMessage } from "@status-updates/types";

import { FrontendState, StateMetadata } from "../frontend-state";

export class ReplacementVehiclePostponedState extends FrontendState<TaskPostponedMessageDetails> {
  constructor(
    message: TypedStatusUpdateMessage<TaskPostponedMessageDetails>,
    metadata: StateMetadata
  ) {
    super(message, metadata, {
      illustration: "alarmcentrale",
      title: "De zoektocht duurt langer dan verwacht",
    });
  }

  renderTimelineItems() {
    return [
      {
        timestamp: DateTime.fromISO(this.message.created),
        title: this.options.title,
        content: (
          <Typography
            data-e2e-test-type="status-updates-replacement-vehicle-postponed"
            tagName="div"
          >
            <p>
              Wij zijn {this.getInformalOrFormal("je", "u")} niet vergeten en vinden het vervelend
              dat we nog geen geschikt vervangend vervoer hebben gevonden.
              <br />
              <br />
              Momenteel doen we ons uiterste best naar het zoeken van een geschikte optie voor{" "}
              {this.getInformalOrFormal("jou", "u")}. Houd er rekening mee dat het vervangend
              vervoer mogelijk iets verder weg staat dan {this.getInformalOrFormal("je", "u")} had
              gehoopt.
              <br />
              <br />
              Wij verwachten dat we je {this.message.messageDetails.timeFragment} meer kunnen
              vertellen.
              <br />
              <br />
              Mocht {this.getInformalOrFormal("je", "u")} zelf vervoer gevonden hebben, laat het ons
              dan weten.
            </p>
          </Typography>
        ),
        feedback: this.renderFeedback(),
      },
    ];
  }
}
