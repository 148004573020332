import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const LinkContainer = styled(NavLink)<{ $inline: boolean }>`
  ${({ $inline }) => !$inline && "display: flex;"};
  text-decoration: none;
  align-items: center;

  .link-icon {
    color: ${({ theme }) => theme.colors.highlight.textLink};

    &--left {
      margin-right: 5px;
      align-self: start;
      order: 1;
    }

    &--right {
      margin-left: 5px;
      align-self: right;
      order: 3;
    }
  }

  .link-text {
    order: 2;
  }
`;
