import { createRef, FunctionComponent, useEffect } from "react";
import { useGoogleTagManager } from "../../hooks/useGoogleTagManager";
import zamaLogos from "@zamalogos/logos";

type Props = {
  contractMarket?: string;
  contractLabelId?: string;
};

const ANWB_LABEL = "anwb";
const LOGO_COMMON_PREFIX = "logo";

/**
 * A list of available logos
 *
 * This list contains all available logos without the common logo prefix in lower case
 *
 * e.g. ['ald', 'bmw', 'anwb' ...]
 */
const AVAILABLE_LOGOS = Object.keys(zamaLogos).map((logoName) =>
  logoName.substring(LOGO_COMMON_PREFIX.length).toLowerCase()
);

/**
 * Converts the label identifier into a logoName identifier
 *
 * The logo filenames are based on the label identifier. However, flow has logic that converts the label identifier
 * into the filename by removing special characters. Also, a logo is shown if its name is a prefix of the label identifier.
 * That means that if the contractLabelId is 'ALD automotive' then the 'ald' logo will be shown
 *
 * This method mimics the flow logic, in order to always show the correct logos
 *
 * @param contractLabelId
 */
export function convertLabelIdToLogoName(contractLabelId: string): string | undefined {
  // Remove special characters
  const cleanedLabelId = contractLabelId
    .replace(/\s|'|"|-|_|&|\./gi, "")
    .replace(/[\u00E8\u00E9\u00EA\u00EB]/g, "e")
    .toLowerCase();

  // Search for a logo that matches the label name
  const logoCandidate = AVAILABLE_LOGOS.find((logoName) => cleanedLabelId === logoName);

  if (logoCandidate !== undefined) {
    return logoCandidate;
  }

  // If we can't find a 1:1 mapping from label to logo name
  // we try to find a logo with the right prefix
  return AVAILABLE_LOGOS.find((logoName) => cleanedLabelId.startsWith(logoName));
}

/**
 * Determines the logo to show based on the contract market and label
 *
 * If no label is given, or no image is found for the logo, the ANWB logo will be shown
 *
 * @param contractMarket
 * @param contractLabelId
 */
function getLogoName(contractMarket: string, contractLabelId?: string): string {
  if (contractMarket === "coma" || !contractLabelId) {
    return ANWB_LABEL;
  }

  return convertLabelIdToLogoName(contractLabelId) || ANWB_LABEL;
}

export const Logo: FunctionComponent<Props> = ({ contractLabelId, contractMarket = "coma" }) => {
  const logoName = getLogoName(contractMarket, contractLabelId);
  const sendEventToGtm = useGoogleTagManager();
  const image = createRef<HTMLImageElement>();

  const gtmLogoClick: () => void = () => {
    sendEventToGtm({
      type: "page",
      component: "logo home",
      action: "click",
    });
  };

  useEffect(() => {
    function loadImage() {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, global-require, @typescript-eslint/no-var-requires
        const imageSrc = require(`@zamalogos/logos/src/logos/${logoName}.png`);
        if (image.current) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          image.current.src = imageSrc;
        }
      } catch (e) {
        console.error(`Could not find image for label '${logoName}`);
      }
    }

    loadImage();
  }, [image, logoName]);

  if (logoName === "anwb") {
    return (
      <a onClick={gtmLogoClick} href="https://anwb.nl">
        <img
          ref={image}
          style={{
            height: "35px",
            width: "116px",
            borderRadius: "0px",
            objectFit: "contain",
            objectPosition: "0% 50%",
          }}
          alt={logoName}
        />
      </a>
    );
  }

  return (
    <img
      ref={image}
      style={{
        height: "35px",
        width: "116px",
        borderRadius: "0px",
        objectFit: "contain",
        objectPosition: "0% 50%",
      }}
      alt={logoName}
    />
  );
};
