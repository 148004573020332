import { FunctionComponent } from "react";
import Typography from "@anwb/typography";
import { ButtonPrimary } from "@anwb/button";
import "./style.css";

type props = {
  retry: () => void;
};
export const NotFoundErrorState: FunctionComponent = () => (
  <div className="error">
    <Typography variant="page-title">Deze pagina bestaat niet meer</Typography>
    <Typography variant="body-text">
      Controleer het volgende:
      <ul>
        <li>
          Het kan zijn dat je kijkt naar een oude pechmelding, deze informatie is slechts beperkte
          tijd beschikbaar.
        </li>
        <li>Misschien heb je een ongeldige weblink ingevoerd of gekopieerd.</li>
      </ul>
    </Typography>
  </div>
);

export const GenericErrorState: FunctionComponent<props> = ({ retry }): JSX.Element => (
  <div className="error">
    <Typography variant="page-title">Technische storing</Typography>
    <Typography variant="body-text">
      Helaas kunnen we door een technische storing de informatie op deze pagina tijdelijk niet
      tonen. Probeer het later nog eens. Sorry voor het ongemak.
    </Typography>
    <ButtonPrimary className="button" onClick={retry} icon="refresh">
      Probeer opnieuw
    </ButtonPrimary>
  </div>
);
