import styled from "styled-components";

export const StyledStatusUpdates = styled.section`
  margin: 0 auto;
  max-width: ${({ theme }) => `${theme.viewportBreakpoint.sm}px`};
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
`;
