import { travelInformationData } from "../../../../../../../features/api/generated/Backend";
import { TravelInfoForm } from "./TravelInformationForm";

export function mapTravelType(
  travelType: NonNullable<NonNullable<travelInformationData>["travelInformation"]>["travelType"]
) {
  switch (travelType) {
    case "HEENREIS":
      return "Heenreis";
    case "PLAATS_VAN_BESTEMMING":
      return "Plaats van bestemming";
    case "RONDREIS":
      return "Rondreis";
    case "TERUGREIS":
      return "Terugreis";
    default:
      return undefined;
  }
}

export function mapFromDataToRequest(form: TravelInfoForm): travelInformationData {
  return {
    travelInformation: setEmptyStringsToUndefined({
      journeyStart: form?.travelInformation?.journeyStart,
      journeyEnd: form?.travelInformation?.journeyEnd,
      travelType: form?.travelInformation?.travelType,
      countryOfDestination: form?.travelInformation?.countryOfDestination,
      /* form?.travelInformation?.returnsAtBreakdownLocation is of type string, but the returnsAtBreakdownLocation property only accepts boolean types.
      with === "true" the string can be converted to a boolean, but form?.travelInformation?.returnsAtBreakdownLocation can also have a value of undefined. Hence this weird patern */
      returnsAtBreakdownLocation:
        form?.travelInformation?.returnsAtBreakdownLocation === "true"
          ? true
          : form?.travelInformation?.returnsAtBreakdownLocation === "false"
          ? false
          : undefined,
      returnsAtBreakdownLocationDate: form?.travelInformation?.returnsAtBreakdownLocationDate,
      comment: form?.travelInformation?.comment,
    }),
    travelDocuments: {
      hasIdentificationCard: form?.travelDocuments?.hasIdentificationCard === "true",
      hasDriversLicense: form?.travelDocuments?.hasDriversLicense === "true",
      hasCreditCard: form?.travelDocuments?.hasCreditCard === "true",
      hasDebitCard: form?.travelDocuments?.hasDebitCard === "true",
    },
    travelEquipment: {
      hasTrailer: form?.travelEquipment?.hasTrailer,
      hasCaravan: form?.travelEquipment?.hasCaravan,
      hasFoldingCamper: form?.travelEquipment?.hasFoldingCamper,
      hasBikeRack: form?.travelEquipment?.hasBikeRack,
      hasTowbarLuggage: form?.travelEquipment?.hasTowbarLuggage,
      hasRoofLuggage: form?.travelEquipment?.hasRoofLuggage,
      hasBoatTrailer: form?.travelEquipment?.hasBoatTrailer,
    },
  };
}

export function setEmptyStringsToUndefined(obj: { [key: string]: string | boolean | undefined }) {
  const keys = Object.keys(obj);
  for (const key of keys) {
    if (obj[key] === "") {
      obj[key] = undefined;
    }
  }
  return obj;
}

export function allPropertiesUndefined(obj: Record<string, string | undefined>): boolean {
  for (const key in obj) {
    if (obj[key] !== undefined) {
      return false;
    }
  }
  return true;
}

export function createGATravelInformationEvent(
  actionValue: string,
  category: string,
  status?: string
) {
  return {
    event: "user_interaction",
    type: "page",
    component: `Deel je reisplanning met ons! - ${category}`,
    action: "click",
    status,
    actionValue,
  };
}
